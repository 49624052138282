//React
import React, { useState, useEffect } from "react";

//Reac Dom
import { useLocation } from "react-router-dom";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Button,
  Affix,
  notification,
  Modal,
  Image,
  Select,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

///// Extras de importacion ANT
const { Meta } = Card;
const { Option } = Select;

///Componente Form
const Formulario = () => {
  const [, setdatosCarga] = useState(null);

  ///estados
  const [modalSucess, setModalSucess] = useState(false);
  const [cargaButonForm, setcargaButonForm] = useState(false);
  const [, setPuestos] = useState(null);
  const [, setCecosNuevos] = useState([]);

  ////Form
  const [form] = Form.useForm();

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");
  ///fin carga de ID recibido
  //console.log(">>> Id para cargado de entrevista",idRequerimiento);

  ////carga Datos
  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/recruitiify-perfils/${idRequerimiento}`,
      { headers }
    );
    setdatosCarga(data);
    return data;
  };

  /////Carga de los Datos en el muro
  const onFill = (data) => {
    console.log(">>> Get data muro Entrevista, Onfill", data);
    form.setFieldsValue({
      nombre: data.nombre,
      correo: data.recruitify_mother.correo,
      numero: data.recruitify_mother.celular,
      dpi: data.recruitify_mother.dpi,
    });
  };
  /////Fin cara de Datos en el muro

  const cargaDatosProyecto = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const queryGraphQl = JSON.stringify({
      query: `query	{
          departamentoCecos{
            value: departamento,
            label: departamento
            children: campanas{
              value: nombre,
              label: nombre
            }
          }
        }`,
      variables: {},
    });



    /////////Inicio Puestos
    const queryGraphQlPuestos = JSON.stringify({
      query: `query	{
		        puestosTrabajos{
            nombrePuesto
          }
        }`,
      variables: {},
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/graphql", queryGraphQlPuestos,{ 
          headers 
    });

    setPuestos(data.data.puestosTrabajos);
    console.log(">>>Puestos",data.data.puestosTrabajos);
    /////Fin carga de puestos




    await Axios.post("https://rh.api.prontobpo.cloud/api/graphql", queryGraphQl, {
      headers,
    })
      .then((res) => {
        console.log(">>>>Datos GRAPHQL Cecos", res.data.data.departamentoCecos);
        setCecosNuevos(res.data.data.departamentoCecos);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////Formularios
  const envioSolicitudEmpleo = async (valores) => {
    const datos = JSON.stringify({
      nombre: valores.nombre,
      dpi: valores.dpi,
      correo: valores.correo,
      telefono: valores.numero,
      proyecto: JSON.stringify(valores.proyecto),
      pruebas: valores.pruebas,
      primeraentrevista: valores.Entrevista1,
      segundaentrevista: valores.Entrevista2,
      informacionpersonal: valores.infopersona,
      motivacionymetas: valores.motivacionymetas,
      informacionestudios: valores.infoestudios,
      medioentero: valores.medioentero,
      nombreempresa1: valores.nombreempresa1,
      puesto1: valores.puestoempresa1,
      nombreempresa2: valores.nombreempresa2,
      puesto2: valores.puestoempresa2,
      nombreempresa3: valores.nombreempresa3,
      puesto3: valores.puestoempresa3,
      nombreempresa4: valores.nombreempresa4,
      puesto4: valores.puestoempresa4,
      disponibilidadhorario: valores.disponibilidadhorario,
      quesperadelacompania: valores.esperadelacompania,
      expectativasalarial: valores.expsalario,
      quepodemosesperardeti: valores.esperadeti,
      padecimientomedico: valores.padecimientos,
      alergias: valores.alergias,
      tomamedicamento: valores.medicamentos,
      tatuajesopiercing: valores.tatuajesopiercings,
      fumatoma: valores.fumatoma,
      problemasley: valores.problemaley,
      drogas: valores.drogas,
      deudas: valores.deudas,
      familiarempresa: valores.familiar,
      embarazada: valores.embarazo,
      disponibilidadfinesdesemana: valores.finesdesemana,
      disponibilidadfestivos: valores.diasfestivos,
      parqueo: valores.parqueo,
      transporte: valores.transporte,
      horarioydescanzos: valores.horarioydescanzo,
      mesesaprendiz: valores.aprendiz,
      planilla: valores.planilla,
      headset: valores.headsets,
      comentariosreclutador: valores.comentariosreclutador,
      comentariogerenteosuper: valores.comentariosgerentesupervisor,
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/recruitifyentrevistas",
      datos,
      { headers }
    );
    console.log(data);

    const datosActualizar = JSON.stringify({
      estado: "Oferta",
    });

    //console.log(datos, headers);
    await Axios.put(
      `https://rh.api.prontobpo.cloud/api/recruitiify-perfils/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    return data;
  };

  const onFinish = (values) => {
    setcargaButonForm(true);
    //console.log(values.fechaInicio1._d)
    //console.log('Success:', values);

    envioSolicitudEmpleo(values)
      .then((info) => {
        setModalSucess(true);
        onReset();
        setcargaButonForm(false);
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setcargaButonForm(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Solicitud de Empleo",
      description:
        "Hay campos que no se han completado, por favor revisa todos los campos.",
    });
    console.log(
      "Fecha:",
      errorInfo?.values["nacimiento"]?.format("YYYY/MM/DD")
    );
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  ////Efectos

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatos().then((data) => {
      onFill(data);
    });
  }, []);
  ////Fin Efectos CargaDatos

  ///Efecto Proyecto
  useEffect(() => {
    cargaDatosProyecto();
  }, []);
  ///Fin Efecto Proyecto

  ////Fin Efectos

  // function filter(inputValue, path) {
  //   return path.some(
  //     (option) =>
  //       option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  //   );
  // }

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={true}
        initialValues={
          {
            //nombre: "Noe Peña",
          }
        }
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }

          <Col span={23}>
            <Card bordered={false}>
              <Meta
                title="Entrevista"
                description="Ingresa los datos para completar la entrevista. Todos lo datos se guardaran de manera segura."
              />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal del Aplicante
                </Divider>

                <Col span={24}>
                  <Row gutter={[18, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="DPI"
                            name="dpi"
                            rules={[
                              {
                                //type: "number",
                                //required: false,
                                message:
                                  "Por favor ingresa tu DPI sin espacios o guiones ( - ) solo numeros. ",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Numero DPI" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Correo Electronico"
                            name="correo"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  "Por favor ingresa correo del aplicante.",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Correo Electronico"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Numero Telefono"
                            name="numero"
                            rules={[
                              {
                                required: true,
                                message: "Ingresa telefono del aplicante.",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Numero de Telefono"
                            />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                      </Row>
                    </Col>

                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>

                {
                  /// Tercera Seccion
                }

<Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Pruebas"
                    name="pruebas"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa si realizo las pruebas.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Pruebas" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Primera Entrevista"
                    name="Entrevista1"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar si aprobo primera entrevista.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Primera entrevista" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Segunda Entrevista"
                    name="Entrevista2"
                    rules={[
                      {
                        required: true,
                        message: "Ingresar si aprobo segunda entrevista.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Segunda entrevista" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Informacion Personal
                </Divider>
                {
                  /// Cuarta Seccion
                }

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Informacion Personal"
                    name="infopersona"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa informacion del aplicante",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Informacion general del aplicante"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Motivacion y Metas"
                    name="motivacionymetas"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la motivacion y metas del aplicante.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Motivacion y Metas del aplicante"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Informacion de Estudios
                </Divider>
                {
                  /// Cuarta Seccion
                }

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Informacion de Estudios"
                    name="infoestudios"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa informacion de estudios del aplicante",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Informacion de estudios del aplicante"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Medio por el que se entero de nosotros"
                    name="medioentero"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingrese medio por el que se entero el aplicante.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Medio por el que se entero"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Educacion
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Experiencia Laboral
                </Divider>

                {
                  /// Quinta Seccion
                }

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Nombre empresa"
                    name="nombreempresa1"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa nombre empresa",
                      },
                    ]}
                  >
                    <Input addonBefore="1" size="large" placeholder="Empresa" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puestoempresa1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el puesto en la empresa.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Puesto" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreempresa2"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa nombre empresa",
                      },
                    ]}
                  >
                    <Input addonBefore="2" size="large" placeholder="Empresa" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label=""
                    name="puestoempresa2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el puesto en la empresa.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Puesto" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreempresa3"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa nombre empresa",
                      },
                    ]}
                  >
                    <Input addonBefore="3" size="large" placeholder="Empresa" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label=""
                    name="puestoempresa3"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el puesto en la empresa.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Puesto" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreempresa4"
                    rules={[
                      {
                        required: true,
                        message: "Ingresa nombre empresa",
                      },
                    ]}
                  >
                    <Input addonBefore="4" size="large" placeholder="Empresa" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label=""
                    name="puestoempresa4"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el puesto en la empresa.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Puesto" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Experiencia Laboral
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Puntos Generales
                </Divider>

                {
                  /// Septima Seccion
                }

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Disponibilidad de Horario"
                    name="disponibilidadhorario"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor indica si el aplicante tiene disponibilidad de horario",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Disponibilidad de horario"
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Que esperas de la compañia"
                    name="esperadelacompania"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa las expectativas del aplicante.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Que espera de la compañia"
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Expectativa Salarial"
                    name="expsalario"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la expectativa salarial del aplicante.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Expectativa salarial" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Que podemos esperar de ti"
                    name="esperadeti"
                    rules={[
                      {
                        required: true,
                        message: "Por favor completar esta informacion.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Que podemos esperar de ti"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row align="middle">
                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Tiene padecimiento de salud/razón para ir al médico?"
                    name="padecimientos"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Alergias?"
                    name="alergias"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Toma algún medicamento actualmente?"
                    name="medicamentos"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Tiene tatuajes o piercings?"
                    name="tatuajesopiercings"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Fuma o toma?"
                    name="fumatoma"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Ha tenido problemas con la ley?"
                    name="problemaley"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Consume o ha consumido drogas/estupefacientes incluyendo marihuana?"
                    name="drogas"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Tiene deudas actualmente?"
                    name="deudas"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Tiene algún familiar en la empresa?"
                    name="familiar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Está o cree estar embarazada de momento?"
                    name="embarazo"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Disponibilidad para fines de semana?"
                    name="finesdesemana"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Disponibilidad para días festivos?"
                    name="diasfestivos"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={8} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Se le ofreció parqueo?"
                    name="parqueo"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={6} offset={4}>
                  <Form.Item
                    className="username"
                    label="¿Metodo Transporte?"
                    name="transporte"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione transporte.",
                      },
                    ]}
                  >
                    <Select style={{ width: "100%" }}>
                      <Option value="Moto">Moto</Option>
                      <Option value="Carro">Carro</Option>
                      <Option value="Bicicleta">Bicicleta</Option>
                      <Option value="No Tiene">No Tiene</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8} offset={8}>
                  <Form.Item
                    className="username"
                    label="Horario Ofrecido y Dias de Descanzo"
                    name="horarioydescanzo"
                    rules={[
                      {
                        required: true,
                        message:
                          "por favor indica el horario y dias de descanzo",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Horario y Dias de Descanzo"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Propuesta Economica
                </Divider>
                {
                  /// Cuarta Seccion
                }

                <Col span={10} offset={2}>
                  <Form.Item
                    className="username"
                    label="Aprendiz Meses"
                    name="aprendiz"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa meses de aprendiz del solicitante",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Meses aprendiz" />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    className="username"
                    label="Planilla"
                    name="planilla"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de entrada a planilla.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Entrada a planilla" />
                  </Form.Item>
                </Col>

                <Col span={8} offset={10}>
                  <Form.Item
                    className="username"
                    label="¿Q400 Headsets?"
                    name="headsets"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Comentarios Generales
                </Divider>
                {
                  /// Cuarta Seccion
                }

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Comentarios del Reclutador"
                    name="comentariosreclutador"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa comentario del prospecto",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Comentarios del reclutador"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Comentarios del Gerente / Supervisor"
                    name="comentariosgerentesupervisor"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa comentarios del gerente o supervisor.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Comentarios del gerente - supervisor"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <br />

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col span={2}>
                  <Form.Item>
                    <Affix offsetBottom={25}>
                      <Button
                        loading={cargaButonForm}
                        size="Large"
                        type="primary"
                        htmlType="submit"
                      >
                        Confirmar Entrevista
                      </Button>
                    </Affix>
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item>
                    <Affix offsetBottom={25}>
                      <Button size="Large" type="primary" htmlType="submit">
                        Rechazar Entrevista
                      </Button>
                    </Affix>
                  </Form.Item>
                </Col>

                {/* Modal Prueba
                 
                                            */}
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => setModalSucess(false)}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button type="primary" onClick={() => setModalSucess(false)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Confirmacion de entrevista!" />
        </Modal>
      }
    </>
  );
};

export default Formulario;
