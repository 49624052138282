import React, { useEffect, useState } from "react";

import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  Typography,
  Input,
  Divider,
  TreeSelect,
  Radio,
  Result,
  notification,
} from "antd";

///Axios
import Axios from "axios";

////Locale
import "moment/locale/es";
import moment from "moment";

//Helpers
import { getToken } from "../../../Helpers/auth-helpers";
import { tipos, getTreeData } from "./dataTipoForm";
import TextArea from "antd/lib/input/TextArea";
import { LoadingOutlined } from "@ant-design/icons";
import Sucess from "../../../components/responses/Sucess";

const { Title } = Typography;

function Entrevista({
  modalEntrevista,
  setmodalEntrevista,
  idRequerimiento,
  correoUsuario,
}) {
  const [formPP] = Form.useForm();
  const [formVEC] = Form.useForm();
  const [datosCarga, setdatosCarga] = useState(null);
  const [datosCargaAsignados, setdatosCargaAsignados] = useState(null);
  const [infoUsuario, setinfoUsuario] = useState(null);
  const [treeData, settreeData] = useState(null);
  const [verificacionEntrevisa, setverificacionEntrevisa] = useState(false);
  const [isloading, setloading] = useState(true);
  const [botonEE, setBotonEE] = useState(false);
  const [modalGE, setModalGE] = useState(false);
  const [modalVEC, setmodalVEC] = useState(false);

  //? carga Datos entrevista
  const cargaDatosPerfil = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );
    setdatosCarga(data);
    return data;
  };
  //? carga Datos entrevistas

  //? carga Datos asignados para entrevista
  const cargaDatosAsignados = async (id) => {
    console.log("Id para buscar en asignado", id);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${id}&populate[mother]=*`,
      { headers }
    );

    console.log("Obtencion del get en asignados", data);

    if (data.data.length === 0) {
      setloading(false);
    } else {
      setinfoUsuario(data.data[0].attributes);
      setdatosCargaAsignados(data.data[0].id);

      //Funcion de carga e importacion dinamica (teba)
      const tipoEntrevista = data.data[0].attributes.Entrevista || false;

      if (tipoEntrevista) {
        getTreeData(tipos[tipoEntrevista]).then((treeData) => {
          settreeData(treeData);
        });
        setverificacionEntrevisa(true);
        setloading(false);
      } else {
        settreeData(null);
        setverificacionEntrevisa(false);
        setloading(false);
      }
      ///

      return data;
    }
  };
  //? carga Datos asignados para entrevista

  /////Carga de los Datos en el muro
  const onFill = (nombre, Proyecto, Puesto) => {
    Proyecto
      ? (Proyecto = JSON.parse(Proyecto))
      : (Proyecto = "Sin informacion");

    nombre = nombre || "Sin informacion.";
    Puesto = Puesto || "Sin informacion.";

    formPP.setFieldsValue({
      nombre: nombre,
      departamento: Proyecto,
      puesto: Puesto,
      entrevistador: correoUsuario,
    });
  };
  /////Fin cara de Datos en el muro

  /////Carga de los Datos en el muro
  const visualVEC = async () => {
    setmodalVEC(true);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/entrevistas?filters[IdMother][$eq]=${datosCarga?.data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Obtencion de la entrevista", data);

    if (data.meta.pagination.total === 0) {
      ///no hago nada
    } else {
      formVEC.setFieldsValue({
        nombreVE: data.data[0].attributes.nombre,
        departamentoVE: JSON.parse(data.data[0].attributes.departamento),
        puestoVE: data.data[0].attributes.puesto,
        entrevistadorVE: data.data[0].attributes.entrevistador,
        p1VE: data.data[0].attributes.primeraPregunta,
        rp1VE: data.data[0].attributes.respuestaPrimeraPregunta,
        p2VE: data.data[0].attributes.segundaPregunta,
        rp2VE: data.data[0].attributes.respuestaSegundaPregunta,
        habilidadesDestrezasVE: data.data[0].attributes.habilidadesDestrezas,
        debilidadesVE: data.data[0].attributes.debilidades,
        comentariosVE: data.data[0].attributes.comentarios,
      });
    }
  };

  const agregadoLog = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Realizacion de entrevista por competencias",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: datosCarga?.data.attributes.nombre,
        idMother: datosCarga?.data.attributes.mother.data.id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };

  const actualizarEstadoEntrevista = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datosActualizarAsignado = JSON.stringify({
      data: {
        estado: "Completada",
      },
    });

    //console.log(datos, headers);
    const { dataAsignados } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/asignados/${datosCargaAsignados}`,
      datosActualizarAsignado,
      { headers }
    );

    console.log(">>> Envio de actualizacion Estado Asignados", dataAsignados);
  };

  const verificacionEntrevista = async () => {
    console.log("Datos carga", datosCarga);
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados/?filters[idMother][$eq]=${datosCarga?.data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Retorno verificacion entrevista", data);
    return data;
  };

  const GuardadoFormulario = async (values) => {
    console.log("Datos del formulario a guardar", values);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      data: {
        IdMother: datosCarga?.data.attributes.mother.data.id,
        nombre: values.nombre,
        departamento: JSON.stringify(values.departamento),
        puesto: values.puesto,
        entrevistador: values.entrevistador,
        primeraPregunta: values.primeraPregunta,
        respuestaPrimeraPregunta: values.primeraRespuesta,
        segundaPregunta: values.segundaPregunta,
        respuestaSegundaPregunta: values.segundaRespuesta,
        habilidadesDestrezas: values.habilidadesydestrezas,
        debilidades: values.debilidades,
        comentarios: values.comentarios,
      },
    };

    const datos = JSON.stringify(datosEnviar);

    console.log("Datos a enviar de formulario a guardar", datos);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/entrevistas",
      datos,
      { headers }
    );
    return data;
  };

  const onFinishDebug = (values) => {
    setBotonEE(true);

    verificacionEntrevista().then((verificacion) => {
      if (!verificacion.data[0].attributes.estado) {
        notification.info({
          message: "Recrutify - Error",
          description:
            "No se ha programado una entrevista para poder realizar - programe primero la fecha y hora para entrevistar",
        });
        setBotonEE(false);
      } else if (verificacion.data[0].attributes.estado === "Completada") {
        notification.info({
          message: "Recrutify - Error",
          description:
            "La entrevista ya fue realizada. Visualizar formulario de entrevista por competencia",
        });
        setBotonEE(false);
        visualVEC();
      } else {
        GuardadoFormulario(values)
          .then((values) => {
            setBotonEE(false);
            setModalGE(true);
            formPP.resetFields();
            actualizarEstadoEntrevista();
            agregadoLog();
          })
          .catch((error) => {
            // Manejar el error al guardar el formulario
            console.error("Error al guardar el formulario:", error);
          });
      }
    });

    // aquí tu código para cuando el formulario es enviado exitosamente
  };

  const onFinishFailedDebug = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // aquí tu código para cuando falla el envío del formulario
  };

  useEffect(() => {
    formPP.resetFields([
      "primeraPregunta",
      "segundaPregunta",
      "primeraRespuesta",
      "segundaRespuesta",
      "habilidadesydestrezas",
      "debilidades",
      "comentarios",
    ]);
  }, [modalEntrevista]);

  ////use Effect de CargaDatos
  useEffect(() => {
    setloading(true);
    console.log(">>> UseEffect");
    cargaDatosPerfil().then((data) => {
      console.log(">>> return carga Perfil", data);
      cargaDatosAsignados(data.data.attributes.mother.data.id).then(
        (dataRCA) => {
          console.log(">>> return Datos Asigandos", dataRCA);
          onFill(
            dataRCA?.data[0].attributes.mother.data.attributes.nombre,
            dataRCA?.data[0].attributes.Proyecto,
            dataRCA?.data[0].attributes.Puesto
          );
        }
      );
    });
  }, [idRequerimiento]);
  ////Fin Efectos CargaDatos

  return (
    // MODAL PROYECTO Y PUESTO

    <Modal
      centered
      visible={modalEntrevista}
      onOk={() => setmodalEntrevista(false)}
      onCancel={() => setmodalEntrevista(false)}
      //height={600}
      width={1200}
      style={{ top: "2%" }}
      bodyStyle={{ maxHeight: "85vh", overflowY: "auto", overflowX: "hidden" }}
      footer={
        <div>
          <Button type="primary" onClick={() => setmodalEntrevista(false)}>
            Salir
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: "red" }}
            onClick={() => visualVEC()}
          >
            Visualizar Entrevista por competencias
          </Button>
        </div>
      }
    >
      {isloading ? (
        //Carga de Entrevista
        <Result icon={<LoadingOutlined />} title="Cargando Entrevista..." />
      ) : verificacionEntrevisa ? (
        //Formulario de Entrevista
        <>
          <Row>
            <Col className="gutter-row" span={24} offset={1}>
              <Title level={3}>Entrevista</Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={22} offset={1}>
              <Form
                name="Entrevista"
                form={formPP}
                onFinish={onFinishDebug}
                onFinishFailed={onFinishFailedDebug}
                autoComplete="off"
                requiredMark={true}
                initialValues={" "}
                size="middle"
              >
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Nombre"
                      name="nombre"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa tu nombre.",
                        },
                      ]}
                    >
                      <Input placeholder="Nombre" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Departamento"
                      name="departamento"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa departamento.",
                        },
                      ]}
                    >
                      <Input placeholder="Departamento" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Puesto"
                      name="puesto"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa puesto.",
                        },
                      ]}
                    >
                      <Input placeholder="Puesto" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Entrevistador"
                      name="entrevistador"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa entrevuistador.",
                        },
                      ]}
                    >
                      <Input placeholder="Entrevistador" />
                    </Form.Item>
                  </Col>

                  {
                    /// Segunda Seccion
                  }
                </Row>

                <Row gutter={16}>
                  <Divider orientation="left">
                    {infoUsuario?.Entrevista ||
                      "Tipo de entrevista no asignado"}
                  </Divider>

                  <Col span={24}>
                    <Form.Item
                      className="username"
                      label={
                        "Primera Pregunta " + infoUsuario?.Entrevista ||
                        "Tipo de entrevista no asignado"
                      }
                      name="primeraPregunta"
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona la primera pregunta",
                        },
                      ]}
                    >
                      <TreeSelect
                        placement="topRight"
                        showSearch
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        dropdownStyle={{
                          overflow: "auto",
                        }}
                        placeholder="Selecciona la primera pregunta"
                        allowClear
                        treeDefaultExpandAll
                        treeData={treeData}
                      />
                    </Form.Item>
                    <Form.Item
                      className="username"
                      name="primeraRespuesta"
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona la primera respuesta",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={1}>1</Radio>
                        <Radio value={2}>2</Radio>
                        <Radio value={3}>3</Radio>
                        <Radio value={4}>4</Radio>
                        <Radio value={5}>5</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      className="username"
                      label={
                        "Segunda Pregunta " + infoUsuario?.Entrevista ||
                        "Tipo de entrevista no asignado"
                      }
                      name="segundaPregunta"
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona la segunda pregunta",
                        },
                      ]}
                    >
                      <TreeSelect
                        placement="topRight"
                        showSearch
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        dropdownStyle={{
                          overflow: "auto",
                        }}
                        placeholder="Selecciona la segunda pregunta"
                        allowClear
                        treeDefaultExpandAll
                        treeData={treeData}
                      />
                    </Form.Item>
                    <Form.Item
                      className="username"
                      name="segundaRespuesta"
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona la segunda respuesta",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={1}>1</Radio>
                        <Radio value={2}>2</Radio>
                        <Radio value={3}>3</Radio>
                        <Radio value={4}>4</Radio>
                        <Radio value={5}>5</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Divider orientation="left">Puntos Fuertes y Débiles</Divider>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Habilidades y Destrezas "
                      name="habilidadesydestrezas"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa tu Habilidades y Destrezas .",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Habilidades y Destrezas"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Debilidades"
                      name="debilidades"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa Debilidades.",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Debilidades"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} offset={6}>
                    <Form.Item
                      className="username"
                      label="Comentarios"
                      name="comentarios"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa Comentarios.",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Comentarios"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} offset={10}>
                    <Button
                      loading={botonEE}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar Datos
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      ) : (
        //Sin asignacion de Entrevista
        <Result
          status="500"
          title="Sin Entrevista Asignada"
          subTitle="No se asigno la entrevista al usuario."
        />
      )}

      {
        ////Modal
        <Modal
          centered
          visible={modalGE}
          onOk={() => setModalGE(false)}
          onCancel={() => setModalGE(false)}
          footer={
            <Button type="primary" onClick={() => setModalGE(false)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Se guarda entrevista con exito" />
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalVEC}
          onOk={() => setmodalVEC(false)}
          onCancel={() => setmodalVEC(false)}
          height={1000}
          width={1200}
          footer={
            <Button type="primary" onClick={() => setmodalVEC(false)}>
              Salir
            </Button>
          }
        >
          <Row>
            <Col className="gutter-row" span={20} offset={3}>
              <Title level={3}>Visualizacion Entrevista</Title>
            </Col>
          </Row>

          <br />

          <Row gutter={16}>
            <Col className="gutter-row" span={22} offset={1}>
              <Form
                name="EnvioProyectoPuesto"
                form={formVEC}
                onFinish={""}
                onFinishFailed={""}
                autoComplete="off"
                requiredMark={true}
                initialValues={" "}
              >
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Nombre"
                      name="nombreVE"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa tu nombre.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nombre" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Departamento"
                      name="departamentoVE"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa estado departamento.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Departamento" />
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Puesto"
                      name="puestoVE"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa tu puesto.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Puesto" />
                    </Form.Item>
                  </Col>

                  <Col span={10} offset={1}>
                    <Form.Item
                      className="username"
                      label="Entrevistador"
                      name="entrevistadorVE"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa entrevistador.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Entrevistador" />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left">
                  Entrevista asignada:{" "}
                  {infoUsuario?.Entrevista || "Tipo de entrevista no asignado"}
                </Divider>

                <Row>
                  <Col span={24} offset={1}>
                    <Form.Item
                      className="username"
                      label="Pregunta 1"
                      name="p1VE"
                      rules={[
                        {
                          required: true,
                          message: "Colocar pregunta 1.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Pregunta 1" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} offset={8}>
                    <Form.Item
                      className="username"
                      label="Respuesta pregunta 1"
                      name="rp1VE"
                      rules={[
                        {
                          required: true,
                          message: "Colocar respuesta pregunta 1.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="respuesta pregunta 1" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={24} offset={1}>
                    <Form.Item
                      className="username"
                      label="Pregunta 2"
                      name="p2VE"
                      rules={[
                        {
                          required: true,
                          message: "Colocar pregunta 2.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Pregunta 2" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} offset={8}>
                    <Form.Item
                      className="username"
                      label="Respuesta pregunta 2"
                      name="rp2VE"
                      rules={[
                        {
                          required: true,
                          message: "Colocar respuesta pregunta 2.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="respuesta pregunta 2" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Divider orientation="left">Puntos Fuertes y Débiles</Divider>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Habilidades y Destrezas "
                      name="habilidadesDestrezasVE"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa tu Habilidades y Destrezas .",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Habilidades y Destrezas"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Debilidades"
                      name="debilidadesVE"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa Debilidades.",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Debilidades"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      className="username"
                      label="Comentarios"
                      name="comentariosVE"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa Comentarios.",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Comentarios"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>
      }
    </Modal>
  );
}

export default Entrevista;
