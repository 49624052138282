import React from "react";
import {
  Form,
  Select,
  Button,
  Cascader,
  Row,
  Col,
  Modal,
  Typography,
} from "antd";
const { Title } = Typography;
const { Option } = Select;

function TipoForm({
  modalPP,
  setmodalPP,
  cecosNuevos,
  puestos,
  envioFormularioPP,
  fallaEnvioFormularioPP,
  botonEnvioPP,
}) {
  const [formPP] = Form.useForm();

  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  return (
    // MODAL PROYECTO Y PUESTO
    <Modal
      centered
      visible={modalPP}
      onOk={() => setmodalPP(false)}
      onCancel={() => setmodalPP(false)}
      //height={600}
      width={1000}
      footer={
        <Button type="primary" onClick={() => setmodalPP(false)}>
          Salir
        </Button>
      }
    >
      <Row>
        <Col className="gutter-row" span={20} offset={3}>
          <Title level={3}>Seleccione Proyecto y Puesto</Title>
        </Col>
      </Row>

      <br />

      <Row gutter={16}>
        <Col className="gutter-row" span={22} offset={1}>
          <Form
            name="EnvioProyectoPuesto"
            form={formPP}
            onFinish={envioFormularioPP}
            onFinishFailed={fallaEnvioFormularioPP}
            autoComplete="off"
            requiredMark={true}
            initialValues={" "}
          >
            <Row>
              <Col span={10} offset={1}>
                <Form.Item
                  className="username"
                  label="Proyecto"
                  name="proyecto"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa el proyecto.",
                    },
                  ]}
                >
                  <Cascader
                    size="large"
                    showSearch={{ filter }}
                    options={cecosNuevos}
                    placeholder="Seleccione el departamento/campaña"
                  />
                </Form.Item>
              </Col>

              <Col span={10} offset={1}>
                <Form.Item
                  className="username"
                  label="Puesto"
                  name="puesto"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa el puesto",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Seleccione el puesto"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {puestos &&
                      puestos.map((puesto) => {
                        return (
                          <Option key={puesto.id} value={puesto.attributes.puestosTrabajos}>
                            {puesto.attributes.puestosTrabajos}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={10} offset={6}>
                <Form.Item
                  className="username"
                  label="Entrevista"
                  name="tipoEntrevista"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tipo de entrevista.",
                    },
                  ]}
                >
                  <Select defaultValue="Seleccione Entrevista" allowClear>
                    <Option value="Maneja Solo">Maneja Solo</Option>
                    <Option value="Maneja Personas">Maneja Personas</Option>
                    <Option value="Maneja Equipos">Maneja Equipos</Option>
                    <Option value="Maneja Areas">Maneja Areas</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <br />

            <Row>
              <Col span={8} offset={10}>
                <Button
                  loading={botonEnvioPP}
                  size="Large"
                  type="primary"
                  htmlType="submit"
                >
                  Enviar Datos
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default TipoForm;
