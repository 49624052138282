import React from 'react';
import { Row, Col, Image, Spin} from 'antd';
import logo from "../../assets/images/logoLoading.png";

const Loading = () => {
    return (
      <Row className="loading" justify="space-around" align="middle">
        <Col span={1}>
          <Image
            className="logoLoading"
            width={100}
            src={logo}
            preview={false}
          />
          <Spin size="large" />
        </Col>
      </Row>
    );
}

export default Loading
