import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import Axios from "axios";
import { getToken } from "../../Helpers/auth-helpers";

const { Title } = Typography;



const EChart = () => {

  const fetchDataByState = async (state, year) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };
  
    try {
      const promises = [];
      for (let month = 1; month <= 12; month++) {
        const startDate = new Date(year, month - 1, 1).toISOString();
        const endDate = new Date(year, month, 0, 23, 59, 59).toISOString();
        const url = `https://rh.api.prontobpo.cloud/api/requerimientos?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=${state}&filters[createdAt][$gte]=${startDate}&filters[createdAt][$lte]=${endDate}&populate[mother]=*`;
  
        promises.push(
          Axios.get(url, { headers }).then(response => response.data.data.length)
        );
      }
  
      return await Promise.all(promises);
    } catch (error) {
      console.error(`Error al cargar los datos para el estado ${state}:`, error);
      return Array(12).fill(0); // Retorna un array de ceros en caso de error
    }
  };
  
  const [dataByMonth, setDataByMonth] = useState({
    enProceso: Array(12).fill(0),
    completo: Array(12).fill(0),
  });

  useEffect(() => {
    const year = 2024;

    const loadAllData = async () => {
      const enProceso = await fetchDataByState('En Proceso', year);
      const completo = await fetchDataByState('Completo', year);

      setDataByMonth({
        enProceso,
        completo,
      });
    };

    loadAllData();
  }, []);

  const options = {
    series: [
      {
        name: "En Proceso",
        data: dataByMonth.enProceso,
      },
      {
        name: "Completo",
        data: dataByMonth.completo,
      },
    ],
    chart: {
      type: "bar",
      height: 400,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={4}>Requerimientos por mes</Title>
        <ReactApexChart
          options={options}
          series={options.series}
          type="bar"
          height={350}
        />
      </Col>
    </Row>
  );
};

export default EChart;
