import React, { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

///Importacion de Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  DatePicker,
  notification,
  Affix,
  Image,
  Modal,
  Checkbox,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

///// Extras
const { Meta } = Card;

const VerificarEntrevistas = (usuarioInfoCompleta) => {
  ///estados
  const [modalSucess, setModalSucess] = useState(false);
  const [cargaButonForm, setcargaButonForm] = useState(false);

  const [tipoReporte, setTiporeporte] = useState(null);

  ////Form
  const [form] = Form.useForm();

  const cargaData = async (values) => {
    console.log("Llego a la generacion del reporte", values);

    console.log("Tipo de reporte a generar", tipoReporte);

    const getFormattedDate = (date, isStartOfDay) => {
      const newDate = new Date(date);
      if (isStartOfDay) {
        newDate.setUTCHours(0, 0, 0, 0);
      } else {
        newDate.setUTCHours(23, 59, 59, 999);
      }
      return newDate.toISOString();
    };

    if (tipoReporte === "aplicantes") {
      const fechaFormateadaInicio = getFormattedDate(values.fecha1._d, true);
      const fechaFormateadaFinal = getFormattedDate(values.fecha2._d, false);

      console.log("Fecha1", fechaFormateadaInicio);
      console.log("Fecha2", fechaFormateadaFinal);

      try {
        const response = await Axios.post(
          "https://rh.api.prontobpo.cloud/graphql", // Reemplaza con la URL correcta de tu endpoint GraphQL
          {
            query: `
            query($fechaFormateadaInicio: DateTime!, $fechaFormateadaFinal: DateTime!) {
              mothers(
                filters: {
                  createdAt: { gte: $fechaFormateadaInicio, lte: $fechaFormateadaFinal }
                },
                pagination: { limit: 10000 }
              ) {
                data{
                  id
                  attributes{
                    createdAt
                    nombre
                    apellido
                    correo
                    dpi
                    perfile{
                      data{
                        attributes{
                          estado
                          requerimiento{
                            data{
                              attributes{
                                proyecto
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } 
          `,
            variables: {
              fechaFormateadaInicio: fechaFormateadaInicio,
              fechaFormateadaFinal: fechaFormateadaFinal,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(
          "Respuesta de obtencion para reporte",
          response.data.data.mothers.data
        );

        const extractedData = response.data.data.mothers.data.map((item) => {
          const { apellido, correo, createdAt, dpi, nombre, perfile } =
            item.attributes;

          const estado = perfile?.data?.attributes?.estado || null;
          const proyecto =
            perfile?.data?.attributes?.requerimiento?.data?.attributes
              ?.proyecto || null;

          return {
            apellido,
            correo,
            createdAt,
            dpi,
            nombre,
            estado,
            proyecto,
          };
        });

        console.log("Array para reporte", extractedData);

        exportToExcel(extractedData, "aplicantes");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Manejar el error según sea necesario
      }
    } else if (tipoReporte === "requerimientos") {
      const fechaFormateadaInicio = getFormattedDate(values.fecha1._d, true);
      const fechaFormateadaFinal = getFormattedDate(values.fecha2._d, false);

      console.log("Fecha1", fechaFormateadaInicio);
      console.log("Fecha2", fechaFormateadaFinal);

      try {
        const response = await Axios.post(
          "https://rh.api.prontobpo.cloud/graphql", // Reemplaza con la URL correcta de tu endpoint GraphQL
          {
            query: `
            query($fechaFormateadaInicio: DateTime!, $fechaFormateadaFinal: DateTime!) {
              requerimientos(
                filters: {
                  createdAt: { gte: $fechaFormateadaInicio, lte: $fechaFormateadaFinal }
                },
                pagination: { limit: 10000 }
              ){
              data{
                id
                attributes{
                  proyecto
                  estado
                  numPersonal
                  descripcionTrabajo
                  edad
                  genero
                  idioma
                  perfiles(pagination: {limit: 1000}){
                    data{
                      id
                      attributes{
                        nombre
                      }
                    }
                  }
                }
                }
              }
            } 
          `,
            variables: {
              fechaFormateadaInicio: fechaFormateadaInicio,
              fechaFormateadaFinal: fechaFormateadaFinal,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(
          "Respuesta de obtencion para reporte requerimientos",
          response.data.data.requerimientos.data
        );

        const formattedData = response.data.data.requerimientos.data.map(
          (item) => {
            const attributes = item.attributes;

            // Concatenar los perfiles contratados en un solo string
            const personalContratado = attributes.perfiles.data
              .map(
                (perfil) =>
                  `id: ${perfil.id}, nombre: ${perfil.attributes.nombre}`
              )
              .join(", "); // Unir los elementos en una sola cadena

            return {
              proyecto: attributes.proyecto,
              estado: attributes.estado,
              numPersonal: attributes.numPersonal,
              descripcionTrabajo: attributes.descripcionTrabajo,
              edad: attributes.edad,
              genero: attributes.genero,
              idioma: attributes.idioma,
              personalContratado: personalContratado, // Campo concatenado
            };
          }
        );

        console.log("Array para reporte Requerimientos", formattedData);

        exportToExcel(formattedData, "requerimientos");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Manejar el error según sea necesario
      }
    } else {
      notification["warning"]({
        message: "Generacion Reporte",
        description: "Seleccionar tipo de reporte a generar.",
      });
    }
  };

  const exportToExcel = (data, fileName) => {
    // Crear una hoja de trabajo a partir de los datos
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Crear un libro de trabajo y añadir la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Generar un archivo Excel y guardarlo
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.xlsx`);
    form.resetFields();
    setTiporeporte(null);
    setModalSucess(true);
  };

  const onFinish = (values) => {
    setcargaButonForm(true);

    cargaData(values)
      .then(() => {
        setcargaButonForm(false);
      })
      .catch((error) => {
        console.error("¡Error!", error);
        setcargaButonForm(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Generacion Reporte",
      description:
        "Hay campos que no se han completado, por favor revisa todos los campos.",
    });
    console.log("Failed:", errorInfo);
  };

  const seleccionReporte = (e) => {
    setTiporeporte(e.target.id); // Actualiza el estado con el ID del checkbox seleccionado
  };

  return (
    <>
      <Row gutter={[24, 18]} align="middle" className="informacion">
        <Form
          name="solicitudEmpleo"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={true}
          initialValues={{
            transporte: false,
            estudiando: false,
            horario: false,
            pretensionSalarial: 2500,
          }}
        >
          <Row gutter={[24, 18]} justify="space-around" align="middle">
            <Col span={23}>
              <Card bordered={false}>
                <Meta
                  title="Generación de reporte"
                  description="Ingresa fechas para generación de reportería."
                />

                <br />

                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Col span={24}>
                    <Row gutter={[16, 0]} justify="space-around" align="middle">
                      <Col span={12}>
                        {
                          /// Primera Seccion
                        }
                        <Row gutter={[16, 0]}>
                          <Col span={12}>
                            <Form.Item
                              className="username"
                              label="De"
                              name="fecha1"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor ingresa fecha inicio.",
                                },
                              ]}
                            >
                              <DatePicker
                                size="large"
                                placeholder="Inicio"
                                format="DD/MM/YYYY"
                                locale={locale}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              className="username"
                              label="Hasta"
                              name="fecha2"
                              rules={[
                                {
                                  required: true,
                                  message: "Por favor ingresa fecha final.",
                                },
                              ]}
                            >
                              <DatePicker
                                size="large"
                                placeholder="Fin"
                                format="DD/MM/YYYY"
                                locale={locale}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <br />
                        <Meta description="Seleccionar Reporte a generar" />
                        <br />
                        <Row
                          gutter={[24, 18]}
                          justify="space-around"
                          align="middle"
                        >
                          <Col span={8}>
                            <Checkbox
                              id="aplicantes"
                              name="reportSelection" // Mismo name para agrupar los checkboxes
                              checked={tipoReporte === "aplicantes"} // Chequea si este checkbox es el seleccionado
                              onChange={seleccionReporte}
                            >
                              Aplicantes
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              id="requerimientos"
                              name="reportSelection" // Mismo name para agrupar los checkboxes
                              checked={tipoReporte === "requerimientos"} // Chequea si este checkbox es el seleccionado
                              onChange={seleccionReporte}
                            >
                              Requerimientos
                            </Checkbox>
                          </Col>
                        </Row>
                        <br />
                        <Row
                          gutter={[24, 18]}
                          justify="space-around"
                          align="middle"
                        >
                          <Col span={2}>
                            <Form.Item>
                              <Affix offsetBottom={25}>
                                <Button
                                  loading={cargaButonForm}
                                  size="Large"
                                  type="primary"
                                  htmlType="submit"
                                >
                                  Generar Reporte
                                </Button>
                              </Affix>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6}>
                        <Image
                          width={{ width: "100%" }}
                          src={imgSolicitudForm}
                          preview={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>

        {
          ////Modal
          <Modal
            centered
            open={modalSucess}
            onOk={() => setModalSucess(false)}
            onCancel={() => setModalSucess(false)}
            footer={
              <Button type="primary" onClick={() => setModalSucess(false)}>
                Salir
              </Button>
            }
          >
            <Sucess titulo="Reporte Generado con Exito" />
          </Modal>
        }

        <br />
      </Row>
    </>
  );
};

export default VerificarEntrevistas;
