import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © 2023, hecho con
            {<HeartFilled />} by
            <a href="#teba" className="font-weight-bold" target="_blank">
              Pronto Desarollo.
            </a>
            Pronto BPO 1.0.1
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a href="#teba" className="nav-link text-muted" target="_blank">
                  Recrutify
                </a>
              </li>
              <li className="nav-item">
                <a href="#teba" className="nav-link text-muted" target="_blank">
                  Manual de uso
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://prontobpo.com"
                  className="nav-link pe-0 text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pronto BPO
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
