////React
import React, { useEffect, useState } from "react";

////React Dom
import { useParams, useLocation, useHistory } from "react-router-dom";

/////Ant design
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Divider,
  DatePicker,
  PageHeader,
} from "antd";

///Axios
import Axios from "axios";

////Locale
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

/////Helpers
import { getToken } from "../../Helpers/auth-helpers";

const { TextArea } = Input;
//////Return del componente
const Requerimientos = () => {
  ////History react Dom
  const history = useHistory();

  ///Use import {  } from "module";
  const [, setdatos] = useState(null);

  ////UseParams
  const { nombreRequerimiento } = useParams();
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");

  console.log("Nombre del requerimiento", nombreRequerimiento);
  console.log("Id del requerimiento", idRequerimiento);

  ////Form
  const [form] = Form.useForm();

  ////carga Datos
  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/requerimientos/${idRequerimiento}`,
      { headers }
    );
    setdatos(data);
    return data;
  };

  const onFill = (data) => {
    console.log(">>> Get data requerimientos, Onfill", data);

    const createdAt = data.data.attributes.createdAt;
    const date = new Date(createdAt);

    // Obtener componentes individuales
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses empiezan desde 0, por eso sumamos 1
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Formatear la fecha como desees (por ejemplo, DD/MM/YYYY HH:mm)
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    //const proyecto = JSON.parse(data.proyecto)
    //console.log(">>>> proyecto", proyecto);
    form.setFieldsValue({
      proyecto: data.data.attributes.proyecto.split(",")[1].trim(),
      numPersonas: data.data.attributes.numPersonal,
      descripcionTrabajo: data.data.attributes.descripcionTrabajo,
      funcionesPrincipales: data.data.attributes.funcionesPrincipales,
      rangoEdad: data.data.attributes.edad,
      generoPersonal: data.data.attributes.genero,
      idiomaPersonal: data.data.attributes.idioma,
      horario: data.data.attributes.horarioi,
      nivelAcademico: data.data.attributes.nivelAcademico,
      experienciaRequerida: data.data.attributes.expRequerida,
      habilidades: data.data.attributes.habilidades,
      perfilesNoDeseados: data.data.attributes.perfinNo,
      diasDescanzo: data.data.attributes.diasDescanso,
      vacaciones: data.data.attributes.vacacionesTrabajar,
      periodoAprendizaje: data.data.attributes.periodoAprendisaje,
      saladioBaseAprendiz: data.data.attributes.salarioBaseAprendiz,
      bonoLeyAprendiz: data.data.attributes.bonoLey,
      bonoProductividadAprendiz: data.data.attributes.bonoProductividadAprendiz,
      bonoAdicionalAprendiz: data.data.attributes.bonoAdicionalAprendiz,
      medicionBonoProductividad: data.data.attributes.medicionBonoProductividad,
      medicionAdicionalBono: data.data.attributes.medicionadicionalBono,
      salarioBase: data.data.attributes.salarioBase,
      bonoLey: data.data.attributes.bonoLey,
      bonoProductividad: data.data.attributes.bonoProductividad,
      bonoAdicional: data.data.attributes.bonoAdicional,
      beneficios: data.data.attributes.beneficios,
      duracionCapacitacion: data.data.attributes.fechaDuracionFormacion,
      encargadoCapacitacion: data.data.attributes.encargadoCapatiacion,
      fechaInicio: moment(
        data.data.attributes.fechaInicioProyecto,
        "YYYY/MM/DD"
      ),
      ubicacionTrabajo: data.data.attributes.ubicacionTrabajo,
      supervisorProyecto: data.data.attributes.supervisorProyecto,
      gerenteProyecto: data.data.attributes.gerenteProyecto,
      auriculares: data.data.attributes.auriculares,
      equipoIT: data.data.attributes.equipoIT,
      comentariosAdicionales: data.data.attributes.comentariosAdicionales,
      tipoRequerimiento: data.data.attributes.tipoRequerimiento,
      tipoplanilla: data.data.attributes.tipoplanilla,
      creadoPor: data.data.attributes.creadoPor,
      createdAt: formattedDate,
      urlPagina: data.data.attributes.urlPagina,
      jornada: data.data.attributes.jornada,
      tiempoLunch: data.data.attributes.tiempoLunch,
      tiempoBreak: data.data.attributes.tiempoBreak,
      aspectosBono: data.data.attributes.aspectosBono,
    });
  };

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatos().then((data) => {
      console.log("Datos obtenidos de carga datos", data);
      onFill(data);
    });
  }, []);

  /////funcion enviar
  const enviarPagina = () => {
    console.log("**************** Enviar pagina");
    history.push({ pathname: "/solicitudes/requerimientos" });
  };

  return (
    <div>
      <Form
        name="solicitudEmpleo"
        form={form}
        autoComplete="off"
        requiredMark={true}
        initialValues={
          {
            //pretensionSalarial: 2500,
          }
        }
      >
        <PageHeader
          onBack={() => enviarPagina()}
          title={nombreRequerimiento}
          subTitle={false}
        />

        <Row gutter={[24, 18]} justify="space-around" align="middle">
          <h3>Requerimiento, {nombreRequerimiento}</h3>

          <Col span={23}>
            <Card bordered={true}>
              <Col span={24}>
                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Tipo requerimiento"
                      name="tipoRequerimiento"
                      rules={[
                        {
                          required: true,
                          message: "Tipo de requerimiento.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Tipo requerimiento" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Tipo planilla"
                      name="tipoplanilla"
                      rules={[
                        {
                          required: true,
                          message: "Tipo de planilla.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Tipo planilla" />
                    </Form.Item>
                  </Col>

                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Solicitante
                  </Divider>
                  <br />

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Solicitante Requerimiento"
                      name="creadoPor"
                      rules={[
                        {
                          required: true,
                          message: "Solicitante Requerimiento.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Solicitante Requerimiento"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Fecha de Requerimiento"
                      name="createdAt"
                      rules={[
                        {
                          required: true,
                          message: "Fecha de Requerimiento.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Fecha de Requerimiento"
                      />
                    </Form.Item>
                  </Col>

                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Rasgos del Personal
                  </Divider>
                  <br />

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Proyecto"
                      name="proyecto"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa proyecto.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Proyecto" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Número de Personas"
                      name="numPersonas"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa número de personas para el proyecto.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Número de personas" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Descripción del Trabajo"
                      name="descripcionTrabajo"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa descripción del trabajo.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Descripción del trabajo"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Funciones Principales"
                      name="funcionesPrincipales"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa las funciones principales para el personal.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Funciones principales" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Rango Edad Personal"
                      name="rangoEdad"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa rango de edad del personal.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Rango de edad" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Genero Personal"
                      name="generoPersonal"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa género del personal.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Género del personal" />
                    </Form.Item>
                  </Col>

                  <Col span={14}>
                    <Form.Item
                      className="username"
                      label="Url pagina"
                      name="urlPagina"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa url.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="url" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>
            <br />
            <Card bordered={true}>
              <Col span={24}>
                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Skills personal
                  </Divider>
                  <br />

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Idioma Personal"
                      name="idiomaPersonal"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa idioma necesario del personal.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Idioma personal" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Jornada"
                      name="jornada"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa jornada.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Jornada" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Horario Necesario"
                      name="horario"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa horario necesario del personal.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Horario del requerimiento"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Tiempo de Lunch"
                      name="tiempoLunch"
                      rules={[
                        {
                          required: true,
                          message: "Lunch",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Lunch" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Tiempo de Breack"
                      name="tiempoBreak"
                      rules={[
                        {
                          required: true,
                          message: "Breack",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Breack" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Nivel Académico"
                      name="nivelAcademico"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa nivel académico del personal.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Nivel académico" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Experiencia Requerida"
                      name="experienciaRequerida"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa si se requiere experiencia.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Experiencia requerida" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Habilidades"
                      name="habilidades"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa habilidades requeridas.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Habilidades" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Perfiles no Deseados"
                      name="perfilesNoDeseados"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa perfiles no deseados.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Perfil no deseado" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>
            <br />
            <Card bordered={true}>
              <Col span={24}>
                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Horarios y Aprendizaje
                  </Divider>
                  <br />

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Días de Descanso"
                      name="diasDescanzo"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa días de descanso.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Días de descanso" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Vacaciones"
                      name="vacaciones"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa vacaciones.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Vacaciones" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Período Aprendizaje"
                      name="periodoAprendizaje"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa período de aprendizaje.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Período de aprendizaje"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>
            <br />
            <Card bordered={true}>
              <Col span={24}>
                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Salario y Bonos
                  </Divider>
                  <br />

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Salario Base Aprendiz"
                      name="saladioBaseAprendiz"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa salario base aprendiz.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Salario base aprendiz" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Bono Ley Aprendiz"
                      name="bonoLeyAprendiz"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa bono ley aprendiz.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Bono ley aprendiz" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Bono Productividad Aprendiz"
                      name="bonoProductividadAprendiz"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa bono productividad aprendiz.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Bono productividad aprendiz"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Bono Adicional Aprendiz"
                      name="bonoAdicionalAprendiz"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa bono adicional aprendiz.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Bono adicional aprendiz"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Salario Base"
                      name="salarioBase"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa salario base de la plaza.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Salario base ofrecido" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Bono Ley"
                      name="bonoLey"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa bono ley de la plaza.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Bono ley ofrecido" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Bono de Productividad"
                      name="bonoProductividad"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa bono.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Bono Ofrecido" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Bonificaciones Adicionales"
                      name="bonoAdicional"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa bono adicional;.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Bono adicional ofrecido"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={18}>
                    <Form.Item
                      className="username"
                      label="Aspectos medicion bono"
                      name="aspectosBono"
                      rules={[
                        {
                          required: true,
                          message: "Aspectos medicion bono.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Aspectos medicion bono"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>
            <br />
            <Card bordered={true}>
              <Col span={24}>
                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Beneficios y Capacitacion
                  </Divider>
                  <br />

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Beneficios"
                      name="beneficios"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa beneficios.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Beneficios" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Duración Capacitación"
                      name="duracionCapacitacion"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa duracion capacitación.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Duracion capacitación" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Encargado Capacitación"
                      name="encargadoCapacitacion"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa encargado capacitación.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Encargado capacitación"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Fecha Inicio"
                      name="fechaInicio"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa fecha de inicio.",
                        },
                      ]}
                    >
                      <DatePicker
                        size="large"
                        placeholder="Fecha Inicio"
                        format="DD/MM/YYYY"
                        locale={locale}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      className="username"
                      label="Ubicación Trabajo"
                      name="ubicacionTrabajo"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa ubicacion del trabajo.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Ubicacion Trabajo" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>
            <br />
            <Card bordered={true}>
              <Col span={24}>
                <Row gutter={[16, 0]} justify="space-around" align="middle">
                  <Divider
                    style={{ fontSize: 17, fontWeight: "bold" }}
                    orientation="left"
                  >
                    Jefes y Adicionales
                  </Divider>
                  <br />

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Supervisor Proyecto"
                      name="supervisorProyecto"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa supervisor proyecto.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Supervisor proyecto" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Gerente Proyecto"
                      name="gerenteProyecto"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa gerente proyecto.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Gerente proyecto" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Auriculares"
                      name="auriculares"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor ingresa si descuenta auriculares.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Auriculares" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      label="Equipo"
                      name="equipoIT"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa si necesita equipo.",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Equipo" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      className="username"
                      label="Comentarios Adicionales (Herramientas o tema especial de la cuenta, experiencia en algo en especifico, lugar de capacitación etc.)"
                      name="comentariosAdicionales"
                      rules={[
                        {
                          required: true,
                          message: "Por favor ingresa comentarios adicionales.",
                        },
                      ]}
                      labelCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                      wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}
                    >
                      <TextArea
                        size="large"
                        placeholder="Ingresa comentarios adicionales"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ width: "100%", maxWidth: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Col>
        </Row>

        {/* <h2>{JSON.stringify(datos)}</h2> */}
      </Form>
    </div>
  );
};

export default Requerimientos;
