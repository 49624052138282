/////React
import React, { useState } from "react";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

import { UploadOutlined } from "@ant-design/icons";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Tooltip,
  InputNumber,
  DatePicker,
  Upload,
  Radio,
  Button,
  notification,
  Modal,
  Image,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;

///Componente Form
const Formulario = ({ usuario }) => {


  const [defaultFileList, setDefaultFileList] = useState([]);

  ///estados
  const [modalSucess, setModalSucess] = useState(false);
  const [cargaButonForm, setcargaButonForm] = useState(false);



  ////Form
  const [form] = Form.useForm();


  const enviarCorreoPerfil = async (correo, nombre) => {
    const headers = {
      
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      "key": "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      "email": correo,
      "nombre": nombre,
      "asunto": "Aplicacion Plaza Pronto BPO",
      "mensaje":
        "Se guardo tu solicitud de empleo exitosamente, nos estaremos comunicando con tigo para poder seguir con el proceso de aplicacion a plaza PRONTO BPO"
    };

    const datos = JSON.stringify(datosEnviar);

    console.log("DATOS PARA EL ENVO DEL CORREO",datos, headers);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/aplicante`,
      datos,
      { headers }
    );
    return data;
  };

  const updateFilesListHook = async (file) => {
    //const files = defaultFileList.push(file)
    return setDefaultFileList([...defaultFileList]);
  }

  ////Upload FileReader
  const uploadFile = async options => {
    const { onSuccess, onError, file, onProgress } = options;

    console.log("Datos de upload", onSuccess, onError, file, onProgress );

    var formData = new FormData();
    formData.append("files", file);
    await Axios.post('https://rh.api.prontobpo.cloud/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${getToken()}`
        }
    }).then(data => {
      console.log("return upload images", data.data[0]);
      updateFilesListHook(data.data[0]);
      onSuccess("Ok");

    }).catch(error => {
      onError({ event: error });
    })

  
  
  };

///  Delete File Strapi
  const deleteStrapiFile = async (fileId) => {
    await Axios.delete(`https://rh.api.prontobpo.cloud/api/upload/files/${fileId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((data) => {
        console.log("return upload images", data.data);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
  //onRemove
  const onRemoveUpoload = files => {
    console.log(">>> On Remove", files, defaultFileList);

    const nameFile = files.name;

    const deleteFileStrapi = defaultFileList.filter(function (i, n) {
      console.log(n, i.name, nameFile);
      return i.name === nameFile;
    });

    const removeID = deleteFileStrapi[0].id;

    console.log("delete strapi", removeID);
    deleteStrapiFile(removeID);

   const valor = defaultFileList.filter(function (i, n){
        console.log(n, i.name, nameFile)
        return i.name !== nameFile;
    });

    console.log("Valor Encontrado", valor);
    setDefaultFileList([...valor]);

  }

  //////Formularios
  const envioSolicitudEmpleo = async (valores) => {

    console.log(">>> Archivos a Subir ", valores);
    
    let nombres = "";
    let apellidos = "";

    if (valores.nombre.includes(" ")) {
      nombres = valores.nombre.split(" ");
      nombres = nombres[0];
    } else {
      nombres = valores.nombre;
    }

    if (valores.apellido.includes(" ")) {
      apellidos = valores.apellido.split(" ");
      if (apellidos.length >= 3) {
        apellidos = apellidos[0] + " " + apellidos[1];
      } else {
        apellidos = apellidos[0];
      }
    } else {
      apellidos = valores.apellido;
    }


    enviarCorreoPerfil(valores.correo, valores.nombre);

    const datos = JSON.stringify({

      proyecto: "No Asignado",
      nombre: valores.nombre,
      apellido: valores.apellido,
      direccion: valores.direccion,
      dpi: valores.dpi,
      fecha: valores["nacimiento"].format("YYYY-MM-DD"),
      transporte: valores.transporte,
      telefono: valores.Telefono,
      celular: valores.celular,
      correo: valores.correo,
      actualmenteEstudiando: valores.estudiando,
      enteroNosotros: valores.entero,
      restriccionHorario: valores.horario,
      posicionAplica: valores.posicionAplica,
      fechaPuedeIniciarLaborar:
        valores["fechaInicioLabores"].format("YYYY-MM-DD"),
      pretencionSalarial: valores.pretensionSalarial,
      nombreInstitucion1: valores.nombreiInstitucion,
      tituloRecibido1: valores.tituloRecibido,
      certificadoFisico1: valores.certificadoFisico,
      nombreInstitucion2: valores.nombreiInstitucion2,
      tituloRecibido2: valores.tituloRecibido2,
      certificadoFisico2: valores.certificadoFisico2,
      nombreInstitucion3: valores.nombreiInstitucion3,
      tituloRecibido3: valores.tituloRecibido3,
      certificadoFisico3: valores.certificadoFisico3,
      nombreReferencia1: valores.nombreReferencia,
      profesionReferencia1: valores.profesionReferencia,
      ocupacionReferencia1: valores.ocupacionReferencia,
      telefonoReferencia1: valores.telefonoReferencia,
      nombreReferencia2: valores.nombreReferencia2,
      profesionReferencia2: valores.profesionReferencia2,
      ocupacionReferencia2: valores.ocupacionReferencia2,
      telefonoReferencia2: valores.telefonoReferencia2,
      nombreReferencia3: valores.nombreReferencia3,
      profesionReferencia3: valores.profesionReferencia3,
      ocupacionReferencia3: valores.ocupacionReferencia3,
      telefonoReferencia3: valores.telefonoReferencia3,
      empleador1: valores.empleador1,
      puesto1: valores.puesto1,
      fechaInicio1: valores["fechaInicio1"].format("YYYY-MM-DD"),
      motivoRetiro1: valores.motivoRetiro1,
      empleador2: valores.empleador2,
      puesto2: valores.puesto2,
      fechaInicio2: valores["fechaInicio2"].format("YYYY-MM-DD"),
      motivoRetiro2: valores.motivoRetiro2,
      empleador3: valores.empleador3,
      puesto3: valores.puesto3,
      fechaInicio3: valores["fechaInicio3"].format("YYYY-MM-DD"),
      motivoRetiro3: valores.motivoRetiro3,
      empleador4: valores.empleador4,
      puesto4: valores.puesto4,
      fechaInicio4: valores["fechaInicio4"].format("YYYY-MM-DD"),
      motivoRetiro4: valores.motivoRetiro4,
      published_at: moment(Date.now()).format("YYYY-MM-DDTHH:mm:ss.ZZ"),
      creadoPor: usuario,
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/recruitify-mothers",
      datos,
      { headers }
    );
    console.log(data);

    const datosPerfil = JSON.stringify({

      adjuntos: defaultFileList,
      recruitify_mother: `${data.id}`,
      tags: "nuevo aplicante",
      estado: "Aplicado",

      tareas: [
        {
          estado: false,
          nombre: "Entrevista Candidato",
          fecha: `${moment().format("YYYY-MM-DD")}`,
          creado: "Recuitify",
        },
        {
          estado: false,
          nombre: "Test Habilidades",
          fecha: `${moment().format("YYYY-MM-DD")}`,
          creado: "Recuitify",
        },
        {
          estado: false,
          nombre: "ver CV y descripcion Puesto",
          fecha: `${moment().format("YYYY-MM-DD")}`,
          creado: "Recuitify",
        },
      ],

      potencial: "0",
      nombre: `${nombres} ${apellidos}`,


    });

    console.log(datosPerfil);

    Axios.post("https://rh.api.prontobpo.cloud/api/recruitiify-perfils", datosPerfil, {
      headers,
    }).then((values) => {
        console.log(values);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return data;
  };

  const onFinish = (values) => {
    setcargaButonForm(true);
    //console.log(values.fechaInicio1._d)
    //console.log('Success:', values);

    envioSolicitudEmpleo(values)
      .then((info) => {
        setModalSucess(true);
        onReset();
        setcargaButonForm(false);
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setcargaButonForm(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Solicitud de Empleo",
      description:
        "Hay campos que no se han completado, por favor revisa todos los campos.",
    });
    console.log(
      "Fecha:",
      errorInfo?.values["nacimiento"]?.format("YYYY/MM/DD")
    );
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={true}
        initialValues={{
          transporte: false,
          estudiando: false,
          horario: false,
          pretensionSalarial: 2500,
        }}
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }
          <Col span={23}>
            <Card bordered={false}>
              <Meta
                title="Solicitud de Empleo"
                description="Ingresa los siguientes datos para completar el formulario. Todos los datos se guardarán de manera segura."
              />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal
                </Divider>

                <Col span={24}>
                  <Row gutter={[16, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Apellidos"
                            name="apellido"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tus apellidos.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Apellidos" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Dirección"
                            name="direccion"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu dirección.",
                              },
                            ]}
                          >
                            <TextArea
                              size="large"
                              placeholder="Dirección"
                              autoSize={{ minRows: 1, maxRows: 6 }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Correo Electrónico"
                            name="correo"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  "Ingresa tu correo, ejemplo: ejemplo@prontobpo.com .",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Correo" />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                        <Col span={6}>
                          <Form.Item
                            className="username"
                            label="Fecha de Nacimiento"
                            name="nacimiento"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu fecha de nacimiento.",
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              placeholder="Nacimiento"
                              format="DD/MM/YYYY"
                              locale={locale}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            className="username"
                            label="Teléfono"
                            name="Telefono"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu número de teléfono.",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              min={7}
                              placeholder="Número de teléfono"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            className="username"
                            label="Celular"
                            name="celular"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu número de celular.",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              min={7}
                              placeholder="Número de celular"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Tooltip
                            trigger={["focus"]}
                            title={`Ingresa el DPI sin espacios o guiones ( - ) solo números. `}
                            placement="topLeft"
                          >
                            <Form.Item
                              className="username"
                              label="DPI"
                              name="dpi"
                              rules={[
                                {
                                  type: "number",
                                  required: true,
                                  message:
                                    "Por favor ingresa tu DPI sin espacios o guiones ( - ) solo números. ",
                                },
                              ]}
                            >
                              <InputNumber size="large" placeholder="DPI" />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>

                {
                  /// Tercera Seccion
                }
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Tiene trasporte"
                    name="transporte"
                    rules={[
                      {
                        required: true,
                        message: "Por favor indica si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Actualmente esta estudiando"
                    name="estudiando"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Tiene restricción de horario"
                    name="horario"
                    rules={[
                      {
                        required: true,
                        message: "Por favor seleccione sí o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="¿Cómo se enteró de nosotros?"
                    name="entero"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Pronto BPO"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Vacante
                </Divider>
                {
                  /// Cuarta Seccion
                }
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Posición a la que aplica"
                    name="posicionAplica"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa la posición",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Posición aplicante" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Fecha en la que puede iniciar a laborar"
                    name="fechaInicioLabores"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de inicio de labores.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Pretensión Salarial"
                    name="pretensionSalarial"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Educacion
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Educacion
                </Divider>

                {
                  /// Quinta Seccion
                }

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Nombre institución"
                    name="nombreiInstitucion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la institución",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="1"
                      size="large"
                      placeholder="Institución"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Título Recibido"
                    name="tituloRecibido"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el título recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Titulo" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Certificado físico"
                    name="certificadoFisico"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el certificado físico.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Certificado" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreiInstitucion2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la institución",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="2"
                      size="large"
                      placeholder="Institución"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label=""
                    name="tituloRecibido2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el título recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Título" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label=""
                    name="certificadoFisico2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el certificado físico.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Certificado" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreiInstitucion3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la institución",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="3"
                      size="large"
                      placeholder="Institución"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label=""
                    name="tituloRecibido3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el título recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Título" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label=""
                    name="certificadoFisico3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el certificado físico.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Certificado" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Refencias Personales
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Referencias Personales
                </Divider>

                {
                  /// Sexta Seccion
                }

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="1"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Profesión"
                    name="profesionReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Ocupación"
                    name="ocupacionReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la ocupación de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Número de teléfono"
                    name="telefonoReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Número de teléfono" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreReferencia2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="2"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="profesionReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="ocupacionReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la ocupación de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="telefonoReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Número de teléfono" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="nombreReferencia3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="3"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="profesionReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="ocupacionReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la ocupación de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="telefonoReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Número de teléfono" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Experiencia Laboral
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Experiencia Laboral
                </Divider>

                {
                  /// Septima Seccion
                }

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la experiencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="1" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto1"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio1"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de la experiencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="empleador2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experiencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="2" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="puesto2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experiencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="fechaInicio2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experiencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="motivoRetiro2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="empleador3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experiencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="3" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="puesto3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experiencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="fechaInicio3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experiencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="motivoRetiro3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="empleador4"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experiencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="4" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="puesto4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experiencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="fechaInicio4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experiencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label=""
                    name="motivoRetiro4"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Meta
                  title="Adjuntar Archivos"
                  description="Adjunte curriculum y certificados importantes"
                />
              </Row>

              <br />

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col>
                  <Form.Item 
                  name="adjuntos"
                  id="adjuntos"
                  >
                  <Upload
                    listType="picture"
                    customRequest={uploadFile}
                    onRemove={onRemoveUpoload}
                    multiple
                  >
                    <Button icon={<UploadOutlined />}>Subir Archivos</Button>
                  </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col span={3}>
                  <Form.Item>
                    <Button
                      loading={cargaButonForm}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar Formulario
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => setModalSucess(false)}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button type="primary" onClick={() => setModalSucess(false)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Solicitud de Empleo" />
        </Modal>
      }

      <br />
    </>
  );
};

export default Formulario;
