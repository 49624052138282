const tipos = {
    "Maneja Solo": "SOLO",
    "Maneja Personas": "PERSONAS",
    "Maneja Equipos": "EQUIPOS",
    "Maneja Areas": "AREAS",
};

async function getTreeData(tipo) {
    console.log("Tipo de Get data",tipo)
    switch (tipo) {
        case "SOLO":
            const { manejaSolo } = await import("./dataTipos/manejaSolo");
            return manejaSolo;
        case "PERSONAS":
            const { manejaPersonas } = await import("./dataTipos/manejaPersonas");
            return manejaPersonas;
        case "EQUIPOS":
            const { manejaEquipos } = await import("./dataTipos/manejaEquipos");
            return manejaEquipos;
        case "AREAS":
            const { manejaAreas } = await import("./dataTipos/manejaAreas");
            return manejaAreas;
        default:
            throw new Error(`Tipo no soportado: ${tipo}`);
    }
}

export { tipos, getTreeData };
