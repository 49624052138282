import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

///Importacion de Axios
import Axios from "axios";

/////Ant Design
import { Card, Row, Col, Empty, Button, Typography, Table } from "antd";

import imgVacio from "../assets/images/solicitudes/vacio.png";

//Helpers
import { getToken } from "../Helpers/auth-helpers";
import moment from "moment";

const headers = {
  Authorization: `Bearer ${getToken()}`,
  "Content-Type": "application/json",
};

const VerificarEntrevistas = (usuarioInfoCompleta) => {
  const history = useHistory();

  const { Title } = Typography;

  const [mapeo, setMapeo] = useState([]);
  const [tabla, setTabla] = useState([]);
  const [accion, setAccion] = useState("");

  const columns = [
    {
      title: "Proyecto",
      dataIndex: "proyecto",
      key: "proyecto",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text) => <a href="#">{text}</a>,
    },
    {
      title: "Número Personal",
      dataIndex: "numero_personal",
      key: "numero_personal",
    },
    {
      title: "Puesto a contratar",
      dataIndex: "descripcionTrabajo",
      key: "descripcionTrabajo",
    },
  ];

  const cargaDatosTabla = async () => {
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/requerimientos?filters[estado][$eq]=En Proceso&populate[perfiles]=*`,
      { headers }
    );

    console.log("datos de requerimientos", data);

    const tabla = data.data.map((number) => {
      console.log("Número dentro del mapeo:", number); // Agregar console.log() aquí
      return {
        key: number.id,
        proyecto: number.attributes.proyecto,
        numero_personal:
          number.attributes.numPersonal -
          number.attributes.perfiles.data.length,
        descripcionTrabajo: number.attributes.descripcionTrabajo,
      };
    });

    console.log("Datos de la tabla", tabla);

    setTabla(tabla);
    return data;
  };

  const cargaTipoUsuario = async () => {
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/users?filters[email][$eq]=${usuarioInfoCompleta.usuarioMail}`,
      { headers }
    );

    return data[0].tipo_usuario;
  };

  const cargaOfertas = async () => {
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles?filters[estado][$eq]=Oferta&populate=mother.asignadoSistema&populate=mother.perfile`,
      { headers }
    );

    console.log("datos Ofertar", data);

    setMapeo(data.data);

    return data;
  };

  const cargaDatosAsignadoEntrevistas = async () => {
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[estado][$eq]=Pendiente&filters[asignadoEntrevista][$eq]=${usuarioInfoCompleta.usuarioMail}&filters[mother][perfile][estado][$eq]=Entrevista&sort[0]=fechaEntrevista:asc&sort[1]=horaEntrevista:asc&populate[mother][populate][0]=perfile`,
      { headers }
    );

    console.log("Datos de obtencion reclutador", data);

    setMapeo(data.data);
    return data;
  };

  /////funcion enviar Formulario Reclutador
  const EnviarEntrevista = async (idMother, idPerfil) => {
    console.log("Id Mother", idMother);
    console.log("Id Perfil", idPerfil);

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${idMother}`,
      { headers }
    );

    console.log("Respuesta asignados", dataasignados);

    const tipoEntrevista = dataasignados.data.data[0].attributes.Entrevista;

    if (tipoEntrevista === "Maneja Solo") {
      history.push({
        pathname: "../FormularioManejaSolo", ///Direccion a donde dirige
        search: `?id=${idPerfil}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Personas") {
      history.push({
        pathname: "../FormularioManejaPersonas", ///Direccion a donde dirige
        search: `?id=${idPerfil}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Equipos") {
      history.push({
        pathname: "../FormularioManejaEquipos", ///Direccion a donde dirige
        search: `?id=${idPerfil}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Areas") {
      history.push({
        pathname: "../FormularioManejaAreas", ///Direccion a donde dirige
        search: `?id=${idPerfil}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    }
  };
  /////Fin funcion enviar Formulario Reclutador

  /////funcion enviar Formulario Ofertador
  const EnviarEntrevistaOfertador = async (idMother) => {
    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${idMother}&populate=mother&populate=mother.perfile`,
      { headers }
    );

    console.log("data asignador", dataasignados);

    const tipoEntrevista = dataasignados.data.data[0].attributes.Entrevista;

    if (tipoEntrevista === "Maneja Solo") {
      history.push({
        pathname: "../ConfirmarFormularioManejaSolo", ///Direccion a donde dirige
        search: `?id=${dataasignados.data.data[0].attributes.mother.data.attributes.perfile.data.id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Personas") {
      history.push({
        pathname: "../ConfirmarFormularioManejaPersonas", ///Direccion a donde dirige
        search: `?id=${dataasignados.data.data[0].attributes.mother.data.attributes.perfile.data.id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Equipos") {
      history.push({
        pathname: "../ConfirmarFormularioManejaEquipos", ///Direccion a donde dirige
        search: `?id=${dataasignados.data.data[0].attributes.mother.data.attributes.perfile.data.id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Areas") {
      history.push({
        pathname: "../ConfirmarFormularioManejaAreas", ///Direccion a donde dirige
        search: `?id=${dataasignados.data.data[0].attributes.mother.data.attributes.perfile.data.id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    }
  };
  /////Fin funcion enviar Formulario Ofertador

  /////funcion enviar a Formulario
  const verPerfil = async (id) => {
    console.log("**************** Enviar pagina", id);
    history.push({
      pathname: "../FormularioPerfil", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });
  };

  useEffect(() => {
    cargaTipoUsuario().then((tipoUsuario) => {
      if (tipoUsuario === "Ofertador") {
        cargaOfertas();
        setAccion("Ofertador");
      } else {
        cargaDatosAsignadoEntrevistas();
        setAccion("No Ofertador");
      }
    });

    cargaDatosTabla();
    // Agrega las dependencias que faltan aquí
  }, []);

  if (accion === "Ofertador") {
    return (
      <>
        <Row gutter={[24, 18]} align="middle" className="informacion">
          {mapeo ? (
            mapeo.map((data) => {
              console.log("Datos mapeados:", data);

              return (
                <Col span={6} key={data.id}>
                  <Card>
                    <Title level={4}>
                      {"Nombre: " + data.attributes.nombre}
                    </Title>

                    <Title level={5}>
                      {"Fecha Ingreso: " +
                        moment(
                          data.attributes.mother.data.attributes.publishedAt
                        ).format("D-M-Y  hh:mm a")}
                    </Title>

                    <Title level={5}>
                      {"Proyecto Reclutador: " +
                        JSON.parse(
                          data.attributes.mother.data.attributes.asignadoSistema
                            .data.attributes.Proyecto
                        )[0]}
                    </Title>
                    <br />

                    <Col span={24}>
                      <Row>
                        <Col span={4}>
                          <Button
                            type="primary"
                            onClick={() =>
                              EnviarEntrevistaOfertador(
                                data.attributes.mother.data.id
                              )
                            }
                          >
                            Ofertar
                          </Button>
                        </Col>

                        <Col span={6} offset={4}>
                          <Button
                            type="primary"
                            onClick={() =>
                              verPerfil(
                                data.attributes.mother.data.attributes.perfile
                                  .data.id
                              )
                            }
                          >
                            Ver Perfil
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Col span={24}>
              <Row justify="center" align="bottom">
                <Col span={8}>
                  <Empty
                    image={imgVacio}
                    imageStyle={{
                      width: "100%",
                      height: "300px",
                      border: "7px",
                    }}
                    description={<span>No hay datos</span>}
                  ></Empty>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Row gutter={[24, 18]} justify="space-around" align="middle">
          <Col span={24}>
            <Table columns={columns} dataSource={tabla} />
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row gutter={[24, 18]} align="middle" className="informacion">
          {mapeo ? (
            mapeo.map((data) => {
              console.log("Datos a mapear", data); // Agregamos el console.log aquí
              return (
                <Col span={6} key={data.id}>
                  <Card>
                    <Title level={4}>
                      {"Nombre: " +
                        data.attributes.mother.data.attributes.nombre +
                        " " +
                        data.attributes.mother.data.attributes.apellido}
                    </Title>
                    <Title level={5}>
                      {"Hora: " +
                        moment(
                          data.attributes.horaEntrevista,
                          "HH:mm:ss"
                        ).format("hh:mm a")}
                    </Title>
                    <Title level={5}>
                      {"Fecha: " + data.attributes.fechaEntrevista}
                    </Title>

                    <Title level={5}>
                      {"Proyecto Reclutador: " +
                        JSON.parse(data.attributes.Proyecto)[0]}
                    </Title>

                    <Row gutter={16} style={{ marginTop: 16 }}>
                      <Col span={9}>
                        <Button
                          type="primary"
                          block
                          onClick={() =>
                            EnviarEntrevista(
                              data.attributes.mother.data.id,
                              data.attributes.mother.data.attributes.perfile
                                .data.id
                            )
                          }
                        >
                          Entrevistar
                        </Button>
                      </Col>
                      <Col span={5}>
                        <Button
                          type="primary"
                          block
                          onClick={() =>
                            verPerfil(
                              data.attributes.mother.data.attributes.perfile.data.id
                            )
                          }
                        >
                          Ver
                        </Button>
                      </Col>
                      <Col span={10}>
                        <Button
                          type="primary"
                          block
                          onClick={""}
                        >
                          Reprogramar
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Col span={24}>
              <Row justify="center" align="bottom">
                <Col span={8}>
                  <Empty
                    image={imgVacio}
                    imageStyle={{
                      width: "100%",
                      height: "300px",
                      border: "7px",
                    }}
                    description={<span>No hay datos</span>}
                  ></Empty>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <Row gutter={[24, 18]} justify="space-around" align="middle">
          <Col span={24}>
            <Table columns={columns} dataSource={tabla} />
          </Col>
        </Row>
      </>
    );
  }
};

export default VerificarEntrevistas;
