import React from "react";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../../Helpers/auth-helpers";

const { Title } = Typography;

function EChart() {

  const fetchDataByState = async (state, year) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };
  
    try {
      const promises = [];
      for (let month = 1; month <= 12; month++) {
        const startDate = new Date(year, month - 1, 1).toISOString();
        const endDate = new Date(year, month, 0, 23, 59, 59).toISOString();
        const url = `https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=${state}&filters[createdAt][$gte]=${startDate}&filters[createdAt][$lte]=${endDate}&populate[mother]=*`;
  
        promises.push(
          Axios.get(url, { headers }).then(response => response.data.data.length)
        );
      }
  
      return await Promise.all(promises);
    } catch (error) {
      console.error(`Error al cargar los datos para el estado ${state}:`, error);
      return Array(12).fill(0); // Retorna un array de ceros en caso de error
    }
  };

  const [dataByMonthAplicado, setDataByMonthAplicado] = useState(Array(12).fill(0));
  const [dataByMonthEntrevista, setDataByMonthEntrevista] = useState(Array(12).fill(0));
  const [dataByMonthDeclinado, setDataByMonthDeclinado] = useState(Array(12).fill(0));
  const [dataByMonthOferta, setDataByMonthOferta] = useState(Array(12).fill(0));
  const [dataByMonthContratado, setDataByMonthContratado] = useState(Array(12).fill(0));

  useEffect(() => {
    const year = 2024;

    const loadAllData = async () => {
      const aplicado = await fetchDataByState('Aplicado', year);
      setDataByMonthAplicado(aplicado);

      const entrevista = await fetchDataByState('Entrevista', year);
      setDataByMonthEntrevista(entrevista);

      const declinado = await fetchDataByState('Declinado', year);
      setDataByMonthDeclinado(declinado);

      const oferta = await fetchDataByState('Oferta', year);
      setDataByMonthOferta(oferta);

      const contratado = await fetchDataByState('Contratado', year);
      setDataByMonthContratado(contratado);
    };

    loadAllData();
  }, []);

  const options = {
    series: [{
      name: 'Aplicado',
      data: dataByMonthAplicado
    }, {
      name: 'Entrevista',
      data: dataByMonthEntrevista
    }, {
      name: 'Declinado',
      data: dataByMonthDeclinado
    }, {
      name: 'Oferta',
      data: dataByMonthOferta
    }, {
      name: 'Contratado',
      data: dataByMonthContratado
    }],
    chart: {
      type: 'bar',
      height: 400
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Title level={4}>Aplicantes por mes</Title>
        <ReactApexChart options={options} series={options.series} type="bar" height={350} />
      </Col>
    </Row>
  );
}

export default EChart;
