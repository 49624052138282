import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  notification,
  Alert,
} from "antd";
import signinbg from "../assets/images/img-signin.png";
import {
  TwitterOutlined,
  InstagramOutlined,
  GoogleOutlined,
  HeartFilled,
  FacebookOutlined
} from "@ant-design/icons";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = ({...props}) => {
  const { login } = props
  const [loadingButton, setloadingButton] = useState(false);
  const [errorAlert, seterrorAlert] = useState(false);

/////Notificacion de Alerta Login
const openNotificationWithIcon = () => {
  notification["warning"]({
    message: "Usuario",
    description: "El correo o la contraseña son incorrectas.",
  });
};


/////////Componente
  const onFinish = async(values) => {

    setloadingButton(true);
    const identifier = values.identifier;
    const password = values.password;

    try {
      await login(identifier, password).then(
        () => {
          setloadingButton(false);
        }
      );
    } catch (error) {
      openNotificationWithIcon();
      console.log("Error try login (Signin.js)", error);
      setloadingButton(false);
      seterrorAlert(true);
    }

  };

  const onFinishFailed = async(errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <span>Recruitify APP</span>
          </div>
          <div className="header-col header-nav"></div>
          <div className="header-col header-btn">
            <Button type="primary">Pronto BPO 1.0.8</Button>
          </div>
        </Header>

        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around" align="middle">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Log in</Title>
              <Title className="font-regular text-muted" level={5}>
                Ingresa con tu correo y contraseña. 
              </Title>

              {errorAlert ? (
                  <Alert
                    message="Usuario"
                    description="El correo o la contraseña son incorrectas."
                    type="error"
                    closable
                    onClose={() => {
                      seterrorAlert(false);
                    }}
                  />
                
              ) : (
                <></>
              )}

              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Correo"
                  name="identifier"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu correo de Pronto BPO!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Correo@prontobpo.com" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Contraseña"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingresa tu contraseña!",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="Contraseña" />
                </Form.Item>

                <br />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loadingButton}
                  >
                    Ingresar al sistema
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="" />
            </Col>
          </Row>
        </Content>

        <Footer>
          <Row justify="center" align="bottom">
            <Col span={24}>
              <Menu mode="horizontal">
                <Menu.Item key="100">About Us</Menu.Item>
                <Menu.Item key="101">Manual de uso</Menu.Item>
                <Menu.Item key="102">Pronto BPO</Menu.Item>
              </Menu>
            </Col>
            <Col span={24}>
              <Menu mode="horizontal" className="menu-nav-social">
                <Menu.Item key="103">
                  <Link to="#">{<GoogleOutlined />}</Link>
                </Menu.Item>
                <Menu.Item key="104">
                  <Link to="#">{<TwitterOutlined />}</Link>
                </Menu.Item>
                <Menu.Item key="105">
                  <Link to="#">{<InstagramOutlined />}</Link>
                </Menu.Item>
                <Menu.Item key="106">
                  <Link to="#">{<FacebookOutlined />}</Link>
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={24}>
              <div className="copyright">
                © 2023, hecho con
                {<HeartFilled />} by
                <a href="#teba" className="font-weight-bold" target="_blank">
                  Pronto Desarollo.
                </a>
                Pronto BPO
              </div>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </>
  );
}

export default SignIn;
