/////React
import React, { useState, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Tooltip,
  InputNumber,
  DatePicker,
  Radio,
  Button,
  Modal,
  Image,
  PageHeader,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;

///Componente Form

const Formulario2 = (nombre) => {
  ////History react Dom
  const history = useHistory();

  ///Use import {  } from "module";
  const [datosCarga, setdatosCarga] = useState(null);

  ///estados
  const [modalSucessdenegado, setModalSucessdenegado] = useState(false);

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");
  ///fin carga de ID recibido

  const [cargaButonForm, setcargaButonForm] = useState(false);

  ////Form
  const [form] = Form.useForm();

  ////carga Datos
  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );
    setdatosCarga(data);
    return data;
  };

  const onFill = (data) => {
    console.log(">>> Get data muro, Onfill", data);
    form.setFieldsValue({
      nombre: data.data.attributes.mother.data.attributes.nombre,
      apellido: data.data.attributes.mother.data.attributes.apellido,
      direccion: data.data.attributes.mother.data.attributes.direccion,
      correo: data.data.attributes.mother.data.attributes.correo,
      nacimiento: moment(
        data.data.attributes.mother.data.attributes.fecha,
        "YYYY/MM/DD"
      ),
      Telefono: data.data.attributes.mother.data.attributes.celular,
      celular: data.data.attributes.mother.data.attributes.telefono,
      dpi: data.data.attributes.mother.data.attributes.dpi,
      transporte: data.data.attributes.mother.data.attributes.transporte,
      estudiando:
        data.data.attributes.mother.data.attributes.actualmenteEstudiando,
      horario: data.data.attributes.mother.data.attributes.restriccionHorario,
      entero: data.data.attributes.mother.data.attributes.enteroNosotros,
      posicionAplica:
        data.data.attributes.mother.data.attributes.posicionAplica,
      fechaInicioLabores: moment(
        data.data.attributes.mother.data.attributes.fechaPuedeIniciarLaborar,
        "YYYY/MM/DD"
      ),
      pretensionSalarial:
        data.data.attributes.mother.data.attributes.pretencionSalarial,
      nombreiInstitucion:
        data.data.attributes.mother.data.attributes.nombreInstitucion1,
      nombreiInstitucion2:
        data.data.attributes.mother.data.attributes.nombreInstitucion2,
      nombreiInstitucion3:
        data.data.attributes.mother.data.attributes.nombreInstitucion3,
      tituloRecibido:
        data.data.attributes.mother.data.attributes.tituloRecibido1,
      tituloRecibido2:
        data.data.attributes.mother.data.attributes.tituloRecibido2,
      tituloRecibido3:
        data.data.attributes.mother.data.attributes.tituloRecibido3,
      certificadoFisico:
        data.data.attributes.mother.data.attributes.certificadoFisico1,
      certificadoFisico2:
        data.data.attributes.mother.data.attributes.certificadoFisico2,
      certificadoFisico3:
        data.data.attributes.mother.data.attributes.certificadoFisico3,
      nombreReferencia:
        data.data.attributes.mother.data.attributes.nombreReferencia1,
      nombreReferencia2:
        data.data.attributes.mother.data.attributes.nombreReferencia2,
      nombreReferencia3:
        data.data.attributes.mother.data.attributes.nombreReferencia3,
      profesionReferencia:
        data.data.attributes.mother.data.attributes.profesionReferencia1,
      profesionReferencia2:
        data.data.attributes.mother.data.attributes.profesionReferencia2,
      profesionReferencia3:
        data.data.attributes.mother.data.attributes.profesionReferencia3,
      ocupacionReferencia:
        data.data.attributes.mother.data.attributes.ocupacionReferencia1,
      ocupacionReferencia2:
        data.data.attributes.mother.data.attributes.ocupacionReferencia2,
      ocupacionReferencia3:
        data.data.attributes.mother.data.attributes.ocupacionReferencia3,
      telefonoReferencia:
        data.data.attributes.mother.data.attributes.telefonoReferencia1,
      telefonoReferencia2:
        data.data.attributes.mother.data.attributes.telefonoReferencia2,
      telefonoReferencia3:
        data.data.attributes.mother.data.attributes.telefonoReferencia3,
      empleador1: data.data.attributes.mother.data.attributes.empleador1,
      empleador2: data.data.attributes.mother.data.attributes.empleador2,
      empleador3: data.data.attributes.mother.data.attributes.empleador3,
      empleador4: data.data.attributes.mother.data.attributes.empleador4,
      puesto1: data.data.attributes.mother.data.attributes.puesto1,
      puesto2: data.data.attributes.mother.data.attributes.puesto2,
      puesto3: data.data.attributes.mother.data.attributes.puesto3,
      puesto4: data.data.attributes.mother.data.attributes.puesto4,
      fechaInicio1: moment(
        data.data.attributes.mother.data.attributes.fechaInicio1,
        "YYYY/MM/DD"
      ),
      fechaInicio2: moment(
        data.data.attributes.mother.data.attributes.fechaInicio2,
        "YYYY/MM/DD"
      ),
      fechaInicio3: moment(
        data.data.attributes.mother.data.attributes.fechaInicio3,
        "YYYY/MM/DD"
      ),
      fechaInicio4: moment(
        data.data.attributes.mother.data.attributes.fechaInicio4,
        "YYYY/MM/DD"
      ),
      motivoRetiro1: data.data.attributes.mother.data.attributes.motivoRetiro1,
      motivoRetiro2: data.data.attributes.mother.data.attributes.motivoRetiro2,
      motivoRetiro3: data.data.attributes.mother.data.attributes.motivoRetiro3,
      motivoRetiro4: data.data.attributes.mother.data.attributes.motivoRetiro4,
    });
  };

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatos().then((data) => {
      onFill(data);
    });
  }, []);

  console.log("Datos Carga ", datosCarga);

  const correoPerfilDeclinado = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.data.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.data.attributes.mother.data.attributes.nombre,
      asunto: "Terminacion proceso aplicacion plaza",
      mensaje:
        "Gracias por tu interés en aplicar con nosotros. Por el momento hemos decidido no continuar con tu proceso de selección, sin embargo agradecemos enormemente tu tiempo e interés en Pronto BPO, Te invitamos a que continúes pendiente de nuestras plazas y no dudes en aplicar si consideras llenas los requisitos",
    };

    const datos = JSON.stringify(datosEnviar);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/denegar`,
      datos,
      { headers }
    );
    return data;
  };

  //////LogPPP
  const agregadoLog = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Denegacion de perfil",
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: datosCarga?.data.attributes.nombre,
        idMother: datosCarga?.data.attributes.mother.data.id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };
  ////Final LogPPP

  const denegarSolicitudEmpleo = async () => {
    setcargaButonForm(true);

    const datosActualizar = JSON.stringify({
      data: {
        estado: "Declinado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );
    console.log("Envio de actualizacion Estado", dataStatus);
    setModalSucessdenegado(true);
    setcargaButonForm(false);
    agregadoLog();
    correoPerfilDeclinado();
  };

  /////funcion enviar
  const enviarPaginadenegado = () => {
    setModalSucessdenegado(false);
    console.log("**************** Enviar pagina");
    history.push({ pathname: "/solicitudes/perfiles" });
  };

  ////Retorrno Componente
  return (
    <>
      <PageHeader
        onBack={() =>
          history.push({
            pathname: "FormularioPerfil",
            search: `?id=${idRequerimiento}`,
          })
        }
        title={"Formulario Perfil"}
        subTitle={false}
      />
      <Form
        name="solicitudEmpleo"
        form={form}
        autoComplete="off"
        requiredMark={true}
        initialValues={
          {
            //pretensionSalarial: 2500,
          }
        }
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }
          <Col span={23}>
            <Card bordered={false}>
              <Meta
                title="Solicitud de Empleo"
                description="Ingresa los siguientes datos para completar el formulario. Todos lo datos se guardaran de manera segura."
              />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal
                </Divider>

                <Col span={24}>
                  <Row gutter={[16, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombres"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Apellidos"
                            name="apellido"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tus apellidos.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Apellidos" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Direccion"
                            name="direccion"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu direccion.",
                              },
                            ]}
                          >
                            <TextArea
                              size="large"
                              placeholder="Direccion"
                              autoSize={{ minRows: 1, maxRows: 6 }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Correo Electronico"
                            name="correo"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  "Ingresa tu correo, ejemplo: ejemplo@prontobpo.com .",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Correo" />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Fecha de Nacimiento"
                            name="nacimiento"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu fecha de nacimiento.",
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              placeholder="Nacimiento"
                              format="DD/MM/YYYY"
                              locale={locale}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Telefono"
                            name="Telefono"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu numero de telefono.",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              min={7}
                              placeholder="Numero de telefono"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Celular"
                            name="celular"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu numero de celular.",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              min={7}
                              placeholder="Numero de celular"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Tooltip
                            trigger={["focus"]}
                            title={`Ingresa el DPI sin espacios o guiones ( - ) solo numeros. `}
                            placement="topLeft"
                          >
                            <Form.Item
                              className="username"
                              label="DPI"
                              name="dpi"
                              rules={[
                                {
                                  //type: "number",
                                  //required: false,
                                  message:
                                    "Por favor ingresa tu DPI sin espacios o guiones ( - ) solo numeros. ",
                                },
                              ]}
                            >
                              <InputNumber size="large" placeholder="DPI" />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>

                {
                  /// Tercera Seccion
                }
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Tiene trasporte"
                    name="transporte"
                    rules={[
                      {
                        required: true,
                        message: "Por favor indica si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Actualmente esta estudiando"
                    name="estudiando"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Tiene restriccion de horario"
                    name="horario"
                    rules={[
                      {
                        required: true,
                        message: "Por favor seleccione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Como se entero de nosotros"
                    name="entero"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Pronto BPO"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Vacante
                </Divider>
                {
                  /// Cuarta Seccion
                }
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Posicion a la que aplica"
                    name="posicionAplica"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa la posicion",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Posicion aplicante" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Fecha en la que puede inciar a laborar"
                    name="fechaInicioLabores"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de inicio de labores.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Pretension Salarial"
                    name="pretensionSalarial"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Educacion
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Educacion
                </Divider>

                {
                  /// Quinta Seccion
                }

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Nombre institucion"
                    name="nombreiInstitucion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la institucion",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="1"
                      size="large"
                      placeholder="Institucion"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Titulo Recibido"
                    name="tituloRecibido"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el titulo recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Titulo" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Certificado (Sellado y Firmado por Contraloria)"
                    name="certificadoFisico"
                    rules={[
                      {
                        required: true,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Nombre Institucion"
                    name="nombreiInstitucion2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la institucion",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="2"
                      size="large"
                      placeholder="Institucion"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Titulo Recibido"
                    name="tituloRecibido2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el titulo recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Titulo" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Certificado (Sellado y Firmado por Contraloria)"
                    name="certificadoFisico2"
                    rules={[
                      {
                        required: false,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Nombre Institucion"
                    name="nombreiInstitucion3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la institucion",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="3"
                      size="large"
                      placeholder="Institucion"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Titulo Recibido"
                    name="tituloRecibido3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el titulo recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Titulo" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Certificado (Sellado y Firmado por Contraloria)"
                    name="certificadoFisico3"
                    rules={[
                      {
                        required: false,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Refencias Personales
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Referencias Personales
                </Divider>

                {
                  /// Sexta Seccion
                }

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="1"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Profesión"
                    name="profesionReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Ocupación"
                    name="ocupacionReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la ocupacion de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Teléfono"
                    name="telefonoReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Número de teléfono" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="2"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Profesion"
                    name="profesionReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Ocupacion"
                    name="ocupacionReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la ocupacion de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Teléfono"
                    name="telefonoReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Número de teléfono" />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="3"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Profesion"
                    name="profesionReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Ocupacion"
                    name="ocupacionReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la ocupacion de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Teléfono"
                    name="telefonoReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Número de teléfono" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Experiencia Laboral
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Experiencia Laboral
                </Divider>

                {
                  /// Septima Seccion
                }

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="1" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto1"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio1"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de fin"
                    name="fechaFin1"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Fin de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="2" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de fin"
                    name="fechaFin2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Fin de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="3" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de final"
                    name="fechaFinal3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Final de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador4"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="4" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicioo de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de final"
                    name="fechaFinal4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Finalo de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro4"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col span={2}>
                  <Form.Item>
                    <Button
                      loading={cargaButonForm}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                      danger
                      onClick={denegarSolicitudEmpleo}
                    >
                      Declinar Perfil
                    </Button>
                  </Form.Item>
                </Col>

                {/*
                                <Col span={2}>
                                    <Form.Item>
                                        <Affix offsetBottom={25}>
                                            <Button
                                                htmlType="button"
                                                onClick={() => setModalSucess(true)}
                                            >
                                                Mostrar modal
                                            </Button>
                                        </Affix>
                                    </Form.Item>
                                </Col>
                                 Modal Prueba
                 
                  */}
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        ////Modal
        <Modal
          centered
          visible={modalSucessdenegado}
          onOk={() => setModalSucessdenegado(false)}
          onCancel={() => setModalSucessdenegado(false)}
          footer={
            <Button type="primary" onClick={enviarPaginadenegado}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Formulario denegado por perfil!" />
        </Modal>
      }

      <br />
    </>
  );
};

export default Formulario2;
