////React
import React, { useEffect, useState } from "react";

///Axios
import Axios from "axios";

////AntDesign
import {
  Card,
  Avatar,
  Row,
  Col,
  Tooltip,
  List,
  Empty,
  Button,
  Skeleton,
} from "antd";

/////Iconos Imagenes
import imgVacio from "../assets/images/solicitudes/vacio.png";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Iconos / Imagenes
import { Link } from "react-router-dom";

/////Retorno Componente
const Solicitudes = () => {
  const { Meta } = Card;

  ///////Estates
  const [solicitudes, setSolicitudes] = useState([]);
  const [cargar, setcargar] = useState(true);

  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const queryGraphQl = JSON.stringify({
      query: ` query {
        requerimientos(filters: {estado: {eq: "En Proceso"}}) {
          data{
            id
            attributes{
              estado
              proyecto
              numPersonal
              descripcionTrabajo
            }
          }
          
        }
      }`,
      variables: {},
    });

    try {
      const response = await Axios.post(
        "https://rh.api.prontobpo.cloud/graphql",
        queryGraphQl,
        { headers }
      );

      console.log(
        "Datos de la obtencion de requerimientos",
        response.data.data.requerimientos.data
      );

      setSolicitudes(response.data.data.requerimientos.data);

      return response;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    cargaDatos()
      .then((data) => {
        console.log(data);
        return setcargar(false);
      })
      .catch((error) => {
        return console.log(error);
      });
  }, []);

  if (cargar) {
    return (
      <Row gutter={[24, 18]} justify="start" align="top">
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
        <Col xxl={4} xl={5} lg={8} md={12} sm={24} xs={24}>
          <Card>
            <Skeleton loading={cargar} active avatar></Skeleton>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[24, 18]} justify="space-around" align="middle">
      {solicitudes ? (
        solicitudes.map((data) => {
          console.log("Data obtenida para cards", data);
          return (
            <Col key={data.id} xxl={4} xl={6} lg={8} md={8} sm={12} xs={24}>
              <Card
                hoverable={true}
                style={{ width: "120%" }}
                // actions={[
                //   <SettingOutlined key="setting" />,
                //   <EditOutlined key="edit" />,
                //   <EllipsisOutlined key="ellipsis" />,
                // ]}
              >
                <Meta
                  avatar={
                    <Avatar size="large" style={{ backgroundColor: "#033143" }}>
                      {data.id[0]}{" "}
                      {/* Acceder al primer carácter de la cadena */}
                    </Avatar>
                  }
                  title={
                    data.id.length === 1 ? (
                      <Tooltip placement="top" title={data.attributes.proyecto.split(',')[1].trim()}>
                        {data.attributes.proyecto.split(',')[1].trim()}
                      </Tooltip>
                    ) : (
                      <Tooltip placement="top" title={data.attributes.proyecto.split(',')[1].trim()}>
                        {data.attributes.proyecto.split(',')[1].trim()}
                      </Tooltip>
                    )
                  }
                  description={
                    <List
                      size="small"
                      header={false}
                      footer={false}
                      bordered={false}
                      dataSource={[data.attributes]} // Poner data.attributes dentro de un array
                      renderItem={(item) => (
                        <List.Item key={data.id}>
                          <Link
                            to={
                              `/solicitudes/requerimientos/${item.descripcionTrabajo}?id=${data.id}` ||
                              "/solicitudes/requerimientos/"
                            }
                          >
                            {item.numPersonal +
                              " - " +
                              item.descripcionTrabajo}
                          </Link>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Card>
            </Col>
          );
        })
      ) : (
        <Col span={24}>
          <Row justify="center" align="bottom">
            <Col span={8}>
              <Empty
                image={imgVacio}
                imageStyle={{
                  width: "100%",
                  height: "300px",
                  border: "7px",
                }}
                description={<span>No hay datos</span>}
              >
                <Button type="primary">Crear una solicitud</Button>
              </Empty>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default Solicitudes;
