////React
import React from 'react'

/////Lottie
import { Player } from '@lottiefiles/react-lottie-player';

/////Ant Design
import { Result } from "antd";




/////Return de componente
const Sucess = ({ titulo = "Solicitud", info = "Se envio la solicitud con éxito. Recruitify." }) => {
    return (
        <>
            <Result
                icon={
                    <Player
                        autoplay
                        loop
                        src="https://assets1.lottiefiles.com/packages/lf20_dp4jvfth.json"
                        style={{ height: '50%', width: '50%' }}
                    >
                    </Player>
                }
                title={titulo}
                subTitle={info}
            />
        </>
    )
}

export default Sucess;
