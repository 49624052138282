/////React
import React, { useState, useEffect } from "react";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Tooltip,
  InputNumber,
  DatePicker,
  Descriptions,
  Button,
  notification,
  Modal,
  Select,
  Image,
  Slider,
  Cascader,
  Popover,
} from "antd";

////Locale
import locale from "antd/es/date-picker/locale/es_ES";

/////Imagenes - Iconos
import imgRequerimientoForm from "../assets/images/requerimiento/formRequerimiento.png";
import imgTiempos from "../assets/images/requerimiento/tiempos.jpeg";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;

////Componente
export default function Requerimiento({ usuario }) {
  ///estados
  const [modalSucess, setModalSucess] = useState(false);
  const [cargaButonForm, setcargaButonForm] = useState(false);
  const [puestos, setPuestos] = useState(null);
  const [cecosNuevos, setCecosNuevos] = useState([]);

  ////Efectos
  useEffect(() => {
    cargaDatos();
  }, []);

  ////Form
  const [form] = Form.useForm();

  //////Carga de datos / Envio de datos
  const envioSolicitudRequermiento = async (valores) => {
    const datos = {
      data: {
        proyecto: valores.proyecto.join(","),
        numPersonal: valores.numPersonal,
        descripcionTrabajo: valores.descripcionTrabajo,
        funcionesPrincipales: valores.funcionesPrincipales,
        edad: valores.edad.join(","),
        genero: valores.genero,
        idioma: valores.idioma,
        horarioi: valores.horarioi,
        nivelAcademico: valores.nivelAcademico,
        expRequerida: valores.expRequerida,
        habilidades: valores.habilidades,
        diasDescanso: valores.diasDescanso,
        vacacionesTrabajar: valores.vacacionesTrabajar,
        perfinNo: valores.perfinNo,
        periodoAprendisaje: valores.periodoAprendisaje,
        salarioBaseAprendiz: valores.salarioBaseAprendiz,
        bonoLeyAprendiz: valores.bonoLeyAprendiz,
        bonoProductividadAprendiz: valores.bonoProductividadAprendiz,
        bonoAdicionalAprendiz: valores.bonoAdicionalAprendiz,
        //medicionBonoProductividad: valores.medicionBonoProductividad,
        //medicionadicionalBono: valores.medicionadicionalBono,
        beneficios: valores.beneficios,
        fechaDuracionFormacion: valores.fechaDuracionFormacion,
        encargadoCapatiacion: valores.encargadoCapatiacion,
        ubicacionTrabajo: valores.ubicacionTrabajo,
        supervisorProyecto: valores.supervisorProyecto,
        gerenteProyecto: valores.gerenteProyecto,
        auriculares: valores.auriculares,
        equipoIT: valores.equipoIT,
        comentariosAdicionales: valores.comentariosAdicionales,
        salarioBase: valores.salarioBase,
        bonoLey: valores.bonoLey,
        bonoProductividad: valores.bonoProductividad,
        bonoAdicional: valores.bonoAdicional,
        tiempoExtraordinario: valores.tiempoExtraordinario,
        fechaInicioProyecto: valores.fechaInicioProyecto
          .toISOString()
          .slice(0, 10),
        creadoPor: usuario,
        tipoRequerimiento: valores.tiporequerimiento,
        tipoplanilla: valores.tipoplanilla,
        estado: "En Proceso",
        urlPagina: valores.urlPagina,
        jornada: valores.jornada,
        tiempoLunch: valores.tiempoLunch,
        tiempoBreak: valores.tiempoBreak,
        aspectosBono: valores.aspectosBono,
      },
    };

    const datosJSON = JSON.stringify(datos);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/requerimientos",
      datosJSON,
      { headers }
    );

    return data;
  };

  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const queryGraphQlPuestos = JSON.stringify({
      query: `query{
        puestos(
          sort: "puestosTrabajos:asc",
          pagination: { limit: -1 } 
        ){
          data{
            id
            attributes{
              puestosTrabajos
            }
          }
        }
      }`,
      variables: {},
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/graphql",
      queryGraphQlPuestos,
      { headers }
    );

    setPuestos(data.data.puestos.data);
    ///console.log(data.data.puestosTrabajos);

    const queryGraphQl = JSON.stringify({
      query: `query {
        departamentoCecos(pagination: { limit: -1 }) {
          data {
            id
            attributes {
              departamento
              nombreCeco
            }
          }
        } 
      }`,
      variables: {},
    });

    await Axios.post("https://rh.api.prontobpo.cloud/graphql", queryGraphQl, {
      headers,
    })
      .then((res) => {
        const data = res.data.data.departamentoCecos.data;

        // Objeto para mantener un registro de los departamentos únicos
        const departamentosUnicos = {};

        // Procesa los datos para agrupar los nombreCeco bajo un solo departamento
        data.forEach((item) => {
          const { departamento, nombreCeco } = item.attributes;

          // Verifica si el departamento ya ha sido agregado
          if (!departamentosUnicos[departamento]) {
            // Si no ha sido agregado, inicializa el departamento con un array vacío
            departamentosUnicos[departamento] = [];
          }

          // Agrega el nombreCeco al departamento correspondiente
          departamentosUnicos[departamento].push({
            value: nombreCeco,
            label: nombreCeco,
          });
        });

        // Ordena los nombreCecos de cada departamento alfabéticamente
        Object.keys(departamentosUnicos).forEach((departamento) => {
          departamentosUnicos[departamento].sort((a, b) =>
            a.label.localeCompare(b.label)
          );
        });

        // Formatea los datos para que coincidan con la estructura requerida por el Cascader y ordena los departamentos
        const processedData = Object.entries(departamentosUnicos)
          .map(([departamento, nombreCecos]) => ({
            value: departamento,
            label: departamento,
            children: nombreCecos,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        // Establece los datos procesados en el estado
        setCecosNuevos(processedData);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////Formulario
  const onFinish = (values) => {
    setcargaButonForm(true);
    //console.log('Success:', values);

    envioSolicitudRequermiento(values)
      .then((values) => {
        setModalSucess(true);
        setcargaButonForm(false);
        onReset();
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setcargaButonForm(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Requerimientos",
      description:
        "Hay campos que no se han completado, por favor revisa todos los campos.",
    });
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  function filter(inputValue, path) {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={true}
        initialValues={{
          edad: [18, 65],
        }}
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {/* Informacion General */}

          <Col span={23}>
            <Card bordered={false}>
              <Meta
                title="Requerimiento de Personal"
                description="Recuerda! Para hacer un requerimiento de personal, es necesario tener el contrato de servicios  firmado por el cliente."
              />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información General
                </Divider>

                {
                  /// Primera Seccion
                }

                <Col span={12}>
                  <Row gutter={[16, 0]}>
                    <Col span={18} offset={3}>
                      <Form.Item
                        className="username"
                        label="Tipo de Requerimiento"
                        name="tiporequerimiento"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor indique el tipo de requerimiento",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Seleccione el tipo de requerimiento"
                        >
                          <Option value="Proyecto Nuevo">Proyecto Nuevo</Option>
                          <Option value="Proyecto Existente">
                            Proyecto Existente
                          </Option>
                          <Option value="Requerimiento Interno">
                            Requerimiento Interno
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={18} offset={3}>
                      <Form.Item
                        className="username"
                        label="Tipo de Planilla"
                        name="tipoplanilla"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique el tipo de planilla",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Seleccione el tipo de planilla"
                        >
                          <Option value="Servicios OK">Servicios OK</Option>
                          <Option value="Grupo TeLlamo">Grupo TeLlamo</Option>
                          <Option value="ProntoBPO SV">ProntoBPO SV</Option>
                          <Option value="ProntoBPO HN">ProntoBPO HN</Option>
                          <Option value="ProntoBPO USA">ProntoBPO USA</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Proyecto"
                        name="proyecto"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingresa el proyecto.",
                          },
                        ]}
                      >
                        <Cascader
                          size="large"
                          showSearch={{ filter }}
                          options={cecosNuevos}
                          placeholder="Seleccione el departamento/campaña"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Cantidad de personal"
                        name="numPersonal"
                        rules={[
                          {
                            type: "number",
                            required: true,
                            message:
                              "Por favor ingresa la cantidad de personal requerida.",
                          },
                        ]}
                      >
                        <InputNumber min={1} size="large" placeholder="0" />
                      </Form.Item>
                    </Col>

                    {
                      /// Segunda Seccion
                    }

                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Posicion laboral"
                        name="descripcionTrabajo"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingresa el puesto",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Seleccione el puesto"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {puestos &&
                            puestos.map((puesto) => {
                              //console.log("Puesto", puesto);
                              return (
                                <Option
                                  key={puesto.id}
                                  value={puesto.attributes.puestosTrabajos}
                                  selected
                                >
                                  {puesto.attributes.puestosTrabajos}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Url Pagina Web"
                        name="urlPagina"
                        rules={[
                          {
                            required: true,
                            message: "Por favor ingresa url",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Ingresa Url" />
                      </Form.Item>
                    </Col>

                    <Col span={20} offset={2}>
                      <Form.Item
                        className="username"
                        label="Funciones principales"
                        name="funcionesPrincipales"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor ingresa las funciones del empleado.",
                          },
                        ]}
                      >
                        <TextArea
                          size="large"
                          placeholder="Funciones"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={8}>
                  <Image
                    width={{ width: "100%" }}
                    src={imgRequerimientoForm}
                    preview={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Requerimientos
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row justify="space-around" align="middle">
                <Col span={23}>
                  <Row gutter={[16, 2]} justify="space-around" align="middle">
                    <Divider
                      style={{ fontSize: 17, fontWeight: "bold" }}
                      orientation="left"
                    >
                      Requerimientos
                    </Divider>

                    {
                      ///seccion 4
                    }
                    <Col span={8}>
                      <Form.Item
                        className="username"
                        label="Edad deseada"
                        name="edad"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique la edad deseada",
                          },
                        ]}
                      >
                        <Slider marks={{ 0: "0", 18: "18", 65: "65" }} range />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        className="username"
                        label="Genero"
                        name="genero"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique el Genero",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Seleccione el Genero"
                        >
                          <Option value="Hombre">Hombre</Option>
                          <Option value="Mujer">Mujer</Option>
                          <Option value="Indiferente">Indiferente</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        className="username"
                        label="Idioma"
                        name="idioma"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique el idioma",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Indique el nivel en % del idioma deseado "
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Jornada"
                        name="jornada"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique jornada",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Seleccione Jornada"
                        >
                          <Option value="Diurna: 8 hrs./día">
                            Diurna: 8 hrs./día
                          </Option>
                          <Option value="Mixta: 7 hrs./día">
                            Mixta: 7 hrs./día
                          </Option>
                          <Option value="Nocturna: 6 hrs./día">
                            Nocturna: 6 hrs./día
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Popover
                        content="Especificar horario, ej.: de lunes a viernes 8:00 a 17:00 horas"
                        title="Horario de trabajo"
                        trigger="hover"
                      >
                        <Form.Item
                          className="username"
                          label="Horario de trabajo"
                          name="horarioi"
                          rules={[
                            {
                              required: true,
                              message:
                                "Por favor indique el horario de trabajo",
                            },
                          ]}
                        >
                          <TextArea
                            size="large"
                            placeholder="Horario"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                          />
                        </Form.Item>
                      </Popover>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Tiempos de Lunch"
                        name="tiempoLunch"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique tiempos de lunch",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Seleccione Tiempo"
                        >
                          <Option value="30 min.">30 min.</Option>
                          <Option value="45 min.">45 min.</Option>
                          <Option value="60 min.">60 min.</Option>
                          <Option value="Otro">Otro</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="username"
                        label="Tiempos de Break"
                        name="tiempoBreak"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique tiempos de break",
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Seleccione Tiempo"
                        >
                          <Option value="1 break de 15 min.">
                            1 break de 15 min.
                          </Option>
                          <Option value="2 breaks de 15 min. (total: 30 min.)">
                            2 breaks de 15 min. (total: 30 min.)
                          </Option>
                          <Option value="No tiene breaks Otros">
                            No tiene breaks Otros
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {
                      /// seccion 3
                    }
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        className="username"
                        label="Nivel académico deseado"
                        name="nivelAcademico"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor indique el nivel académico deseado",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Nivel académico " />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        className="username"
                        label="Tipo de experiencia requerida"
                        name="expRequerida"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor ingresa el tipo de experencia que requiere.",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Experiencia requerida"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Tooltip title="Ejemplo: (negociación, servicio al cliente, comunicación asertiva, habilidades de escritura, etc.)">
                        <Form.Item
                          className="username"
                          label="Habilidades y competencias deseadas"
                          name="habilidades"
                          rules={[
                            {
                              required: true,
                              message:
                                "Por favor ingresa las Habilidades y competencias deseadas",
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Habilidades" />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    {
                      ////Seccion 3 - 2
                    }
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        className="username"
                        label="Días de descanso"
                        name="diasDescanso"
                        rules={[
                          {
                            required: true,
                            message: "Por favor indique los Días de descanso",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Días de descanso" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        className="username"
                        label="Vacaciones para trabajar"
                        name="vacacionesTrabajar"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor indique las vacaciones para trabajar",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Vacaciones para trabajar"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        className="username"
                        label="Perfiles o historial no deseados"
                        name="perfinNo"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor indique el perfil o historial no deseados",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Perfiles o historial no deseados"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />

                  <Divider
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      whiteSpace: "normal",
                      textAlign: "left",
                      margin: "24px 0",
                    }}
                    orientation="left"
                  >
                    Fecha requerida de Ingreso de personal a operaciones ( Tomar
                    en cuenta la información de la gráfica adjunta) para el
                    desarrollo del proceso para el proyecto
                  </Divider>

                  <Col span={16} offset={4}>
                    <Image
                      width={{ width: "100%" }}
                      height={{ height: "100%" }}
                      src={imgTiempos}
                      preview={false}
                    />
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Tipo de contrato
          }
          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 2]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Tipo de contrato
                </Divider>

                <Divider orientation="left">Aprendiz</Divider>
                {
                  ///Período de aprendizaje:
                }
                <Col span={24}>
                  <Row gutter={[8, 2]} justify="space-around" align="middle">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Descriptions title="" bordered>
                        <Descriptions.Item label="Período de aprendizaje:">
                          <Form.Item
                            className="username"
                            label="Duracion"
                            name="periodoAprendisaje"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor indique el periodo de aprendizaje",
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              style={{ width: "100%" }}
                              placeholder="Seleccione la duracion"
                            >
                              <Option value="No Aplica">No Aplica</Option>
                              <Option value="1 mes">1 mes</Option>
                              <Option value="2 meses">2 meses</Option>
                              <Option value="3 meses">3 meses</Option>
                              <Option value="4 meses">4 meses</Option>
                              <Option value="5 meses">5 meses</Option>
                            </Select>
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Descriptions
                        title=""
                        layout="vertical"
                        bordered
                        column={{ xs: 1, sm: 2, md: 4 }}
                      >
                        <Descriptions.Item label="Salario base">
                          <Form.Item
                            className="username"
                            label={false}
                            name="salarioBaseAprendiz"
                            rules={[
                              {
                                required: true,
                                message: "Salario base",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              placeholder="0"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Bono de ley">
                          <Form.Item
                            className="username"
                            label={false}
                            name="bonoLeyAprendiz"
                            rules={[
                              {
                                required: true,
                                message: "Bono de ley Aprediz",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              placeholder="0"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Bono de productividad">
                          <Form.Item
                            className="username"
                            label={false}
                            name="bonoProductividadAprendiz"
                            rules={[
                              {
                                required: false,
                                message: "Bono de productividad",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              placeholder="0"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="Bonificaciones adicionales">
                          <Form.Item
                            className="username"
                            label={false}
                            name="bonoAdicionalAprendiz"
                            rules={[
                              {
                                required: false,
                                message: "Bono de adicional",
                              },
                            ]}
                          >
                            <InputNumber
                              size="large"
                              placeholder="0"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                </Col>

                <Divider orientation="left">Planilla</Divider>
                {
                  ////Salario
                }
                <Col span={24}>
                  <Row gutter={[8, 2]} justify="space-around" align="middle">
                    <Col span={24}>
                      <Descriptions title="" bordered>
                        <Descriptions.Item label="Nómina de sueldos">
                          <Descriptions
                            title=""
                            layout="vertical"
                            bordered
                            column={4}
                          >
                            <Descriptions.Item label="Salario base">
                              <Form.Item
                                className="username"
                                label={false}
                                name="salarioBase"
                                rules={[
                                  {
                                    required: true,
                                    message: "Salario base",
                                  },
                                ]}
                              >
                                <InputNumber size="large" placeholder="0" />
                              </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Bono de ley">
                              <Form.Item
                                className="username"
                                label={false}
                                name="bonoLey"
                                rules={[
                                  {
                                    required: true,
                                    message: "Bono de ley Aprediz",
                                  },
                                ]}
                              >
                                <InputNumber size="large" placeholder="0" />
                              </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Bono de productividad">
                              <Form.Item
                                className="username"
                                label={false}
                                name="bonoProductividad"
                                rules={[
                                  {
                                    required: true,
                                    message: "Bono de productividad",
                                  },
                                ]}
                              >
                                <InputNumber size="large" placeholder="0" />
                              </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Bonificaciones adicionales">
                              <Form.Item
                                className="username"
                                label={false}
                                name="bonoAdicional"
                                rules={[
                                  {
                                    required: false,
                                    message: "Bono de adicional",
                                  },
                                ]}
                              >
                                <InputNumber size="large" placeholder="0" />
                              </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Aspectos Medicion Bono">
                              <Form.Item
                                className="username"
                                label={false}
                                name="aspectosBono"
                                rules={[
                                  {
                                    required: true,
                                    message: "Aspectos Medicion",
                                  },
                                ]}
                              >
                                <TextArea
                                  size="large"
                                  placeholder="Aspectos Medicion"
                                  autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                              </Form.Item>
                            </Descriptions.Item>
                          </Descriptions>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                </Col>

                <Divider />

                {
                  ///seccion 4
                }

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Aplica para horas extras"
                    name="tiempoExtraordinario"
                    rules={[
                      {
                        required: true,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="username"
                    label="Beneficios"
                    name="beneficios"
                    rules={[
                      {
                        required: true,
                        message: "Por favor indique los Beneficios",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Beneficios" />
                  </Form.Item>
                </Col>

                {
                  ///seccion 4
                }
                <Col span={10}>
                  <Form.Item
                    className="username"
                    label="Fecha y duración de la capacitacion"
                    name="fechaDuracionFormacion"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor indiquela Fecha y duración de la capacitacion",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="capacitacion" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Tooltip title="IMPORTANTE MENCIONAR SI NECESITA ESPECIALIZAR UN ENTRENADOR INTERNO DE PRONTO BPO">
                    <Form.Item
                      className="username"
                      label="Encargado de la capacitacion y seguimiento"
                      name="encargadoCapatiacion"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor indique el encargado de capaticacion",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Encargado" />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio del proyecto"
                    name="fechaInicioProyecto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor indique fecha inicio de proyecto",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha inicio proyecto"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="ubicacionTrabajo"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor indique la ubicacion del puesto de trabajo",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Seleccione el puesto"
                    >
                      <Option value="Casa">Casa</Option>
                      <Option value="Oficina">Oficina</Option>
                      <Option value="Hibrido">Hibrido</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Divider orientation="left">Pronto BPO</Divider>
                <Row gutter={[16, 16]} justify="space-around" align="middle">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      className="username"
                      label="Supervisor a cargo del proyecto"
                      name="supervisorProyecto"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor indique el supervisor del proyecto",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Supervisor" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      className="username"
                      label="Gerente a cargo del proyecto"
                      name="gerenteProyecto"
                      rules={[
                        {
                          required: true,
                          message: "Por favor indique el gerente del proyecto",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Gerente" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      className="username"
                      label={
                        <div
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          En caso de requerir "headset" para el nuevo
                          colaborador ingresar a este
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScM6KKpzjSWqC17J2BnSUnVbEb_kor1I39dH-RCHj7IG9AL7g/viewform?usp=send_form"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#1890ff", marginLeft: "5px" }}
                          >
                            Enlace
                          </a>
                        </div>
                      }
                      name="auriculares"
                      rules={[
                        {
                          required: true,
                          message: "Por favor selecciona",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Seleccione"
                      >
                        <Option value="Si">Si</Option>
                        <Option value="No necesita headset">
                          No necesita headset
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      className="username"
                      label={
                        <div
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          ¿Se llenó el formulario de IT para solicitud de
                          equipo? Este proceso será revisado por IT y SE DEBERÁ
                          SOLICITAR EQUIPO PARA TODOS SUS REQUERIMIENTOS DE
                          PERSONAL. **Importante tomar en cuenta que en su
                          periodo de aprendiz estará laborando en oficina. Dudas
                          con Jonathan García a este
                          <a
                            href="https://forms.office.com/r/JwGkh0hHaM"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#1890ff", marginLeft: "5px" }}
                          >
                            Enlace
                          </a>
                        </div>
                      }
                      name="equipoIT"
                      rules={[
                        {
                          required: true,
                          message:
                            "Por favor indique los comentarios adicionales",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Seleccione"
                      >
                        <Option value="Si">Si</Option>
                        <Option value="No necesita equipo">
                          No necesita equipo
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="username"
                    label={
                      <div>
                        Comentarios Adicionales (Herramientas o tema especial de
                        la cuenta, experiencia en algo en especifico, lugar de
                        capacitación etc.)
                      </div>
                    }
                    name="comentariosAdicionales"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor indique los comentarios adicionales",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ////Botones
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col span={3}>
                  <Form.Item>
                    <Button
                      loading={cargaButonForm}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar Formulario
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      {/* Modal */}
      <Modal
        centered
        visible={modalSucess}
        onOk={() => setModalSucess(false)}
        onCancel={() => setModalSucess(false)}
        footer={
          <Button type="primary" onClick={() => setModalSucess(false)}>
            Salir
          </Button>
        }
      >
        <Sucess
          titulo="Solicitud de Proyecto"
          info="Se envio la informacion con exito, puedes ver tu solicitud en la seccion de solicitudes."
        />
      </Modal>
    </>
  );
}
