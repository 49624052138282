//React
import React, { useState, useEffect } from "react";

//Reac Dom
import { useLocation, useHistory } from "react-router-dom";

///Axios
import Axios from "axios";

import { UploadOutlined } from "@ant-design/icons";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Button,
  Affix,
  notification,
  Modal,
  Image,
  Collapse,
  Badge,
  Tag,
  Upload,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";
import moment from "moment";

///// Extras de importacion ANT
const { Meta } = Card;
const { Panel } = Collapse;

///Componente Form
const FormularioOfertar = ({ usuario, correoLogeado }) => {
  const history = useHistory();

  const [nombre] = usuario;

  const [proyectos, setProyectos] = useState([]);

  const [datosCarga, setdatosCarga] = useState(null);
  ///estados
  const [modalSucess, setModalSucess] = useState(false);
  const [cargaButonForm, setcargaButonForm] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  ////Form
  const [form] = Form.useForm();

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");
  ///fin carga de ID recibido
  //console.log(">>> Id para cargado de entrevista",idRequerimiento);

  ////carga Datos asignados para entrevista
  const cargaDatosAsignados = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${id}`,
      { headers }
    );

    return data.data;
  };
  ////carga Datos asignados para entrevista

  ////carga Datos
  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );
    setdatosCarga(data.data);
    return data;
  };

  ////carga Datos
  const CargaProyectoPuesto = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const queryGraphQlPuestos = JSON.stringify({
      query: `query{
        requerimientos(filters:{estado: {eq: "En Proceso" }}){
         data{
           id
           attributes{
             proyecto
             descripcionTrabajo
             numPersonal
             estado
             perfiles{
               data{
                 id
               }
             }
           }
         }
       }
       }`,
      variables: {},
    });

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/graphql",
      queryGraphQlPuestos,
      { headers }
    );

    console.log(">>>! return Data Requerimientos", data);
    return data.data.requerimientos;
  };

  /////Carga de los Datos en el muro
  const onFill = (data, dataAsignados) => {
    console.log(">>> Get data muro Ofertar, Onfill", data);
    console.log(">>> Get data muro Asignados", dataAsignados);

    form.setFieldsValue({
      nombre: data.data.attributes.nombre,
      correo: data.data.attributes.mother.data.attributes.correo,
      numero: data.data.attributes.mother.data.attributes.celular,
      dpi: data.data.attributes.mother.data.attributes.dpi,
      departamento: JSON.parse(dataAsignados[0].attributes.Proyecto),
      puesto: dataAsignados[0].attributes.Puesto,
    });
  };
  /////Fin cara de Datos en el muro

  console.log("Datos Carga", datosCarga);

  const enviarCorreoOferta = async () => {
    console.log("Datos del adjunto a enviar en la oferta", defaultFileList);

    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.attributes.nombre,
      asunto: "Confirmacion Oferta",
      mensaje:
        "Se ha completado tu proceso de reclutamiento, bienvenido a PRONTO BPO",
      adjunto: `https://rh.api.prontobpo.cloud${defaultFileList[0].url}`, // URL completa del archivo adjunto
    };

    console.log("Datos a enviar al endpoint", datosEnviar); // Verificar los datos enviados

    const datos = JSON.stringify(datosEnviar);

    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/ofertar`,
      datos,
      { headers }
    );
    return data;
  };

  const agregadoLog = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Confirmacion Oferta",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga?.attributes.mother.data.id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };

  //////Formularios
  const envioSolicitudEmpleo = async () => {
    console.log(">>> Numero de Id para el Update", idRequerimiento);

    const datosActualizar = JSON.stringify({
      data: {
        estado: "Contratado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    enviarCorreoOferta();
    agregadoLog();
    return dataStatus;
  };

  const actNumRequerimientos = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    await Axios.get(
      `https://rh.api.prontobpo.cloud/api/requerimientos/${id}?populate[perfiles]=*`,
      { headers }
    ).then((res) => {
      const response = res.data;

      console.log(">>>! data de NumReq", response);

      const NumPersonal = response.data.attributes.numPersonal;
      const NumContratado = response.data.attributes.perfiles.data.length;
      const verNumPersonal = NumPersonal - NumContratado;

      let estado;

      if (verNumPersonal === 1) {
        estado = "Completo";
      }

      const perfiles = [
        ...response.data.attributes.perfiles.data,
        idRequerimiento,
      ];

      console.log(
        ">>>! Response ActReq: ",
        idRequerimiento,
        NumPersonal,
        NumContratado,
        estado,
        perfiles
      );

      const datosActualizar = JSON.stringify({
        data: {
          estado: estado,
          perfiles: perfiles,
        },
      });

      //console.log(datos, headers);
      Axios.put(
        `https://rh.api.prontobpo.cloud/api/requerimientos/${id}`,
        datosActualizar,
        { headers }
      ).then((data) => {
        console.log("Update de requerimiento", data);
        return data;
      });
    });
  };

  const onFinish = (values) => {
    setcargaButonForm(true);
    console.log(">>>! valores Form", values);
    envioSolicitudEmpleo(values)
      .then(() => {
        setcargaButonForm(true);
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setcargaButonForm(false);
      });

    actNumRequerimientos(values.proyectoRequerimiento).then(() => {
      setModalSucess(true);
      form.resetFields();
    });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Ofertar",
      description: "No se pudo completar el envio de los datos.",
    });
    console.log(
      "Fecha:",
      errorInfo?.values["nacimiento"]?.format("YYYY/MM/DD")
    );
    console.log("Failed:", errorInfo);
  };

  ////Upload FileReader
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    console.log("Datos de upload", onSuccess, onError, file, onProgress);

    var formData = new FormData();
    formData.append("files", file);
    await Axios.post("https://rh.api.prontobpo.cloud/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        //console.log("return upload images", data.data[0]);
        updateFilesListHook(data.data[0]);
        onSuccess("Ok");
      })
      .catch((error) => {
        onError({ event: error });
      });
  };

  //onRemove
  const onRemoveUpoload = (files) => {
    //console.log(">>> On Remove", files, defaultFileList);

    const nameFile = files.name;

    const deleteFileStrapi = defaultFileList.filter(function (i, n) {
      console.log("datos del archivo a remover", n, i.name, nameFile);
      return i.name === nameFile;
    });

    const removeID = deleteFileStrapi[0].id;

    //console.log("delete strapi", removeID);
    deleteStrapiFile(removeID);

    const valor = defaultFileList.filter(function (i, n) {
      console.log(n, i.name, nameFile);
      return i.name !== nameFile;
    });

    //console.log("Valor Encontrado", valor);
    setDefaultFileList([...valor]);
  };

  const updateFilesListHook = async (file) => {
    const files = defaultFileList.push(file);
    console.log("Files", files);
    return setDefaultFileList([...defaultFileList]);
  };

  ///  Delete File Strapi
  const deleteStrapiFile = async (fileId) => {
    await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/upload/files/${fileId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((data) => {
        //console.log("return upload images", data.data);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  ////Efectos

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatos().then((data) => {
      console.log("Datos de cargaDatos", data);
      cargaDatosAsignados(data.data.attributes.mother.data.id).then(
        (dataAsignados) => {
          console.log("Datos de carga asignados", dataAsignados);
          onFill(data, dataAsignados);
        }
      );
    });

    CargaProyectoPuesto().then((proyecto) => {
      console.log(">>>! Proyectos", proyecto);
      setProyectos(proyecto.data);
    });
  }, []);
  ////Fin Efectos CargaDatos

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={true}
        initialValues={
          {
            //nombre: "Noe Peña",
          }
        }
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }

          <Col span={23}>
            <Card bordered={false}>
              <Meta
                title="Realizar oferta / Confirmacion de Aplicante"
                description="Se asigna oferta de propuesta salarial, pasa a ser contratado."
              />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal del Aplicante
                </Divider>

                <Col span={24}>
                  <Row gutter={[18, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="DPI"
                            name="dpi"
                            rules={[
                              {
                                //type: "number",
                                //required: false,
                                message:
                                  "Por favor ingresa tu DPI sin espacios o guiones ( - ) solo numeros. ",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Numero DPI" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Correo Electronico"
                            name="correo"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  "Por favor ingresa correo del aplicante.",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Correo Electronico"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Numero Telefono"
                            name="numero"
                            rules={[
                              {
                                required: true,
                                message: "Ingresa telefono del aplicante.",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Numero de Telefono"
                            />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                      </Row>
                    </Col>

                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>

                {
                  /// Tercera Seccion
                }

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Proyecto"
                    name="departamento"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa proyecto.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Proyecto" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Posicion laboral"
                    name="puesto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa posicion laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Posicion laboral" />
                  </Form.Item>
                </Col>
              </Row>

              <br />

              <Divider
                style={{ fontSize: 17, fontWeight: "bold" }}
                orientation="left"
              >
                Confirmacion de Proyecto y Puesto
              </Divider>

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Proyecto (Selecciona el proyecto)"
                    name="proyectoRequerimiento"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecciona el proyecto.",
                      },
                    ]}
                  >
                    <Collapse accordion ghost>
                      {proyectos ? (
                        proyectos.map((proyecto, index) => {
                          console.log(">>>! MAP", index, proyecto);
                          const posicionesFalt =
                            proyecto.attributes.numPersonal -
                            proyecto.attributes.perfiles.data.length;

                          return (
                            <Panel
                              header={
                                <Badge count={posicionesFalt} offset={[20, 0]}>
                                  {proyecto.attributes.proyecto} |{" "}
                                  {proyecto.attributes.descripcionTrabajo}
                                </Badge>
                              }
                              key={proyecto.id}
                            >
                              <p>{proyecto.attributes.descripcionTrabajo}</p>
                              <p>
                                Posiciones faltantes:{" "}
                                <strong style={{ color: "green" }}>
                                  {posicionesFalt}
                                </strong>
                              </p>
                              <p>
                                <Tag color="green">Proyecto Seleccionado</Tag>
                              </p>
                            </Panel>
                          );
                        })
                      ) : (
                        <>No hay proyectos</>
                      )}
                    </Collapse>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                  <Form.Item
                    name="adjuntos"
                    id="adjuntos"
                    rules={[
                      {
                        required: true,
                        message: "Por favor adjunta la Carta Oferta.",
                      },
                      {
                        validator: (_, value) =>
                          value && value.fileList.length > 0
                            ? Promise.resolve()
                            : Promise.reject(
                                "!"
                              ),
                      },
                    ]}
                  >
                    <Upload
                      listType="picture"
                      customRequest={uploadFile}
                      onRemove={onRemoveUpoload}
                      multiple
                    >
                      <Button icon={<UploadOutlined />}>
                        Adjuntar Carta Oferta
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <br />

              <Col span={3} offset={10}>
                <Form.Item>
                  <Affix offsetBottom={25}>
                    <Button
                      loading={cargaButonForm}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                    >
                      Asignar Oferta
                    </Button>
                  </Affix>
                </Form.Item>
              </Col>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => history.push({ pathname: "./solicitudes/perfiles" })}
          onCancel={() => history.push({ pathname: "./solicitudes/perfiles" })}
          footer={
            <Button
              type="primary"
              onClick={() =>
                history.push({ pathname: "./solicitudes/perfiles" })
              }
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Oferta y confirmacion realizada!" />
        </Modal>
      }
    </>
  );
};

export default FormularioOfertar;
