import React from 'react'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";


const DocumentoVista = () => {
  const docs = [
    {
      uri: "https://rh.api.prontobpo.cloud/api/uploads/M0368_FGV_Calcomania_bec63d1d34.pdf",
    }
  ];
  return (
    <div>
      <h1>DocumentoVista</h1>

      {<DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />
      }
    </div>
  )
}

export default DocumentoVista