import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children, usuarioMail, usuarioUser, usuarioTipo }) {
  //console.log(children, usuarioMail, usuarioUser);

  const [visible, setVisible] = useState(false);
  const [ocultarNav, setOcultarNav] = useState(false);
  const [, setPlacement] = useState("left");
  const [sidenavColor, setSidenavColor] = useState("#FE8710");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  console.log(">>>>Path ", pathname);

  useEffect(() => {
    setPlacement("left");
    pathname === "FormularioPerfil"
      ? setOcultarNav(true)
      : setOcultarNav(false);
  }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      }
        `}
    >
      {
        // TODO: Arreglar el Drawer
        /*
        <Drawer
          title={false}
          placement={placement}
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
          key={placement}
          width={250}
          className={`drawer-sidebar`}
        >
          <Layout className={`layout-dashboard`}>
            <Sider
              trigger={null}
              width={250}
              theme="light"
              className={`sider-primary ant-layout-sider-primary ${
                sidenavType === "#fff" ? "active-route" : ""
              }`}
              style={{ background: sidenavType }}
            >
              <Sidenav color={sidenavColor} username={usuarioUser} />
            </Sider>
          </Layout>
        </Drawer> */
      }

      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        collapsed={ocultarNav}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} username={usuarioUser} usuariotipo={usuarioTipo}/>
      </Sider>
      <Layout className={`${ocultarNav ? "ocultarSidenav" : ""}`}>
        <>
          {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                  usuarioInfo={usuarioMail}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                onPress={openDrawer}
                name={pathname}
                subName={pathname}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
                usuarioInfo={usuarioMail}
              />
            </AntHeader>
          )}
        </>
        <Content className={`content-ant`}>
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
