/////React
import React, { useState, useEffect } from "react";

//Reac Dom
import { useLocation, useHistory } from "react-router-dom";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Image,
  Button,
  notification,
  Modal,
  Select
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";
import moment from "moment";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;

///Componente Form
const FormularioManejaPersonas = ({ usuario, correoLogeado }) => {
  ////History react Dom
  const history = useHistory();

  const [nombre] = usuario;

  ////Estados de Modals
  const [modalSucessDenegado, setModalSucessDenegado] = useState(false);
  const [modalSucess, setModalSucess] = useState(false);
  ////Fin Estados de Modals

  ////Estados para cargas de datos
  const [datosCarga, setdatosCarga] = useState(null);
  const [datosCargaAsignados, setdatosCargaAsignados] = useState(null);
  const [cargaButonForm, setcargaButonForm] = useState(false);
  const [value, setValue] = useState(1);
  ////Fin Estados para cargas de datos

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  ////Formularios
  const [form] = Form.useForm();
  ////Fin Formularios

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");
  ///fin carga de ID recibido
  //console.log(">>> Id para cargado de entrevista Maneja Solo",idRequerimiento);

  ////carga Datos entrevista
  const cargaDatosPerfil = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );

    setdatosCarga(data);
    return data;
  };
  ////carga Datos entrevista

  ////carga Datos asignados para entrevista
  const cargaDatosAsignados = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${id}`,
      { headers }
    );

    setdatosCargaAsignados(data.data[0].id);

    return data.data;
  };
  ////carga Datos asignados para entrevista

  const actualizarEstadoEntrevista = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datosActualizarAsignado = JSON.stringify({
      data: {
        estado: "Completada",
      },
    });

    //console.log(datos, headers);
    const { dataAsignados } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/asignados/${datosCargaAsignados}`,
      datosActualizarAsignado,
      { headers }
    );

    console.log(">>> Envio de actualizacion Estado Asignados", dataAsignados);
  };

  const enviarCorreoManejaPersonas = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.data.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.data.attributes.nombre,
      asunto: "Entrevista Exitosa Pronto BPO",
      mensaje:
        "Se ah realizado la entrevista de forma exitosa, nos estaremos comunicando con tigo para seguir con el proceso, PRONTO BPO",
    };

    const datos = JSON.stringify(datosEnviar);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/entrevistaExitosa`,
      datos,
      { headers }
    );
    return data;
  };

  const agregadoLog = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Realizacion entrevista - Maneja Personas",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: datosCarga?.data.attributes.mother.data.id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };

  const envioEntrevista = async (valores) => {
    console.log("Datos a guardar de la entrevista", valores);

    console.log("Datos carga", datosCarga);

    const datos = JSON.stringify({
      data: {
        idMother: datosCarga.data.attributes.mother.data.id,
        nombre: valores.nombre,
        departamento: JSON.stringify(valores.departamento),
        puesto: valores.puesto,
        entrevistador: valores.entrevistador,
        escucha1: valores.escucha1,
        escucha2: valores.escucha2,
        desarrollopersonas1: valores.desarrollopersonas1,
        desarrollopersonas2: valores.desarrollopersonas2,
        orientacionresultado1: valores.orientacionresultado1,
        orientacionresultado2: valores.orientacionresultado2,
        decision1: valores.decision1,
        decision2: valores.decision2,
        manejodiversidad1: valores.manejodiversidad1,
        manejodiversidad2: valores.manejodiversidad2,
        manejoconflictos1: valores.manejoconflictos1,
        manejoconflictos2: valores.manejoconflictos2,
        serviciocliente1: valores.serviciocliente1,
        serviciocliente2: valores.serviciocliente2,
        planificacionestrategica1: valores.planificacionestrategica1,
        planificacionestrategica2: valores.planificacionestrategica2,
        actitudfrentetrabajo1: valores.actitudfrentetrabajo1,
        actitudfrentetrabajo2: valores.actitudfrentetrabajo2,
        conocimientobasededatos1: valores.conocimientobasededatos1,
        conocimientobasededatos2: valores.conocimientobasededatos2,
        habilidadesydestrezas: valores.habilidadesydestrezas,
        debilidades: valores.debilidades,
        comentarios: valores.comentarios,

        tipoCuenta: valores.tipoCuenta,
        familiaresPronto: valores.familiaresPronto,
        fuenteCV: valores.fuenteCV,
        edad: valores.edad,
        direccionRecide: valores.direccionRecide,
        salarioActual: valores.salarioActual,
        disponibilidadInmediata: valores.disponibilidadInmediata,
        tipoTransporte: valores.tipoTransporte,
        principalMotivacion: valores.principalMotivacion,
        queEnojar: valores.queEnojar,
        padeceEnfermedad: valores.padeceEnfermedad,
        descripcionMetasValores: valores.descripcionMetasValores,
        nucleoFamiliar: valores.nucleoFamiliar,
        estudiaActualmente: valores.estudiaActualmente,
        nivelAcademico: valores.nivelAcademico,
        laboradoPronto: valores.laboradoPronto,
        experienciaLaboralDetallada: valores.experienciaLaboralDetallada,
        mayorLogroLaboral: valores.mayorLogroLaboral,
        comprendePuesto: valores.comprendePuesto,
        problemaBanco: valores.problemaBanco,
        tituloFirmado: valores.tituloFirmado,
        antecedentes: valores.antecedentes,
        fechasDePago: valores.fechasDePago,
        verificacionPapeleria: valores.verificacionPapeleria,
        headsets: valores.headsets,
        pruebasPsicojob: valores.pruebasPsicojob,
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/manejapersonas",
      datos,
      { headers }
    );

    console.log(">>> Datos enviados a la base Maneja Solo", data);

    actualizarEstadoEntrevista();
    enviarCorreoManejaPersonas();
    agregadoLog();

    return data;
  };

  const envioFormulario = (values) => {
    setcargaButonForm(true);

    envioEntrevista(values)
      .then((info) => {
        setcargaButonForm(false);
        setModalSucess(true);
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setcargaButonForm(false);
      });
  };

  const fallaEnvioFormulario = (errorInfo) => {
    notification["warning"]({
      message: "Guardado Entrevista",
      description:
        "Hay campos que no se han completado, por favor revisa todos los campos.",
    });
    console.log(
      "Fecha:",
      errorInfo?.values["nacimiento"]?.format("YYYY/MM/DD")
    );
    console.log("Failed:", errorInfo);
  };

  const correoPerfilDeclinado = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga?.data.attributes.mother.data.attributes.correo,
      nombre: datosCarga?.data.attributes.nombre,
      asunto: "Terminacion proceso aplicacion plaza",
      mensaje:
        "Gracias por tu interés en aplicar con nosotros. Por el momento hemos decidido no continuar con tu proceso de selección, sin embargo agradecemos enormemente tu tiempo e interés en Pronto BPO, Te invitamos a que continúes pendiente de nuestras plazas y no dudes en aplicar si consideras llenas los requisitos",
    };

    const datos = JSON.stringify(datosEnviar);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/denegar`,
      datos,
      { headers }
    );
    return data;
  };

  const denegarSolicitudEmpleo = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Declinado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    setModalSucessDenegado(true);

    console.log("Envio de actualizacion Estado", dataStatus);

    correoPerfilDeclinado();
  };

  /////Carga de los Datos en el muro
  const onFill = (nombre, Proyecto, Puesto) => {
    form.setFieldsValue({
      nombre: nombre,
      departamento: JSON.parse(Proyecto),
      puesto: Puesto,
      entrevistador: correoLogeado,
    });
  };
  /////Fin cara de Datos en el muro

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatosPerfil().then((data) => {
      console.log(">>> returncargaPerfil", data);
      cargaDatosAsignados(data.data.attributes.mother.data.id).then(
        (dataRCA) => {
          console.log(">>> return Datos Asigandos", dataRCA);
          onFill(
            data.data.attributes.nombre,
            dataRCA[0].attributes.Proyecto,
            dataRCA[0].attributes.Puesto
          );
        }
      );
    });
  }, []);
  ////Fin Efectos CargaDatos

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        onFinish={envioFormulario}
        onFinishFailed={fallaEnvioFormulario}
        autoComplete="off"
        requiredMark={true}
        initialValues={{}}
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }
          <Col span={23}>
            <Card bordered={false}>
              <Meta title="Formulario Maneja Personas" />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal
                </Divider>

                <Col span={24}>
                  <Row gutter={[16, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Departamento"
                            name="departamento"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa departamento.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Departamento" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Puesto"
                            name="puesto"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa puesto.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Puesto" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Entrevistador"
                            name="entrevistador"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa entrevuistador.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Entrevistador" />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                      </Row>
                    </Col>

                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Entrevista General
                </Divider>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Tipo de cuenta"
                    name="tipoCuenta"
                    rules={[
                      {
                        required: true,
                        message: "Tipo Cuenta.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Seleccione el tipo de cuenta"
                    >
                      <Option value="Español">Español</Option>
                      <Option value="Inglés">Inglés</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Form.Item
                    className="username"
                    label="Famiiares en ProntoBPO, Proyecto y relación"
                    name="familiaresPronto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa si tienes familiares.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Familiares en pronto" />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Form.Item
                    className="username"
                    label="Fuente del CV"
                    name="fuenteCV"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese fuente de CV.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona fuente"
                    >
                      <Option value="Correo Recruitment">
                        Correo Recruitment
                      </Option>
                      <Option value="Referido">Referido</Option>
                      <Option value="Correo Reclutamiento">
                        Correo Reclutamiento
                      </Option>
                      <Option value="LinkedIn">LinkedIn</Option>
                      <Option value="Facebook">Facebook</Option>
                      <Option value="Presencial">Presencial</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Edad"
                    name="edad"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa edad.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Edad" />
                  </Form.Item>
                </Col>

                <Col span={20} offset={2}>
                  <Form.Item
                    className="username"
                    label="Dirección o zona en la que Reside"
                    name="direccionRecide"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa direccion.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Direccion" />
                  </Form.Item>
                </Col>

                <Col span={8} offset={2}>
                  <Form.Item
                    className="username"
                    label="Salario Actual"
                    name="salarioActual"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa salario actual.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Salario" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Disponibilidad Inmediata"
                    name="disponibilidadInmediata"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese disponibilidad.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="Tiene que dar tiempo">
                        Tiene que dar tiempo
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={16} offset={4}>
                  <Form.Item
                    className="username"
                    label="Tipo de Transporte"
                    name="tipoTransporte"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese tipo transporte.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona transporte"
                    >
                      <Option value="Moto (verificar si hay parqueo antes de ofrecerlo)">
                        Moto (verificar si hay parqueo antes de ofrecerlo)
                      </Option>
                      <Option value="Carro (Mencionar de los parqueos aledaños)">
                        Carro (Mencionar de los parqueos aledaños)
                      </Option>
                      <Option value="Transporte público ( Considerar Horarios )">
                        Transporte público ( Considerar Horarios )
                      </Option>
                      <Option value="Vienen a dejar y a traer ( Considerar Horarios )">
                        Vienen a dejar y a traer ( Considerar Horarios )
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Cuál es tu principal fuente de motivación / Qué disfrutas"
                    name="principalMotivacion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Qué es lo que más te hace enojar (profundizar)"
                    name="queEnojar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Padece de alguna Enfermedad, Alergia o Similar que requiera seguimiento"
                    name="padeceEnfermedad"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Descripción del candidato en Metas, valores y/o fortalezas."
                    name="descripcionMetasValores"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Quiénes conforman tu núcleo familiar (profundizar)"
                    name="nucleoFamiliar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Estudia actualmente o tiene planes de estudiar (especificar horario)"
                    name="estudiaActualmente"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Nivel Academico"
                    name="nivelAcademico"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese nivel Academico",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Diversificado Completo">
                        Diversificado Completo
                      </Option>
                      <Option value="Licenciatura">Licenciatura</Option>
                      <Option value="Posgrado">Posgrado</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Ha Laborado En Pronto"
                    name="laboradoPronto"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese nivel Academico",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si ( Preguntar por recontratación y por qué se fue de la empresa)">
                        Si ( Preguntar por recontratación y por qué se fue de la
                        empresa)
                      </Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Experiencia Laboral Detallada
                            Últimos 3 empleos, funciones y motivo de salida, ultimo salario"
                    name="experienciaLaboralDetallada"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Indica tu mayor logro en tu record laboral y cómo este aporto a la organización"
                    name="mayorLogroLaboral"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Qué comprendes de la plaza a la que estás aplicando"
                    name="comprendePuesto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Tiene algún problema para aperturar cuenta en Banco Industrial"
                    name="problemaBanco"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="No">No</Option>
                      <Option value="Si (Preguntar por disponibilidad de cheque a encargado del área)">
                        Si (Preguntar por disponibilidad de cheque a encargado
                        del área)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Título Firmado y Sellado por Contraloría"
                    name="tituloFirmado"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Antecedentes Tramitados (Agregar a Notas Adicionales en caso tenga problemas para Tramitarlos)"
                    name="antecedentes"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Informar de las fechas de Pago (Salario Base el 30 y 15 solo bonos) "
                    name="fechasDePago"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Verificación de Papelería, según Checklist"
                    name="verificacionPapeleria"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Headset ( En caso no tenga, explicar pagos hasta 4 cuotas Q.400.00 -Q.450.00)"
                    name="headsets"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Pruebas Psicojob enviadas"
                    name="pruebasPsicojob"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ESCUCHA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame de una ocasión sobre una materia que le gustó y qué fue lo que más aprendió de su catedrático?"
                    name="escucha1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo reacciona usted ante un tema que no es de su interés, pero sumamente necesario?"
                    name="escucha2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  DESARROLLO DE PERSONAS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué factores consideras importantes para desarrollar las habilidades dentro del equipo de trabajo?"
                    name="desarrollopersonas1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna ocasión en la que hayas trabajado para el desarrollo de personal a tu cargo.?"
                    name="desarrollopersonas2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ORIENTACIÓN AL RESULTADO
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué factores consideras fundamentales para alcanzar un objetivo propuesto?"
                    name="orientacionresultado1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre algún objetivo que hayas alcanzado y cómo lo alcanzaste. ?"
                    name="orientacionresultado2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  DECISIÓN
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo te organizas al momento de tomar una decisión importante? "
                    name="decision1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna decisión importante que hayas tomado y cómo lo manejaste.?"
                    name="decision2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  MANEJO DE LA DIVERSIDAD
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué factores consideras importantes para manejar de buena manera los diferentes comportamientos dentro del equipo de trabajo?"
                    name="manejodiversidad1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna experiencia en la que hayas compartido con una persona con una actitud complicada y cómo lo manejaste.?"
                    name="manejodiversidad2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  MANEJO DE CONFLICTOS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame de algún conflicto en el que te hayas visto involucrado y cómo lo resolviste?"
                    name="manejoconflictos1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame sobre alguna vez hayas ayudado a algún compañero en un problema?"
                    name="manejoconflictos2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  SERVICIO AL CLIENTE
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué habilidades consideras importantes para contar con buen servicio al cliente?"
                    name="serviciocliente1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna ocasión en la que hayas tratado con una persona complicada y cómo lo solucionaste.?"
                    name="serviciocliente2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  PLANIFICACIÓN ESTRATÉGICA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo te organizas cuando tienes un proyecto u objetivo?"
                    name="planificacionestrategica1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre algún proyecto importante que hayas desarrollado y cómo lo planificaste?"
                    name="planificacionestrategica2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ACTITUD FRENTE AL TRABAJO
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Considera que mantener una actitud positiva influye para el buen desarrollo de las tareas?"
                    name="actitudfrentetrabajo1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna experiencia en la que no te encontrabas motivado, pero cambiar de actitud fue positivo para la realización de una actividad.?"
                    name="actitudfrentetrabajo2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  APRENDIZAJE AUTÓNOMO
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Cuando algún proceso es modificado y aún no te encuentras familiarizado con este, ¿Qué herramientas utilizas para actualizarte?"
                    name="aprendizajeautonomo1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación en la que de manera independiente aprendiste a desarrollar una actividad.?"
                    name="aprendizajeautonomo2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  CONOCIMIENTO DIGITAL EN BASE DE DATOS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo consideras que influye el uso de herramientas digitales para el desarrollo de las actividades?"
                    name="conocimientobasededatos1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna herramienta digital que has utilizado y cómo te funcionó.?"
                    name="conocimientobasededatos2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  PUNTOS FUERTES Y DÉBILES
                </Divider>

                <Col span={10} offset={1}>
                  <Form.Item
                    className="username"
                    label="HABILIDADES Y DESTREZAS "
                    name="habilidadesydestrezas"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa tu HABILIDADES Y DESTREZAS .",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="HABILIDADES Y DESTREZAS" />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    className="username"
                    label="DEBILIDADES"
                    name="debilidades"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa DEBILIDADES.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="DEBILIDADES" />
                  </Form.Item>
                </Col>

                <Col span={12} offset={6}>
                  <Form.Item
                    className="username"
                    label="Comentarios"
                    name="comentarios"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>

                  <Col span={12} offset={5}>
                    <Row>
                      <Col span={6}>
                        <Form.Item>
                          <Button
                            loading={cargaButonForm}
                            size="Large"
                            type="primary"
                            htmlType="submit"
                          >
                            Guardar Entrevista
                          </Button>
                        </Form.Item>
                      </Col>

                      <Col span={6} offset={10}>
                        <Button
                          loading={false}
                          size="Large"
                          type="primary"
                          danger
                          onClick={denegarSolicitudEmpleo}
                        >
                          Declinar Perfil
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        <Modal
          centered
          visible={modalSucess}
          onOk={() => history.push({ pathname: "./solicitudes/perfiles" })}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button
              type="primary"
              onClick={() =>
                history.push({ pathname: "./solicitudes/perfiles" })
              }
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Confirmacion de guardado de entrevista!" />
        </Modal>
      }

      {
        <Modal
          centered
          visible={modalSucessDenegado}
          onOk={() => history.push({ pathname: "./solicitudes/perfiles" })}
          onCancel={() => setModalSucessDenegado(false)}
          footer={
            <Button
              type="primary"
              onClick={() =>
                history.push({ pathname: "./solicitudes/perfiles" })
              }
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Perfil denegado con exito!" />
        </Modal>
      }
    </>
  );
};

export default FormularioManejaPersonas;
