/////React
import React, { useState, useEffect } from "react";

//Reac Dom
import { useLocation, useHistory } from "react-router-dom";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

////Layout Sucess
import Sucess from "../components/responses/Sucess";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Radio,
  Image,
  Button,
  Modal,
  Select,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;

///Componente Form
const ConfirmarFormularioManejaSolo = ({ usuario }) => {
  const [datosCarga, setdatosCarga] = useState(null);
  const [modalSucessDenegado, setModalSucessDenegado] = useState(false);

  ////History react Dom
  const history = useHistory();

  ////Estados para cargas de datos
  const [value, setValue] = useState(1);
  ////Fin Estados para cargas de datos

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  ////Formularios
  const [form] = Form.useForm();
  ////Fin Formularios

  ///carga de ID recibido
  const idRequerimiento = new URLSearchParams(useLocation().search).get("id");
  ///fin carga de ID recibido
  //console.log(">>> Id para cargado de entrevista Maneja Solo",idRequerimiento);

  ////carga Datos entrevista
  const cargaDatosPerfil = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}?populate[mother]=*`,
      { headers }
    );

    setdatosCarga(data);
    return data;
  };
  ////carga Datos entrevista

  ////carga Datos asignados para entrevista
  const consultaManejaSolo = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/manejasolos?filters[IdMother][$eq]=${id}`,
      { headers }
    );

    return data.data[0];
  };
  ////carga Datos asignados para entrevista

  ////carga Datos asignados para entrevista
  const cargaDatosAsignados = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?idMother=${id}`,
      { headers }
    );

    return data.data[0];
  };
  ////carga Datos asignados para entrevista

  const correoPerfilDeclinado = async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: datosCarga.data.attributes.mother.data.attributes.correo,
      nombre: datosCarga.data.attributes.nombre,
      asunto: "Terminación proceso aplicación plaza",
      mensaje:
        "Gracias por tu interés en aplicar con nosotros. Por el momento hemos decidido no continuar con tu proceso de selección, sin embargo agradecemos enormemente tu tiempo e interés en Pronto BPO, Te invitamos a que continúes pendiente de nuestras plazas y no dudes en aplicar si consideras llenas los requisitos",
    };

    const datos = JSON.stringify(datosEnviar);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/denegar`,
      datos,
      { headers }
    );
    return data;
  };

  const denegarSolicitudEmpleo = async () => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Declinado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    setModalSucessDenegado(true);

    console.log("Envio de actualizacion Estado", dataStatus);

    correoPerfilDeclinado();
  };

  /////Carga de los Datos en el muro
const onFill = (
  nombre,
  Proyecto,
  Puesto,
  asignadoEntrevista,
  aenvuelo1,
  aenvuelo2,
  aprendizajeautonomo1,
  aprendizajeautonomo2,
  comentarios,
  cverbalescrita1,
  cverbalescrita2,
  debilidades,
  empatia1,
  empatia2,
  escucha1,
  escucha2,
  flexibilidad1,
  flexibilidad2,
  habilidadesydestrezas,
  negociacion1,
  negociacion2,
  oalacion1,
  oalacion2,
  rproblemas1,
  rproblemas2,
  tolerancia1,
  tolerancia2,


  tipoCuenta,
  familiaresPronto,
  fuenteCV,
  edad,
  direccionRecide,
  salarioActual,
  disponibilidadInmediata,
  tipoTransporte,
  principalMotivacion,
  queEnojar,
  padeceEnfermedad,
  descripcionMetasValores,
  nucleoFamiliar,
  estudiaActualmente,
  nivelAcademico,
  laboradoPronto,
  experienciaLaboralDetallada,
  mayorLogroLaboral,
  comprendePuesto,
  problemaBanco,
  tituloFirmado,
  antecedentes,
  fechasDePago,
  verificacionPapeleria,
  headsets,
  pruebasPsicojob

  
) => {
  form.setFieldsValue({
    nombre: nombre,
    departamento: JSON.parse(Proyecto),
    puesto: Puesto,
    entrevistador: asignadoEntrevista,
    aenvuelo1: aenvuelo1,
    aenvuelo2: aenvuelo2,
    aprendizajeautonomo1: aprendizajeautonomo1,
    aprendizajeautonomo2: aprendizajeautonomo2,
    comentarios: comentarios,
    cverbalescrita1: cverbalescrita1,
    cverbalescrita2: cverbalescrita2,
    debilidades: debilidades,
    empatia1: empatia1,
    empatia2: empatia2,
    escucha1: escucha1,
    escucha2: escucha2,
    flexibilidad1: flexibilidad1,
    flexibilidad2: flexibilidad2,
    habilidadesydestrezas: habilidadesydestrezas,
    negociacion1: negociacion1,
    negociacion2: negociacion2,
    oalacion1: oalacion1,
    oalacion2: oalacion2,
    rproblemas1: rproblemas1,
    rproblemas2: rproblemas2,
    tolerancia1: tolerancia1,
    tolerancia2: tolerancia2,


    tipoCuenta: tipoCuenta,
    familiaresPronto: familiaresPronto,
    fuenteCV: fuenteCV,
    edad: edad,
    direccionRecide: direccionRecide,
    salarioActual: salarioActual,
    disponibilidadInmediata: disponibilidadInmediata,
    tipoTransporte: tipoTransporte,
    principalMotivacion: principalMotivacion,
    queEnojar: queEnojar,
    padeceEnfermedad: padeceEnfermedad,
    descripcionMetasValores: descripcionMetasValores,
    nucleoFamiliar: nucleoFamiliar,
    estudiaActualmente: estudiaActualmente,
    nivelAcademico: nivelAcademico,
    laboradoPronto: laboradoPronto,
    experienciaLaboralDetallada: experienciaLaboralDetallada,
    mayorLogroLaboral: mayorLogroLaboral,
    comprendePuesto: comprendePuesto,
    problemaBanco: problemaBanco,
    tituloFirmado: tituloFirmado,
    antecedentes: antecedentes,
    fechasDePago: fechasDePago,
    verificacionPapeleria: verificacionPapeleria,
    headsets: headsets,
    pruebasPsicojob: pruebasPsicojob

  });
};

  /////Fin cara de Datos en el muro

  /////funcion enviar a Formulario
  const EnviarOfertar = (id) => {
    console.log("**************** Enviar pagina", id);
    history.push({
      pathname: "../FormularioOfertar", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });
  };

  ////use Effect de CargaDatos
  useEffect(() => {
    cargaDatosPerfil().then((data) => {
      console.log(">>> returncargaPerfil", data);
      cargaDatosAsignados(data.data.attributes.mother.data.id).then(
        (dataRCA) => {
          console.log(">>> return Datos Asigandos", dataRCA);
          consultaManejaSolo(data.data.attributes.mother.data.id).then(
            (dataEntre) => {
              console.log(">>> return Datos Entrevista", dataEntre);
              onFill(
                data.data.attributes.nombre,
                dataRCA.attributes.Proyecto,
                dataRCA.attributes.Puesto,
                dataRCA.attributes.asignadoEntrevista,
                dataEntre.attributes.aenvuelo1,
                dataEntre.attributes.aenvuelo2,
                dataEntre.attributes.aprendizajeautonomo1,
                dataEntre.attributes.aprendizajeautonomo2,
                dataEntre.attributes.comentarios,
                dataEntre.attributes.cverbalescrita1,
                dataEntre.attributes.cverbalescrita2,
                dataEntre.attributes.debilidades,
                dataEntre.attributes.empatia1,
                dataEntre.attributes.empatia2,
                dataEntre.attributes.escucha1,
                dataEntre.attributes.escucha2,
                dataEntre.attributes.flexibilidad1,
                dataEntre.attributes.flexibilidad2,
                dataEntre.attributes.habilidadesydestrezas,
                dataEntre.attributes.negociacion1,
                dataEntre.attributes.negociacion2,
                dataEntre.attributes.oalacion1,
                dataEntre.attributes.oalacion2,
                dataEntre.attributes.rproblemas1,
                dataEntre.attributes.rproblemas2,
                dataEntre.attributes.tolerancia1,
                dataEntre.attributes.tolerancia2,

                dataEntre.attributes.tipoCuenta,
                dataEntre.attributes.familiaresPronto,
                dataEntre.attributes.fuenteCV,
                dataEntre.attributes.edad,
                dataEntre.attributes.direccionRecide,
                dataEntre.attributes.salarioActual,
                dataEntre.attributes.disponibilidadInmediata,
                dataEntre.attributes.tipoTransporte,
                dataEntre.attributes.principalMotivacion,
                dataEntre.attributes.queEnojar,
                dataEntre.attributes.padeceEnfermedad,
                dataEntre.attributes.descripcionMetasValores,
                dataEntre.attributes.nucleoFamiliar,
                dataEntre.attributes.estudiaActualmente,
                dataEntre.attributes.nivelAcademico,
                dataEntre.attributes.laboradoPronto,
                dataEntre.attributes.experienciaLaboralDetallada,
                dataEntre.attributes.mayorLogroLaboral,
                dataEntre.attributes.comprendePuesto,
                dataEntre.attributes.problemaBanco,
                dataEntre.attributes.tituloFirmado,
                dataEntre.attributes.antecedentes,
                dataEntre.attributes.fechasDePago,
                dataEntre.attributes.verificacionPapeleria,
                dataEntre.attributes.headsets,
                dataEntre.attributes.pruebasPsicojob,
              );
            }
          );
        }
      );
    });
  }, []);
  ////Fin Efectos CargaDatos

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        autoComplete="off"
        requiredMark={true}
        initialValues={{}}
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }
          <Col span={23}>
            <Card bordered={false}>
              <Meta title="Confirmacion Formulario Maneja Solo" />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal
                </Divider>

                <Col span={24}>
                  <Row gutter={[16, 0]} justify="space-around" align="middle">
                    <Col span={12}>
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[16, 0]}>
                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Nombre"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombre" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Departamento"
                            name="departamento"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa departamento.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Departamento" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Puesto"
                            name="puesto"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa puesto.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Puesto" />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            className="username"
                            label="Entrevistador"
                            name="entrevistador"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa entrevistador.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Entrevistador" />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                      </Row>
                    </Col>

                    <Col span={6}>
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Entrevista General
                </Divider>
                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Tipo de cuenta"
                    name="tipoCuenta"
                    rules={[
                      {
                        required: true,
                        message: "Tipo Cuenta.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Seleccione el tipo de cuenta"
                    >
                      <Option value="Español">Español</Option>
                      <Option value="Inglés">Inglés</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Form.Item
                    className="username"
                    label="Famiiares en ProntoBPO, Proyecto y relación"
                    name="familiaresPronto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa si tienes familiares.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Familiares en pronto" />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Form.Item
                    className="username"
                    label="Fuente del CV"
                    name="fuenteCV"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese fuente de CV.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona fuente"
                    >
                      <Option value="Correo Recruitment">
                        Correo Recruitment
                      </Option>
                      <Option value="Referido">Referido</Option>
                      <Option value="Correo Reclutamiento">
                        Correo Reclutamiento
                      </Option>
                      <Option value="LinkedIn">LinkedIn</Option>
                      <Option value="Facebook">Facebook</Option>
                      <Option value="Presencial">Presencial</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="username"
                    label="Edad"
                    name="edad"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa edad.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Edad" />
                  </Form.Item>
                </Col>

                <Col span={20} offset={2}>
                  <Form.Item
                    className="username"
                    label="Dirección o zona en la que Reside"
                    name="direccionRecide"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa direccion.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Direccion" />
                  </Form.Item>
                </Col>

                <Col span={8} offset={2}>
                  <Form.Item
                    className="username"
                    label="Salario Actual"
                    name="salarioActual"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa salario actual.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Salario" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Disponibilidad Inmediata"
                    name="disponibilidadInmediata"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese disponibilidad.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="Tiene que dar tiempo">
                        Tiene que dar tiempo
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={16} offset={4}>
                  <Form.Item
                    className="username"
                    label="Tipo de Transporte"
                    name="tipoTransporte"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese tipo transporte.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona transporte"
                    >
                      <Option value="Moto (verificar si hay parqueo antes de ofrecerlo)">
                        Moto (verificar si hay parqueo antes de ofrecerlo)
                      </Option>
                      <Option value="Carro (Mencionar de los parqueos aledaños)">
                        Carro (Mencionar de los parqueos aledaños)
                      </Option>
                      <Option value="Transporte público ( Considerar Horarios )">
                        Transporte público ( Considerar Horarios )
                      </Option>
                      <Option value="Vienen a dejar y a traer ( Considerar Horarios )">
                        Vienen a dejar y a traer ( Considerar Horarios )
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Cuál es tu principal fuente de motivación / Qué disfrutas"
                    name="principalMotivacion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Qué es lo que más te hace enojar (profundizar)"
                    name="queEnojar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Padece de alguna Enfermedad, Alergia o Similar que requiera seguimiento"
                    name="padeceEnfermedad"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Descripción del candidato en Metas, valores y/o fortalezas."
                    name="descripcionMetasValores"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Quiénes conforman tu núcleo familiar (profundizar)"
                    name="nucleoFamiliar"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Estudia actualmente o tiene planes de estudiar (especificar horario)"
                    name="estudiaActualmente"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Nivel Academico"
                    name="nivelAcademico"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese nivel Academico",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Diversificado Completo">
                        Diversificado Completo
                      </Option>
                      <Option value="Licenciatura">Licenciatura</Option>
                      <Option value="Posgrado">Posgrado</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Ha Laborado En Pronto"
                    name="laboradoPronto"
                    rules={[
                      {
                        required: true,
                        message: "Ingrese nivel Academico",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si ( Preguntar por recontratación y por qué se fue de la empresa)">
                        Si ( Preguntar por recontratación y por qué se fue de la
                        empresa)
                      </Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Experiencia Laboral Detallada
                            Últimos 3 empleos, funciones y motivo de salida, ultimo salario"
                    name="experienciaLaboralDetallada"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Indica tu mayor logro en tu record laboral y cómo este aporto a la organización"
                    name="mayorLogroLaboral"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Qué comprendes de la plaza a la que estás aplicando"
                    name="comprendePuesto"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Tiene algún problema para aperturar cuenta en Banco Industrial"
                    name="problemaBanco"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="No">No</Option>
                      <Option value="Si (Preguntar por disponibilidad de cheque a encargado del área)">
                        Si (Preguntar por disponibilidad de cheque a encargado
                        del área)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Título Firmado y Sellado por Contraloría"
                    name="tituloFirmado"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Antecedentes Tramitados (Agregar a Notas Adicionales en caso tenga problemas para Tramitarlos)"
                    name="antecedentes"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Informar de las fechas de Pago (Salario Base el 30 y 15 solo bonos) "
                    name="fechasDePago"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Verificación de Papelería, según Checklist"
                    name="verificacionPapeleria"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Headset ( En caso no tenga, explicar pagos hasta 4 cuotas Q.400.00 -Q.450.00)"
                    name="headsets"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Pruebas Psicojob enviadas"
                    name="pruebasPsicojob"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Selecciona"
                    >
                      <Option value="Si">Si</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ESCUCHA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame de una ocasión sobre una materia que te gustó y qué fue lo que más aprendiste de tu catedrático?"
                    name="escucha1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo reaccionas ante un tema que no es de tu interés, pero es necesario?"
                    name="escucha2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  APRENDIZAJE EN EL VUELO
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame sobre alguna ocasión en la que te hayan brindado poco tiempo para aprender un nuevo proceso.?"
                    name="aenvuelo1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué factores consideras importantes a tomar en cuenta cuando estás aprendiendo sobre un nuevo proceso o actividad?"
                    name="aenvuelo2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  ORIENTACIÓN A LA ACCIÓN
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame de alguna ocasión que te encontrarás estresado y cómo lo manejaste?"
                    name="oalacion1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué habilidades consideras fundamentales para llevar a cabo tus actividades de manera efectiva?"
                    name="oalacion2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  NEGOCIACIÓN
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame sobre algún proceso que hayas realizado para una venta en frío.?"
                    name="negociacion1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cómo has manejado una situación en la que el cliente no se encuentra interesado en tu producto o servicio?"
                    name="negociacion2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  FLEXIBILIDAD
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Qué factores consideras importantes para adaptarte a un nuevo lugar?"
                    name="flexibilidad1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame sobre alguna situación en la que no estabas completamente de acuerdo pero fuiste flexible sobre la decisión final.?"
                    name="flexibilidad2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  RESOLUCIÓN DE PROBLEMAS
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre algún conflicto en el que te hayas visto involucrado y cómo lo resolviste.?"
                    name="rproblemas1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coménteme sobre alguna vez haya ayudado algún compañero en un problema.?"
                    name="rproblemas2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  COMUNICACIÓN VERBAL/ESCRITA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Coméntame sobre alguna oportunidad relacionada  a estudios o trabajo en la que hayas tenido que exponer un tema y como resultó.?"
                    name="cverbalescrita1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Puedes indicar alguna oportunidad en la que  se te solicitó un informe detallado del comportamiento de alguna base de datos /Análisis y en qué te basaste para detallar por escrito.?"
                    name="cverbalescrita2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  TOLERANCIA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Consideras que para llevar una buena relación con tu equipo de trabajo es importante escuchar de manera respetuosa sus ideas y comentarios? ¿Por qué?"
                    name="tolerancia1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación en la que fuiste tolerante ante una opinión que no considerabas correcta.?"
                    name="tolerancia2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  EMPATÍA
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Consideras importante ponerte en el lugar de los demás para comprender sus actitudes? ¿Por qué?"
                    name="empatia1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación en la que a pesar de no estar de acuerdo con un compañero, comprendiste su manera de actuar.?"
                    name="empatia2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  APRENDIZAJE AUTÓNOMO
                </Divider>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="Cuando algún proceso es modificado y aún no te encuentras familiarizado con este, ¿Qué herramientas utilizas para actualizarte?"
                    name="aprendizajeautonomo1"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    className="username"
                    label="¿Cuéntame sobre alguna situación en la que de manera independiente aprendiste a desarrollar una actividad.?"
                    name="aprendizajeautonomo2"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa selecciona uno",
                      },
                    ]}
                  >
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={23}>
            <Card bordered={false}>
              <Row gutter={[16, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  PUNTOS FUERTES Y DÉBILES
                </Divider>

                <Col span={10} offset={1}>
                  <Form.Item
                    className="username"
                    label="HABILIDADES Y DESTREZAS "
                    name="habilidadesydestrezas"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa tu HABILIDADES Y DESTREZAS .",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="HABILIDADES Y DESTREZAS" />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item
                    className="username"
                    label="DEBILIDADES"
                    name="debilidades"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa DEBILIDADES.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="DEBILIDADES" />
                  </Form.Item>
                </Col>

                <Col span={12} offset={6}>
                  <Form.Item
                    className="username"
                    label="Comentarios"
                    name="comentarios"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa Comentarios.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Comentarios"
                      autoSize={{ minRows: 1, maxRows: 8 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} offset={6}>
                  <Row>
                    <Col span={6} offset={3}>
                      <Form.Item>
                        <Button
                          loading={false}
                          size="Large"
                          type="primary"
                          htmlType="submit"
                          onClick={() => EnviarOfertar(idRequerimiento)}
                        >
                          Confirmar Entrevista
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={6} offset={6}>
                      <Button
                        loading={false}
                        size="Large"
                        type="primary"
                        danger
                        onClick={denegarSolicitudEmpleo}
                      >
                        Declinar Perfil
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        <Modal
          centered
          visible={modalSucessDenegado}
          onOk={() => history.push({ pathname: "./solicitudes/perfiles" })}
          onCancel={() => setModalSucessDenegado(false)}
          footer={
            <Button
              type="primary"
              onClick={() =>
                history.push({ pathname: "./solicitudes/perfiles" })
              }
            >
              Salir
            </Button>
          }
        >
          <Sucess titulo="Perfil denegado con exito!" />
        </Modal>
      }
    </>
  );
};

export default ConfirmarFormularioManejaSolo;
