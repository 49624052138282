import Axios from "axios";
const getUserHelpers = async (token) => {

    try {
        const { data } = await Axios.get(
          "https://rh.api.prontobpo.cloud/api/users/me",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let datosID = data.id;

        const infoUserComplete = await Axios.get(
            `https://rh.api.prontobpo.cloud/api/users/${datosID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(infoUserComplete.data);
          return infoUserComplete.data;

    } catch (error) {
      console.log(error);
      return error;
    }
  
};
export default getUserHelpers;
