import React, { useState, useEffect, useCallback } from "react";
import FileViewer from "react-file-viewer";
import Axios from "axios";
import { getToken } from "../../Helpers/auth-helpers";

const Vista = ({ idPerfil }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const cargaDatos = useCallback(async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await Axios.get(
        `https://rh.api.prontobpo.cloud/api/perfiles/${idPerfil}?populate[adjuntos]=*`,
        { headers }
      );

      console.log("Datos de perfil", data);

      setFiles(data.data.attributes.adjuntos.data);
    } catch (error) {
      console.error("Error al cargar los datos del perfil:", error);
    } finally {
      setLoading(false);
    }
  }, [idPerfil]);

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  useEffect(() => {
    cargaDatos();
  }, [cargaDatos]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (files === null) {
    return <div>Sin archivos adjuntos</div>;
  }

  return (
    <div>
      {files.map((fileData, index) => {
        const file = `https://rh.api.prontobpo.cloud${fileData.attributes.url}`;
        const fileType = fileData.attributes.ext.substring(1); // Remover el punto de la extensión

        return (
          <div key={index}>
            <h3>{fileData.attributes.name}</h3>
            <FileViewer
              fileType={fileType}
              filePath={file}
              onError={onError}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Vista;
