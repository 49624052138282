////React
import React, { useEffect, useState } from "react";

////React Dom
import { useParams, useHistory } from "react-router-dom";

///Importar DND
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

////Layout Sucess
import Sucess from "../../components/responses/Sucess";

////AntDesign
import {
  Card,
  Row,
  Col,
  Empty,
  PageHeader,
  Avatar,
  Button,
  Typography,
  notification,
  Modal,
  Tooltip,
  Segmented,
  Input,
  List,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";

///Axios
import Axios from "axios";
import "moment/locale/es";
import moment from "moment";

/////Helpers
import { getToken } from "../../Helpers/auth-helpers";

//////Return del componente
const Perfiles = ({ usuario }) => {
  const { Meta } = Card;

  ////History react Dom
  const history = useHistory();

  ///Use import {  } from "module";
  const [nombre] = usuario;
  const [datos, setdatos] = useState([]);
  const [datosEntrevista, setdatosEntrevista] = useState([]);
  const [datosDeclinado, setdatosDeclinado] = useState([]);
  const [datosOferta, setdatosOferta] = useState([]);
  const [datosContratado, setdatosContratado] = useState([]);
  const [modalSucess, setModalSucess] = useState(false);
  const [modalSucessDC, setModalSucessDC] = useState(false);

  const [modalFiltradoGlobal, setModalFiltradoGlobal] = useState(false);
  const [searchTermGlobal, setSearchTermGlobal] = useState("");
  const [searchTypeGlobal, setSearchTypeGlobal] = useState("Nombre");
  const [resultsGlobal, setResultsGlobal] = useState([]);

  const [modalFiltradoCandidatos, setModalFiltradoCandidatos] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("Nombre");
  const [results, setResults] = useState([]);

  const [
    modalFiltradoCandidatosEntrevista,
    setModalFiltradoCandidatosEntrevista,
  ] = useState(false);
  const [searchTermEntrevista, setSearchTermEntrevista] = useState("");
  const [searchTypeEntrevista, setSearchTypeEntrevista] = useState("Nombre");
  const [resultsEntrevista, setResultsEntrevista] = useState([]);

  const [
    modalFiltradoCandidatosDeclinado,
    setModalFiltradoCandidatosDeclinado,
  ] = useState(false);
  const [searchTermDeclinado, setSearchTermDeclinado] = useState("");
  const [searchTypeDeclinado, setSearchTypeDeclinado] = useState("Nombre");
  const [resultsDeclinado, setResultsDeclinado] = useState([]);

  const [modalFiltradoCandidatosOferta, setModalFiltradoCandidatosOferta] =
    useState(false);
  const [searchTermOferta, setSearchTermOferta] = useState("");
  const [searchTypeOferta, setSearchTypeOferta] = useState("Nombre");
  const [resultsOferta, setResultsOferta] = useState([]);

  const [
    modalFiltradoCandidatosContratado,
    setModalFiltradoCandidatosContratado,
  ] = useState(false);
  const [searchTermContratado, setSearchTermContratado] = useState("");
  const [searchTypeContratado, setSearchTypeContratado] = useState("Nombre");
  const [resultsContratado, setResultsContratado] = useState([]);

  ////UseParams
  const { nombreRequerimiento } = useParams();

  ///Notificacion
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "ERROR",
      description: "No se puede realizar el movimiento.",
    });
  };
  console.log("Token de reclu", getToken());

  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    try {
      const [datosResp, datos2Resp, datos3Resp, datos4Resp, datos5Resp] =
        await Promise.all([
          Axios.get(
            "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$in]=Aplicado&filters[estado][$in]=Confirmacion entrevista 1&populate[mother]=*",
            { headers }
          ),
          Axios.get(
            "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$eq]=Entrevista&populate[]=mother.asignadoSistema",
            { headers }
          ),
          Axios.get(
            "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$eq]=Declinado&populate[mother]=*",
            { headers }
          ),
          Axios.get(
            "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$eq]=Oferta&populate[]=mother.asignadoSistema",
            { headers }
          ),
          Axios.get(
            "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=1000&filters[estado][$eq]=Contratado&populate[requerimiento]=*&populate[mother]=*",
            { headers }
          ),
        ]);

      console.log(
        "Datos de Aplicado y Confirmacion entrevista 1:",
        datosResp.data.data
      );
      setdatos(datosResp.data.data);

      console.log("Datos de Entrevista:", datos2Resp.data.data);
      setdatosEntrevista(datos2Resp.data.data);

      console.log("Datos de Declinado:", datos3Resp.data.data);
      setdatosDeclinado(datos3Resp.data.data);

      console.log("Datos de Oferta:", datos4Resp.data.data);
      setdatosOferta(datos4Resp.data.data);

      console.log("Datos de Contratado:", datos5Resp.data.data);
      setdatosContratado(datos5Resp.data.data);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  const recargarPagina = () => {
    setModalSucess(false);
    window.location.reload();
  };

  /////funcion enviar
  const enviarPagina = () => {
    console.log("**************** Enviar pagina");
    history.push({ pathname: "/solicitudes/perfiles" });
  };

  /////funcion enviar a Formulario
  const Enviar = (id) => {
    console.log("**************** Enviar pagina", id);
    history.push({
      pathname: "../FormularioPerfil", ///Direccion a donde dirige
      search: `?id=${id}`, //parametros a enviar
      state: {
        //Estado
        update: true,
      },
    });
  };

  /////funcion enviar Formulario
  const EnviarEntrevista = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${id}?populate[mother]=*`,
      { headers }
    );

    console.log("Data de envio de entrevista", data);

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Datos de la obtencion de asignado", dataasignados);

    const tipoEntrevista = dataasignados.data.data[0].attributes.Entrevista;

    if (tipoEntrevista === "Maneja Solo") {
      history.push({
        pathname: "../FormularioManejaSolo", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Personas") {
      history.push({
        pathname: "../FormularioManejaPersonas", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Equipos") {
      history.push({
        pathname: "../FormularioManejaEquipos", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Areas") {
      history.push({
        pathname: "../FormularioManejaAreas", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    }
  };
  /////Fin funcion enviar Formulario

  /////funcion enviar a Formulario
  const EnviarOferta = async (id) => {
    console.log("id enviado de perfi", id);
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    //console.log(datos, headers);
    const { data } = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/perfiles/${id}?populate[mother]=*`,
      { headers }
    );

    console.log("Datos de perfiles", data);

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Datos de asignados", dataasignados);

    const tipoEntrevista = dataasignados.data.data[0].attributes.Entrevista;

    if (tipoEntrevista === "Maneja Solo") {
      history.push({
        pathname: "../ConfirmarFormularioManejaSolo", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Personas") {
      history.push({
        pathname: "../ConfirmarFormularioManejaPersonas", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Equipos") {
      history.push({
        pathname: "../ConfirmarFormularioManejaEquipos", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    } else if (tipoEntrevista === "Maneja Areas") {
      history.push({
        pathname: "../ConfirmarFormularioManejaAreas", ///Direccion a donde dirige
        search: `?id=${id}`, //parametros a enviar
        state: {
          //Estado
          update: true,
        },
      });
    }
  };
  /////Fin funcion enviar a Formulario

  const actualizarRequerimiento = async (id) => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "En proceso",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const estadoActualizado = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/requerimientos/${id}`,
      datosActualizar,
      { headers }
    );

    console.log("Actualice el estado del requerimiento", estadoActualizado);

    return estadoActualizado;
  };

  const reActivar = async (data) => {
    console.log("Datos Perfil", data);
    console.log("id Perfil", data.id);

    console.log(
      "Estado del requerimiento",
      data.attributes.requerimiento.data.attributes.estado
    );

    console.log("Id del requerimiento", data.attributes.requerimiento.data.id);

    if (data.attributes.requerimiento.data.attributes.estado === "En Proceso") {
      const datosActualizar = JSON.stringify({
        data: {
          requerimiento: null,
        },
      });

      const headers = {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      };

      const estadoActualizado = await Axios.put(
        `https://rh.api.prontobpo.cloud/api/perfiles/${data.id}`,
        datosActualizar,
        { headers }
      );

      console.log("Actualice el estado", estadoActualizado);
      declinarContratacion(data);
      return estadoActualizado;
    } else {
      const datosActualizar = JSON.stringify({
        data: {
          recruitify_requerimiento: null,
        },
      });

      const headers = {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      };

      const estadoActualizado = await Axios.put(
        `https://rh.api.prontobpo.cloud/api/perfiles/${data.id}`,
        datosActualizar,
        { headers }
      );

      console.log("Actualice el estado", estadoActualizado);
      actualizarRequerimiento(data.recruitify_requerimiento.id);
      declinarContratacion(data);
      return estadoActualizado;
    }
  };

  const declinarContratacion = async (data) => {
    console.log("Datos del perfil a Modificar", data);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const getPC = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/primercontactos?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
      { headers }
    );
    console.log("Id primer contacto a borrar", getPC);

    const deletPC = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/primercontactos/${getPC.data.data[0].id}`,
      { headers }
    );
    console.log("Id primer contacto a borrar", deletPC);

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Datos asignados", dataasignados);

    if (dataasignados.data.data.length >= 1) {
      console.log("hay Datos", dataasignados);

      let Entrevista = dataasignados.data.data[0].attributes.Entrevista;

      console.log("Tipo Entrevista", Entrevista);

      if (Entrevista === "Maneja Solo") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejasolos?filters[IdMother][$eq]=${data.attributes.mother.data.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);

        if (Datosborrar.data.data.length > 0) {
          borrarManejaSolo(Datosborrar.data.data[0].id);
        }

        borrarAsignado(dataasignados.data.data[0].id);
        actualizarEstado(data.id);
        agregadoLogDeclinarContratacion(data.attributes.mother.data.id);
        setModalSucessDC(true);
      } else if (Entrevista === "Maneja Personas") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejapersonas?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);

        if (Datosborrar.data.data.length > 0) {
          borrarManejaPersonas(Datosborrar.data.data[0].id);
        }
        borrarAsignado(dataasignados.data.data[0].id);
        actualizarEstado(data.id);
        agregadoLogDeclinarContratacion(data.attributes.mother.data.id);
        setModalSucessDC(true);
      } else if (Entrevista === "Maneja Equipos") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejaequipos?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);
        if (Datosborrar.data.length > 0) {
          borrarManejaEquipos(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data.data[0].id);
        actualizarEstado(data.id);
        agregadoLogDeclinarContratacion(data.attributes.mother.data.id);
        setModalSucessDC(true);
      } else if (Entrevista === "Maneja Areas") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejaareas?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);
        if (Datosborrar.data.length > 0) {
          borrarManejaAreas(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data.data[0].id);
        actualizarEstado(data.id);
        agregadoLogDeclinarContratacion(data.attributes.mother.data.id);
        setModalSucessDC(true);
      }
    } else {
      console.log("No Hay Datos");
      actualizarEstado(data.id);
      setModalSucessDC(true);
    }
  };

  //////LogPPP
  const agregadoLogDeclinarContratacion = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Se declina contratacion",
        fecha: moment(Date.now()).add(1, "day").format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };
  ////Final LogPPP

  const actualizarEstado = async (id) => {
    const datosActualizar = JSON.stringify({
      data: {
        estado: "Aplicado",
      },
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const estadoActualizado = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/perfiles/${id}`,
      datosActualizar,
      { headers }
    );

    console.log("Actualice el estado", estadoActualizado);

    return estadoActualizado;
  };

  const borrarAsignado = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/asignados/${id}`,
      { headers }
    );

    console.log("Borre los Datos de Asignado", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaSolo = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejasolos/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaPersonas = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejapersonas/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaEquipos = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejaequipos/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const borrarManejaAreas = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const DatosBorrados = await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/manejaareas/${id}`,
      { headers }
    );

    console.log("Borre los Datos", DatosBorrados);

    return DatosBorrados;
  };

  const ReactivarDeclinado = async (data) => {
    console.log("Datos del perfil a reactivar", data);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const dataasignados = await Axios.get(
      `https://rh.api.prontobpo.cloud/api/asignados?filters[idMother][$eq]=${data.attributes.mother.data.id}`,
      { headers }
    );

    console.log("Datos asignados", dataasignados);

    if (dataasignados.data.length >= 1) {
      console.log("hay Datos", dataasignados);

      let Entrevista = dataasignados.data[0].Entrevista;

      console.log("Tipo Entrevista", Entrevista);

      if (Entrevista === "Maneja Solo") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejasolos?filters[IdMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);

        if (Datosborrar.data.length > 0) {
          borrarManejaSolo(Datosborrar.data[0].id);
        }

        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      } else if (Entrevista === "Maneja Personas") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejapersonas?filters[idMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);

        if (Datosborrar.data.length > 0) {
          borrarManejaPersonas(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      } else if (Entrevista === "Maneja Equipos") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejaequipos?filters[idMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);
        if (Datosborrar.data.length > 0) {
          borrarManejaEquipos(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      } else if (Entrevista === "Maneja Areas") {
        const Datosborrar = await Axios.get(
          `https://rh.api.prontobpo.cloud/api/manejaareas?filters[idMother][$eq]=${data.recruitify_mother.id}`,
          { headers }
        );

        console.log("Respuesta borrado", Datosborrar);
        if (Datosborrar.data.length > 0) {
          borrarManejaAreas(Datosborrar.data[0].id);
        }
        borrarAsignado(dataasignados.data[0].id);
        actualizarEstado(data.id);
        agregadoLogReactivar(data.attributes.mother.data.id);
        setModalSucess(true);
      }
    } else {
      console.log("No Hay Datos");
      actualizarEstado(data.id);
      agregadoLogReactivar(data.attributes.mother.data.id);
      setModalSucess(true);
    }
  };

  //////LogPPP
  const agregadoLogReactivar = async (id) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const datos = JSON.stringify({
      data: {
        funcion: "Reactivacion de usuario declinado",
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: id,
      },
    });

    console.log("Llego al envio del Log", datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datos,
      { headers }
    );

    console.log("Datos a guardar del log", data);

    return data;
  };
  ////Final LogPPP

  ///Funcion  detectar droppableId
  const droppableIdDetect = async (datosDng) => {
    console.log(">>> dentro de funcion droppableIdDetect", datosDng);

    //verificar variagles
    const origen = datosDng?.source?.droppableId;
    const destino = datosDng?.destination?.droppableId || origen;
    const indexOrigen = datosDng?.source?.index;
    let newArrayEntrevistaP = [];
    let newArrayEntrevista = [];

    //No hacer si es igual = Prueba logica
    if (destino === origen) return openNotificationWithIcon("error");
    if (destino === "Aplicado") return openNotificationWithIcon("error");
    if (origen === "Oferta" && destino === "Entrevista")
      return openNotificationWithIcon("error");
    if (origen === "Contratado") return openNotificationWithIcon("error");

    //Detecar el origen para agregarlo
    switch (origen) {
      case "Entrevista":
        newArrayEntrevistaP = datosEntrevista.filter(
          (item, index) => index === indexOrigen
        );
        newArrayEntrevista = datosEntrevista.filter(
          (item, index) => index !== indexOrigen
        );
        setdatosEntrevista(newArrayEntrevista);
        break;
      case "Aplicado":
        newArrayEntrevistaP = datos.filter(
          (item, index) => index === indexOrigen
        );
        newArrayEntrevista = datos.filter(
          (item, index) => index !== indexOrigen
        );
        setdatos(newArrayEntrevista);
        break;
      case "Declinado":
        newArrayEntrevistaP = datosDeclinado.filter(
          (item, index) => index === indexOrigen
        );
        newArrayEntrevista = datosDeclinado.filter(
          (item, index) => index !== indexOrigen
        );
        setdatosDeclinado(newArrayEntrevista);
        break;
      case "Oferta":
        newArrayEntrevistaP = datosOferta.filter(
          (item, index) => index === indexOrigen
        );
        newArrayEntrevista = datosOferta.filter(
          (item, index) => index !== indexOrigen
        );
        setdatosOferta(newArrayEntrevista);
        break;
      case "Contratado":
        newArrayEntrevistaP = datosContratado.filter(
          (item, index) => index === indexOrigen
        );
        newArrayEntrevista = datosContratado.filter(
          (item, index) => index !== indexOrigen
        );
        setdatosContratado(newArrayEntrevista);
        break;
      default:
        // Opcional: Manejar casos no previstos
        console.warn(`Origen desconocido: ${origen}`);
        break;
    }

    switch (destino) {
      case "Entrevista":
        setdatosEntrevista([...datosEntrevista, newArrayEntrevistaP[0]]);
        break;
      case "Aplicado":
        setdatos([...datos, newArrayEntrevistaP[0]]);
        break;
      case "Declinado":
        setdatosDeclinado([...datosDeclinado, newArrayEntrevistaP[0]]);
        break;
      case "Oferta":
        setdatosOferta([...datosOferta, newArrayEntrevistaP[0]]);
        break;
      case "Contratado":
        setdatosContratado([...datosContratado, newArrayEntrevistaP[0]]);
        break;
      default:
        // Opcional: Manejar casos no previstos
        console.warn(`Destino desconocido: ${destino}`);
        break;
    }

    const datosActualizar = JSON.stringify({
      estado: destino,
    });

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    const idRequerimiento = newArrayEntrevistaP[0].id;
    const { dataStatus } = await Axios.put(
      `https://rh.api.prontobpo.cloud/api/recruitiify-perfils/${idRequerimiento}`,
      datosActualizar,
      { headers }
    );

    console.log(">>> resultado de axios", dataStatus);
  };

  const handleSegmentChangeGlobal = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeGlobal(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermGlobal("");
    setResultsGlobal([]);
  };

  const handleSearchGlobal = async () => {
    let filterQuery;
    console.log(searchTypeGlobal, searchTermGlobal);

    switch (searchType) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermGlobal}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermGlobal}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermGlobal}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTermGlobal}" }`;
    }

    const query = `query {
      mothers(
        filters: {
          ${filterQuery} 
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            perfile {
              data {
                id
                attributes{
                  estado
                }
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsGlobal(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };

  /////Funciones del filtrado de candidatos
  const handleSegmentChange = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchType(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTerm("");
    setResults([]);
  };

  const handleSearch = async () => {
    let filterQuery;
    console.log(searchType, searchTerm);

    switch (searchType) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTerm}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTerm}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTerm}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTerm}" }`;
    }

    const query = `query {
      mothers(
        filters: { 
          or: [
            { perfile: { estado: { eq: "Aplicado" } } },
            { perfile: { estado: { eq: "Confirmacion entrevista 1" } } }
          ],
          ${filterQuery}
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            perfile {
              data {
                id
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResults(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };

  const handleSegmentChangeEntrevista = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeEntrevista(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermEntrevista("");
    setResultsEntrevista([]);
  };

  const handleSearchEntrevista = async () => {
    let filterQuery;
    console.log(searchTypeEntrevista, searchTermEntrevista);

    switch (searchTypeEntrevista) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermEntrevista}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermEntrevista}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermEntrevista}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTermEntrevista}" }`;
    }

    const query = `query{
      mothers(
        filters: {
          perfile: { estado: { eq: "Entrevista" } }, 
          ${filterQuery} 
        }
      ){
        data{
          id
          attributes{
            nombre
            apellido
            correo
            dpi
            perfile{
              data{
                id
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsEntrevista(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };

  const handleSegmentChangeDeclinado = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeDeclinado(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermDeclinado("");
    setResultsDeclinado([]);
  };

  const handleSearchDeclinado = async () => {
    let filterQuery;
    console.log(searchTypeDeclinado, searchTermDeclinado);

    switch (searchTypeDeclinado) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermDeclinado}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermDeclinado}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermDeclinado}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTermDeclinado}" }`;
    }

    const query = `query {
      mothers(
        filters: { 
          perfile: { estado: { eq: "Declinado" } },
          ${filterQuery}
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            perfile {
              data {
                id
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsDeclinado(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };

  const handleSegmentChangeOferta = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeOferta(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermOferta("");
    setResultsOferta([]);
  };

  const handleSearchOferta = async () => {
    let filterQuery;
    console.log(searchTypeOferta, searchTermOferta);

    switch (searchTypeOferta) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermOferta}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermOferta}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermOferta}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTermOferta}" }`;
    }

    const query = `query {
      mothers(
        filters: { 
           perfile: { estado: { eq: "Oferta" } },
          ${filterQuery}
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            perfile {
              data {
                id
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsOferta(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };

  const handleSegmentChangeContratado = (value) => {
    // Cambiar el tipo de búsqueda
    setSearchTypeContratado(value);

    // Limpiar el término de búsqueda y los resultados
    setSearchTermContratado("");
    setResultsContratado([]);
  };

  const handleSearchContratado = async () => {
    let filterQuery;
    console.log(searchTypeContratado, searchTermContratado);

    switch (searchTypeContratado) {
      case "Nombre":
        filterQuery = `nombre: { contains: "${searchTermContratado}" }`;
        break;
      case "Correo":
        filterQuery = `correo: { contains: "${searchTermContratado}" }`;
        break;
      case "DPI":
        filterQuery = `dpi: { contains: "${searchTermContratado}" }`;
        break;
      default:
        filterQuery = `nombre: { contains: "${searchTermContratado}" }`;
    }

    const query = `query {
      mothers(
        filters: { 
          perfile: { estado: { eq: "Contratado" } },
          ${filterQuery}
        }
      ) {
        data {
          id
          attributes {
            nombre
            apellido
            correo
            dpi
            perfile {
              data {
                id
              }
            }
          }
        }
      }
    }`;

    try {
      const response = await Axios.post(
        `https://rh.api.prontobpo.cloud/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setResultsContratado(response.data.data.mothers.data);
    } catch (error) {
      console.error("Error fetching mothers:", error);
    }
  };
  //////Fin funciones de filtrado de candidatos

  return (
    <div>
      <PageHeader
        onBack={() => enviarPagina()}
        title={nombreRequerimiento}
        subTitle={false}
      />

      <Row justify="space-between" align="top">
        <Col span={4}>
          <Typography.Title level={2}>Perfiles</Typography.Title>
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => setModalFiltradoGlobal(true)}
          >
            Busqueda Global
          </Button>
        </Col>
      </Row>

      <br />

      <DragDropContext
        onDragEnd={(result) => {
          droppableIdDetect(result);
        }}
      >
        {datos ? (
          <div className="scrollHorizontal">
            <Row
              justify="space-around"
              align="top"
              gutter={0}
              style={{ width: "150%", height: "80%" }}
            >
              <Droppable droppableId="Aplicado">
                {(provided) => (
                  <Col
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    span={4}
                  >
                    <Card
                      className="cardDnd"
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            Aplicado
                          </Typography.Title>
                          <Tooltip title="Filtrar Candidatos">
                            <Button
                              type="link"
                              size="large"
                              onClick={() => setModalFiltradoCandidatos(true)}
                              icon={<SearchOutlined />}
                            />
                          </Tooltip>
                        </div>
                      }
                      bordered={true}
                    >
                      <Row>
                        {datos.map((data, index) => {
                          return (
                            <Draggable
                              key={data.id}
                              draggableId={data.attributes.nombre}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Card
                                    style={{
                                      marginTop: 16,
                                      maxWidth: "100%", // Limita el ancho de la tarjeta
                                    }}
                                  >
                                    <Meta
                                      avatar={
                                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                                      }
                                      title={
                                        <div
                                          style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {data.attributes.nombre}
                                        </div>
                                      }
                                      description={
                                        <div
                                          style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {data.attributes.estado}
                                          <br />
                                          Posicion Aplica:{" "}
                                          {
                                            data.attributes.mother.data
                                              .attributes.posicionAplica
                                          }
                                        </div>
                                      }
                                    />
                                    <br />
                                    <Button
                                      type="primary"
                                      onClick={() => Enviar(data.id)}
                                    >
                                      Ver
                                    </Button>
                                  </Card>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </Row>
                    </Card>
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>

              <Droppable droppableId="Entrevista">
                {(provided) => (
                  <Col
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    span={4}
                  >
                    <Card
                      className="cardDnd"
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            Entrevista
                          </Typography.Title>
                          <Tooltip title="Filtrar Candidatos">
                            <Button
                              type="link"
                              size="large"
                              onClick={() =>
                                setModalFiltradoCandidatosEntrevista(true)
                              }
                              icon={<SearchOutlined />}
                            />
                          </Tooltip>
                        </div>
                      }
                      bordered={true}
                    >
                      <Row>
                        {datosEntrevista.map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={"Entrevista" + data.attributes.nombre}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card
                                  style={{
                                    marginTop: 2,
                                    maxWidth: "100%", // Limita el ancho de la tarjeta
                                  }}
                                >
                                  <Meta
                                    avatar={
                                      <Avatar src="https://joeschmoe.io/api/v1/random" />
                                    }
                                    title={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {data.attributes.nombre}
                                      </div>
                                    }
                                    description={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Proyecto:{" "}
                                        {
                                          JSON.parse(
                                            data.attributes.mother.data
                                              .attributes.asignadoSistema.data
                                              .attributes.Proyecto
                                          )[0]
                                        }
                                        <br />
                                        Estado: {data.attributes.estado}
                                      </div>
                                    }
                                  />
                                  <br />
                                  <Col span={24}>
                                    <Row>
                                      <Col span={4}>
                                        <Button
                                          type="primary"
                                          onClick={() =>
                                            EnviarEntrevista(data.id)
                                          }
                                        >
                                          Entrevistar
                                        </Button>
                                      </Col>
                                      <Col span={6} offset={12}>
                                        <Button
                                          type="primary"
                                          onClick={() => Enviar(data.id)}
                                        >
                                          Ver
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Row>
                    </Card>
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>

              <Droppable droppableId="Declinado">
                {(provided) => (
                  <Col
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    span={4}
                  >
                    <Card
                      className="cardDnd"
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            Declinado
                          </Typography.Title>
                          <Tooltip title="Filtrar Candidatos">
                            <Button
                              type="link"
                              size="large"
                              onClick={() =>
                                setModalFiltradoCandidatosDeclinado(true)
                              }
                              icon={<SearchOutlined />}
                            />
                          </Tooltip>
                        </div>
                      }
                      bordered={true}
                    >
                      <Row>
                        {datosDeclinado.map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={"Declinado" + data.attributes.nombre}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card
                                  style={{ marginTop: 16, maxWidth: "100%" }}
                                >
                                  <Meta
                                    avatar={
                                      <Avatar src="https://joeschmoe.io/api/v1/random" />
                                    }
                                    title={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {data.attributes.nombre}
                                      </div>
                                    }
                                    description={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {data.attributes.estado}
                                      </div>
                                    }
                                  />
                                  <br />
                                  <Col span={24}>
                                    <Row gutter={[8, 8]}>
                                      <Col span={12}>
                                        <Button
                                          block
                                          type="primary"
                                          onClick={() => Enviar(data.id)}
                                        >
                                          Ir
                                        </Button>
                                      </Col>

                                      <Col span={24}>
                                        <Button
                                          block
                                          danger
                                          type="primary"
                                          onClick={() =>
                                            ReactivarDeclinado(data)
                                          }
                                        >
                                          Reactivar Aplicante
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Row>
                    </Card>
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>

              <Droppable droppableId="Oferta">
                {(provided) => (
                  <Col
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    span={4}
                  >
                    <Card
                      className="cardDnd"
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            Oferta
                          </Typography.Title>
                          <Tooltip title="Filtrar Candidatos">
                            <Button
                              type="link"
                              size="large"
                              onClick={() =>
                                setModalFiltradoCandidatosOferta(true)
                              }
                              icon={<SearchOutlined />}
                            />
                          </Tooltip>
                        </div>
                      }
                      bordered={true}
                    >
                      <Row>
                        {datosOferta.map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={"Oferta" + data.attributes.nombre}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card
                                  style={{ marginTop: 16, maxWidth: "100%" }}
                                >
                                  <Meta
                                    avatar={
                                      <Avatar src="https://joeschmoe.io/api/v1/random" />
                                    }
                                    title={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {data.attributes.nombre}
                                      </div>
                                    }
                                    description={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Proyecto:{" "}
                                        {
                                          JSON.parse(
                                            data.attributes.mother.data
                                              .attributes.asignadoSistema.data
                                              .attributes.Proyecto
                                          )[0]
                                        }
                                        <br />
                                        Estado: {data.attributes.estado}
                                      </div>
                                    }
                                  />
                                  <br />
                                  <Col span={24}>
                                    <Row gutter={[8, 8]}>
                                      <Col span={12}>
                                        <Button
                                          block
                                          type="primary"
                                          onClick={() => EnviarOferta(data.id)}
                                        >
                                          Ofertar
                                        </Button>
                                      </Col>
                                      <Col span={12}>
                                        <Button
                                          block
                                          type="primary"
                                          onClick={() => Enviar(data.id)}
                                        >
                                          Ver
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Row>
                    </Card>
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>

              <Droppable droppableId="Contratado">
                {(provided) => (
                  <Col
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    span={4}
                  >
                    <Card
                      className="cardDnd"
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            Contratado
                          </Typography.Title>
                          <Tooltip title="Filtrar Candidatos">
                            <Button
                              type="link"
                              size="large"
                              onClick={() =>
                                setModalFiltradoCandidatosContratado(true)
                              }
                              icon={<SearchOutlined />}
                            />
                          </Tooltip>
                        </div>
                      }
                      bordered={true}
                    >
                      <Row>
                        {datosContratado.map((data, index) => (
                          <Draggable
                            key={data.id}
                            draggableId={"Contratado" + data.attributes.nombre}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Card
                                  style={{ marginTop: 16, maxWidth: "100%" }}
                                >
                                  <Meta
                                    avatar={
                                      <Avatar src="https://joeschmoe.io/api/v1/random" />
                                    }
                                    title={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {data.attributes.nombre}
                                      </div>
                                    }
                                    description={
                                      <div
                                        style={{
                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Proyecto:{" "}
                                        {
                                          data.attributes.requerimiento.data.attributes.proyecto.split(
                                            ","
                                          )[0]
                                        }
                                        <br />
                                        Estado: {data.attributes.estado}
                                      </div>
                                    }
                                  />
                                  <br />
                                  <Col span={24}>
                                    <Row gutter={[8, 8]}>
                                      <Col span={12}>
                                        <Button
                                          block
                                          type="primary"
                                          onClick={() => Enviar(data.id)}
                                        >
                                          Ver Perfil
                                        </Button>
                                      </Col>
                                      <Col span={24}>
                                        <Button
                                          block
                                          type="primary"
                                          danger
                                          onClick={() => reActivar(data)}
                                        >
                                          Declinar Contratacion
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Row>
                    </Card>
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>
            </Row>
          </div>
        ) : (
          <Col span={24}>
            <Row justify="center" align="bottom">
              <Col span={8}>
                <Empty
                  imageStyle={{
                    width: "100%",
                    height: "300px",
                    border: "7px",
                  }}
                  description={<span></span>}
                >
                  No hay Datos
                </Empty>
              </Col>
            </Row>
          </Col>
        )}
      </DragDropContext>

      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => setModalSucess(false)}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button type="primary" onClick={() => recargarPagina()}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Se ah regresado al aplicante al inicio del track!" />
        </Modal>
      }

      {
        ////Modal
        <Modal
          centered
          visible={modalSucessDC}
          onOk={() => setModalSucessDC(false)}
          onCancel={() => setModalSucessDC(false)}
          footer={
            <Button type="primary" onClick={() => recargarPagina()}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Se ah declinado contratacion del aplicante!" />
        </Modal>
      }

      {
        <Modal
          title="Busqueda Global de Candidatos"
          open={modalFiltradoGlobal}
          onOk={() => setModalFiltradoGlobal(false)}
          onCancel={() => setModalFiltradoGlobal(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChangeGlobal}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermGlobal}
                onChange={(e) => setSearchTermGlobal(e.target.value)}
                onPressEnter={handleSearchGlobal}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchGlobal}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsGlobal}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                      <br/>
                      {`Estado App: ${item.attributes.perfile.data.attributes.estado}`}
                      
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }

      {
        <Modal
          title="Buscar Candidatos Aplicados"
          open={modalFiltradoCandidatos}
          onOk={() => setModalFiltradoCandidatos(false)}
          onCancel={() => setModalFiltradoCandidatos(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChange}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onPressEnter={handleSearch}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearch}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={results}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }

      {
        <Modal
          title="Buscar Candidatos Entrevista"
          open={modalFiltradoCandidatosEntrevista}
          onOk={() => setModalFiltradoCandidatosEntrevista(false)}
          onCancel={() => setModalFiltradoCandidatosEntrevista(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChangeEntrevista}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermEntrevista}
                onChange={(e) => setSearchTermEntrevista(e.target.value)}
                onPressEnter={handleSearchEntrevista}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchEntrevista}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsEntrevista}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }

      {
        <Modal
          title="Buscar Candidatos Declinados"
          open={modalFiltradoCandidatosDeclinado}
          onOk={() => setModalFiltradoCandidatosDeclinado(false)}
          onCancel={() => setModalFiltradoCandidatosDeclinado(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChangeDeclinado}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermDeclinado}
                onChange={(e) => setSearchTermDeclinado(e.target.value)}
                onPressEnter={handleSearchDeclinado}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchDeclinado}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsDeclinado}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }

      {
        <Modal
          title="Buscar Candidatos en Oferta"
          open={modalFiltradoCandidatosOferta}
          onOk={() => setModalFiltradoCandidatosOferta(false)}
          onCancel={() => setModalFiltradoCandidatosOferta(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChangeOferta}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermOferta}
                onChange={(e) => setSearchTermOferta(e.target.value)}
                onPressEnter={handleSearchOferta}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchOferta}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsOferta}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }

      {
        <Modal
          title="Buscar Candidatos Contratados"
          open={modalFiltradoCandidatosContratado}
          onOk={() => setModalFiltradoCandidatosContratado(false)}
          onCancel={() => setModalFiltradoCandidatosContratado(false)}
        >
          <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={24}>
              <Segmented
                options={["Nombre", "Correo", "DPI"]}
                onChange={handleSegmentChangeContratado}
              />
            </Col>
            <Col span={24}>
              <Input
                placeholder="Selecciona un tipo de búsqueda"
                value={searchTermContratado}
                onChange={(e) => setSearchTermContratado(e.target.value)}
                onPressEnter={handleSearchContratado}
              />
            </Col>
            <Col span={24}>
              <Button onClick={handleSearchContratado}>Buscar</Button>
            </Col>
            <Col span={24}>
              <List
                itemLayout="horizontal"
                dataSource={resultsContratado}
                renderItem={(item) => (
                  console.log("Datos del item", item),
                  (
                    <List.Item
                      key={item.attributes.perfile.data.id}
                      actions={[
                        <a
                          key="list-loadmore-more"
                          onClick={() =>
                            Enviar(item.attributes.perfile.data.id)
                          }
                        >
                          Ver Perfil
                        </a>,
                      ]}
                    >
                      {" "}
                      {/* Asegúrate de que cada item tiene una clave única */}
                      <List.Item.Meta
                        title={`Nombre: ${
                          item.attributes.nombre +
                          " " +
                          item.attributes.apellido
                        }`}
                        description={`Correo: ${item.attributes.correo}`}
                        // ... otros campos
                      />
                      {`DPI: ${item.attributes.dpi}`}
                    </List.Item>
                  )
                )}
              />
            </Col>
          </Row>
        </Modal>
      }
    </div>
  );
};

export default Perfiles;
