/////React
import React, { useState } from "react";

///Axios
import Axios from "axios";

import { UploadOutlined } from "@ant-design/icons";

////Layout Sucess
import Sucess from "../components/responses/Sucess";
import logo from "../assets/images/aplicante.png";

/////Ant Design
import {
  Row,
  Col,
  Card,
  Divider,
  Form,
  Input,
  Tooltip,
  DatePicker,
  Upload,
  Radio,
  Button,
  notification,
  Modal,
  Image,
  Select,
  Typography,
} from "antd";

/////Iconos Imagenes
import imgSolicitudForm from "../assets/images/empleo/onlinecsv.png";

////Locale
import "moment/locale/es";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";

///// Extras
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;

///Componente Form
const FoamularioAplicante = () => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const { Text } = Typography;

  ///estados
  const [modalSucess, setModalSucess] = useState(false);
  const [cargaButonForm, setcargaButonForm] = useState(false);

  ////Form
  const [form] = Form.useForm();

  const headers = {
    "Content-Type": "application/json",
  };

  const enviarCorreoPerfil = async (correo, nombre) => {
    const datosEnviar = {
      key: "OFRNDQDHNRX7PYY8IUP3GBB94EZSNX5N",
      email: correo,
      nombre: nombre,
      asunto: "Aplicacion Plaza Pronto BPO",
      mensaje:
        "Se guardo tu solicitud de empleo exitosamente, nos estaremos comunicando con tigo para poder seguir con el proceso de aplicacion a plaza PRONTO BPO",
    };

    const datos = JSON.stringify(datosEnviar);

    const { data } = await Axios.post(
      `https://sendmail.prontoapps.app/api/aplicante`,
      datos,
      { headers }
    );
    return data;
  };

  const updateFilesListHook = async (file) => {
    const files = defaultFileList.push(file);
    console.log("Files", files);
    return setDefaultFileList([...defaultFileList]);
  };

  ////Upload FileReader
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    console.log("Datos de upload", onSuccess, onError, file, onProgress);

    var formData = new FormData();
    formData.append("files", file);
    await Axios.post("https://rh.api.prontobpo.cloud/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((data) => {
        //console.log("return upload images", data.data[0]);
        updateFilesListHook(data.data[0]);
        onSuccess("Ok");
      })
      .catch((error) => {
        onError({ event: error });
      });
  };

  ///  Delete File Strapi
  const deleteStrapiFile = async (fileId) => {
    await Axios.delete(
      `https://rh.api.prontobpo.cloud/api/upload/files/${fileId}`,
      {
        headers,
      }
    )
      .then((data) => {
        //console.log("return upload images", data.data);
        return data;
      })
      .catch((error) => {
        return error;
      });
  };

  //onRemove
  const onRemoveUpoload = (files) => {
    //console.log(">>> On Remove", files, defaultFileList);

    const nameFile = files.name;

    const deleteFileStrapi = defaultFileList.filter(function (i, n) {
      console.log(n, i.name, nameFile);
      return i.name === nameFile;
    });

    const removeID = deleteFileStrapi[0].id;

    //console.log("delete strapi", removeID);
    deleteStrapiFile(removeID);

    const valor = defaultFileList.filter(function (i, n) {
      console.log(n, i.name, nameFile);
      return i.name !== nameFile;
    });

    //console.log("Valor Encontrado", valor);
    setDefaultFileList([...valor]);
  };

  //////Log
  const agregadoLog = async (id, nombre) => {
    const datos = {
      data: {
        funcion: "Creacion formulario aplicante",
        fecha: moment(Date.now()).format("YYYY-MM-DD"),
        hora: moment(Date.now()).format("HH:mm:ss"),
        nombre: nombre,
        idMother: id,
      },
    };

    const datosStringify = JSON.stringify(datos);

    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/logs",
      datosStringify,
      { headers }
    );

    return data;
  };

  //////Formularios
  const envioSolicitudEmpleo = async (valores) => {
    let nombres = "";
    let apellidos = "";

    if (valores.nombre.includes(" ")) {
      nombres = valores.nombre.split(" ");
      nombres = nombres[0];
    } else {
      nombres = valores.nombre;
    }

    if (valores.apellido.includes(" ")) {
      apellidos = valores.apellido.split(" ");
      if (apellidos.length >= 3) {
        apellidos = apellidos[0] + " " + apellidos[1];
      } else {
        apellidos = apellidos[0];
      }
    } else {
      apellidos = valores.apellido;
    }

    enviarCorreoPerfil(valores.correo, valores.nombre);

    const datos = {
      data: {
        nombre: valores.nombre,
        apellido: valores.apellido,
        direccion: valores.direccion,
        dpi: valores.dpi,
        fecha: new Date(valores["nacimiento"]).toISOString().split("T")[0],
        transporte: valores.transporte,
        telefono: valores.Telefono,
        celular: valores.celular,
        correo: valores.correo,
        enteroNosotros: valores.entero,
        posicionAplica: valores.posicionAplica,
        fechaPuedeIniciarLaborar: new Date(valores["fechaInicioLabores"])
          .toISOString()
          .split("T")[0],
        pretencionSalarial: valores.pretensionSalarial,
        nombreInstitucion1: valores.nombreiInstitucion,
        tituloRecibido1: valores.tituloRecibido,
        certificadoFisico1: valores.certificadoFisico,
        nombreInstitucion2: valores.nombreiInstitucion2,
        tituloRecibido2: valores.tituloRecibido2,
        certificadoFisico2: valores.certificadoFisico2,
        nombreInstitucion3: valores.nombreiInstitucion3,
        tituloRecibido3: valores.tituloRecibido3,
        certificadoFisico3: valores.certificadoFisico3,
        nombreReferencia1: valores.nombreReferencia,
        profesionReferencia1: valores.profesionReferencia,
        ocupacionReferencia1: valores.ocupacionReferencia,
        telefonoReferencia1: valores.telefonoReferencia,
        nombreReferencia2: valores.nombreReferencia2,
        profesionReferencia2: valores.profesionReferencia2,
        ocupacionReferencia2: valores.ocupacionReferencia2,
        telefonoReferencia2: valores.telefonoReferencia2,
        nombreReferencia3: valores.nombreReferencia3,
        profesionReferencia3: valores.profesionReferencia3,
        ocupacionReferencia3: valores.ocupacionReferencia3,
        telefonoReferencia3: valores.telefonoReferencia3,
        empleador1: valores.empleador1,
        puesto1: valores.puesto1,
        fechaInicio1: valores.fechaInicio1
          ? new Date(valores["fechaInicio1"]).toISOString().split("T")[0]
          : null,
        motivoRetiro1: valores.motivoRetiro1,
        empleador2: valores.empleador2,
        puesto2: valores.puesto2,
        fechaInicio2: valores.fechaInicio2
          ? new Date(valores["fechaInicio2"]).toISOString().split("T")[0]
          : null,
        motivoRetiro2: valores.motivoRetiro2,
        empleador3: valores.empleador3,
        puesto3: valores.puesto3,
        fechaInicio3: valores.fechaInicio3
          ? new Date(valores["fechaInicio3"]).toISOString().split("T")[0]
          : null,
        motivoRetiro3: valores.motivoRetiro3,
        empleador4: valores.empleador4,
        puesto4: valores.puesto4,
        fechaInicio4: valores.fechaInicio4
          ? new Date(valores["fechaInicio4"]).toISOString().split("T")[0]
          : null,
        motivoRetiro4: valores.motivoRetiro4,
        proyecto: "No Asignado",
        creadoPor: valores.nombre,
        actualmenteEstudiando: valores.estudiando,
        restriccionHorario: valores.horario,

        fechaFinal1: valores.fechaFinal1
          ? new Date(valores["fechaFinal1"]).toISOString().split("T")[0]
          : null,
        fechaFinal2: valores.fechaFinal2
          ? new Date(valores["fechaFinal2"]).toISOString().split("T")[0]
          : null,
        fechaFinal3: valores.fechaFinal3
          ? new Date(valores["fechaFinal3"]).toISOString().split("T")[0]
          : null,
        fechaFinal4: valores.fechaFinal4
          ? new Date(valores["fechaFinal4"]).toISOString().split("T")[0]
          : null,
      },
    };

    const datosStringify = JSON.stringify(datos);

    //console.log(datos, headers);
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/mothers",
      datosStringify,
      { headers }
    );

    console.log("Datos de la respuesta del post", data);

    agregadoLog(data.data.id, valores.nombre);

    const datosPerfil = {
      data: {
        adjuntos: defaultFileList,
        mother: data.data.id,
        tags: "nuevo aplicante",
        estado: "Aplicado",
        tareas: [
          {
            estado: false,
            nombre: "Entrevista Candidato",
            fecha: `${moment().format("YYYY-MM-DD")}`,
            creado: "Recuitify",
          },
          {
            estado: false,
            nombre: "Test Habilidades",
            fecha: `${moment().format("YYYY-MM-DD")}`,
            creado: "Recuitify",
          },
          {
            estado: false,
            nombre: "ver CV y descripcion Puesto",
            fecha: `${moment().format("YYYY-MM-DD")}`,
            creado: "Recuitify",
          },
        ],
        potencial: "0",
        nombre: `${nombres} ${apellidos}`,
      },
    };

    console.log("Datos que voya enviar a perfil", datosPerfil);

    const datosStringify2 = JSON.stringify(datosPerfil);

    Axios.post("https://rh.api.prontobpo.cloud/api/perfiles", datosStringify2, {
      headers,
    })
      .then((values) => {
        console.log("Respuesta guardado perfil", values);
      })
      .catch((err) => {
        console.log("Error en guardar perfil", err);
        return err;
      });

    return data;
  };

  const onFinish = (values) => {
    setcargaButonForm(true);
    //console.log(values.fechaInicio1._d)
    //console.log('Success:', values);

    envioSolicitudEmpleo(values)
      .then((info) => {
        setModalSucess(true);
        onReset();
        setcargaButonForm(false);
      })
      .catch((error) => {
        console.error("Error!", error);
        notification["error"]({
          message: "Recrutify",
          description: `${error}`,
        });
        setcargaButonForm(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    notification["warning"]({
      message: "Solicitud de Empleo",
      description:
        "Hay campos que no se han completado, por favor revisa todos los campos.",
    });
    console.log(
      "Fecha:",
      errorInfo?.values["nacimiento"]?.format("YYYY/MM/DD")
    );
    console.log("Failed:", errorInfo);
  };

  const onReset = () => {
    form.resetFields();
  };

  ////Retorrno Componente
  return (
    <>
      <Form
        name="solicitudEmpleo"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={true}
        initialValues={{
          transporte: false,
          estudiando: false,
          horario: false,
          pretensionSalarial: 2500,
        }}
      >
        <Row gutter={[24, 18]} justify="space-around" align="middle">
          {
            ///Informacion personal
          }

          <Col xs={{ span: 23, offset: 1 }} sm={20} md={18} lg={12} xl={12}>
            <br />
            <div className="brand">
              <img src={logo} alt="" />
            </div>
          </Col>

          <Col xs={{ span: 23, offset: 1 }} sm={22} md={22} lg={20}>
            <Card bordered={false}>
              <Meta
                title="Solicitud de Empleo"
                description="Ingresa los siguientes datos para completar el formulario. Todos lo datos se guardaran de manera segura."
              />

              <br />

              <Row gutter={[16, 0]} justify="space-around" align="middle">
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Información Personal
                </Divider>

                <Col span={24}>
                  <Row gutter={[16, 0]} justify="space-around" align="middle">
                    <Col
                      xs={{ span: 24, order: 2 }}
                      sm={{ span: 24, order: 2 }}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      {
                        /// Primera Seccion
                      }
                      <Row gutter={[32, 0]}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Nombres"
                            name="nombre"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu nombre.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Nombres" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Apellidos"
                            name="apellido"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tus apellidos.",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Apellidos" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Dirección"
                            name="direccion"
                            rules={[
                              {
                                required: true,
                                message: "Por favor ingresa tu dirección.",
                              },
                            ]}
                          >
                            <TextArea
                              size="large"
                              placeholder="Dirección"
                              autoSize={{ minRows: 1, maxRows: 6 }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Correo Electrónico"
                            name="correo"
                            rules={[
                              {
                                type: "email",
                                required: true,
                                message:
                                  "Ingresa tu correo, ejemplo: ejemplo@prontobpo.com .",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Correo" />
                          </Form.Item>
                        </Col>

                        {
                          /// Segunda Seccion
                        }
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Fecha de Nacimiento"
                            name="nacimiento"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu fecha de nacimiento.",
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              placeholder="Nacimiento"
                              format="DD/MM/YYYY"
                              locale={locale}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Teléfono"
                            name="Telefono"
                            rules={[
                              {
                                required: false,
                                message:
                                  "Por favor ingresa tu número de teléfono.",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              pattern="[0-9]*"
                              placeholder="Número teléfono"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Form.Item
                            className="username"
                            label="Celular"
                            name="celular"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Por favor ingresa tu número de celular.",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              pattern="[0-9]*"
                              placeholder="Número celular"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Tooltip
                            trigger={["focus"]}
                            title={`Ingresa el DPI sin espacios o guiones ( - ) solo números. `}
                            placement="topLeft"
                          >
                            <Form.Item
                              className="username"
                              label="DPI"
                              name="dpi"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Por favor ingresa tu DPI sin espacios o guiones ( - ) solo números. ",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                pattern="[0-9]*"
                                placeholder="DPI"
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={{ span: 24, order: 1 }}
                      sm={{ span: 24, order: 1 }}
                      md={12}
                      lg={6}
                      xl={6}
                    >
                      <Image
                        width={{ width: "100%" }}
                        src={imgSolicitudForm}
                        preview={false}
                      />
                    </Col>
                  </Row>
                </Col>

                {
                  /// Tercera Seccion
                }
                <Col xs={10} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    className="username"
                    label="Tiene trasporte"
                    name="transporte"
                    rules={[
                      {
                        required: true,
                        message: "Por favor indica si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={10} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    className="username"
                    label="Actualmente está estudiando"
                    name="estudiando"
                    rules={[
                      {
                        required: true,
                        message: "Por favor seleccione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    className="username"
                    label="Tiene restricción de horario"
                    name="horario"
                    rules={[
                      {
                        required: true,
                        message: "Por favor seleccione si o no.",
                      },
                    ]}
                  >
                    <Radio.Group style={{ width: "100%" }} size="large">
                      <Radio.Button value={true}>Si</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    className="username"
                    label="Como se enteró de nosotros"
                    name="entero"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Como se enteró de nosotros"
                    >
                      <Option value="Referido">Referido</Option>
                      <Option value="Linkedin">Linkedin</Option>
                      <Option value="Facebook">Facebook</Option>
                      <Option value="Instagram">Instagram</Option>
                      <Option value="Tiktok">Tiktok</Option>
                      <Option value="Presencial">Presencial</Option>
                      <Option value="Pagina Web">Pagina Web</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Vacante
          }
          <Col xs={{ span: 23, offset: 1 }} sm={22} md={22} lg={20}>
            <Card bordered={false}>
              <Row gutter={[32, 0]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Vacante
                </Divider>
                {
                  /// Cuarta Seccion
                }

                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    className="username"
                    label="Posición a la que aplica"
                    name="posicionAplica"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa la posición",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Por favor ingresa la posición"
                    >
                      <Option value="Español">Español</Option>
                      <Option value="Ingles">Ingles</Option>
                      <Option value="Lenguas Mayas">Lenguas Mayas</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    className="username"
                    label="Fecha en la que puede inciar a laborar"
                    name="fechaInicioLabores"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la fecha de inicio de labores.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Form.Item
                    className="username"
                    label="Pretensión Salarial"
                    name="pretensionSalarial"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      size="large"
                      pattern="[0-9]*"
                      placeholder="Salario"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Educacion
          }
          <Col xs={{ span: 23, offset: 1 }} sm={22} md={22} lg={20}>
            <Card bordered={false}>
              <Row gutter={[28, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Educacion
                </Divider>

                {
                  /// Quinta Seccion
                }

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item
                    className="username"
                    label="Nombre institución"
                    name="nombreiInstitucion"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la institución",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="1"
                      size="large"
                      placeholder="Institución"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Título Recibido"
                    name="tituloRecibido"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingresa el título recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Título" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <Form.Item
                    className="username"
                    label="Certificado (Sellado y Firmado por Contraloria)"
                    name="certificadoFisico"
                    rules={[
                      {
                        required: true,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item
                    className="username"
                    label="Nombre institución"
                    name="nombreiInstitucion2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la institución",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="2"
                      size="large"
                      placeholder="Institución"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Título Recibido"
                    name="tituloRecibido2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el título recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Título" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <Form.Item
                    className="username"
                    label="Certificado (Sellado y Firmado por Contraloria)"
                    name="certificadoFisico2"
                    rules={[
                      {
                        required: false,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item
                    className="username"
                    label="Nombre institución"
                    name="nombreiInstitucion3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la institución",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="3"
                      size="large"
                      placeholder="Institución"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Título Recibido"
                    name="tituloRecibido3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el título recibido.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Título" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={10} xl={10}>
                  <Form.Item
                    className="username"
                    label="Certificado (Sellado y Firmado por Contraloria)"
                    name="certificadoFisico3"
                    rules={[
                      {
                        required: false,
                        message: "Si / No",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Si / No" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Refencias Personales
          }
          <Col xs={{ span: 23, offset: 1 }} sm={22} md={22} lg={20}>
            <Card bordered={false}>
              <Row gutter={[28, 2]}>
                <Divider
                  style={{ fontSize: 17, fontWeight: "bold" }}
                  orientation="left"
                >
                  Referencias Personales
                </Divider>

                {
                  /// Sexta Seccion
                }

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia"
                    rules={[
                      {
                        required: true,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="1"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Profesión"
                    name="profesionReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Ocupación"
                    name="ocupacionReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa la ocupación de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Número de teléfono"
                    name="telefonoReferencia"
                    rules={[
                      {
                        required: true,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      pattern="[0-9]*"
                      placeholder="Número Telefono"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="2"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Profesión"
                    name="profesionReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Ocupación"
                    name="ocupacionReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la ocupación de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Número de teléfono"
                    name="telefonoReferencia2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      pattern="[0-9]*"
                      placeholder="Número Teléfono"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Nombre"
                    name="nombreReferencia3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la referencia",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="3"
                      size="large"
                      placeholder="Nombre Completo"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Profesión"
                    name="profesionReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la Profesión de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Profesión" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Ocupación"
                    name="ocupacionReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la ocupación de su referencia personal.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Ocupación" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Número de teléfono"
                    name="telefonoReferencia3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el número de teléfono de su referencia personal.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      pattern="[0-9]*"
                      placeholder="Número Teléfono"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          {
            ///Experiencia Laboral
          }
          <Col xs={{ span: 23, offset: 1 }} sm={22} md={22} lg={20}>
            <Card bordered={false}>
              <Divider
                style={{ fontSize: 17, fontWeight: "bold" }}
                orientation="left"
              >
                Experiencia Laboral
              </Divider>

              <Text type="secondary">
                Si no tiene experiencia deje en blanco!
              </Text>

              <br />
              <br />
              <Row gutter={[28, 2]}>
                {
                  /// Septima Seccion
                }

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="1" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto1"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Puesto" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio1"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de final"
                    name="fechaFin1"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Final de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro1"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="2" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de final"
                    name="fechaFinal2"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Final de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro2"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="3" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de final"
                    name="fechaFinal3"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Final de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro3"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Empleador"
                    name="empleador4"
                    rules={[
                      {
                        required: false,
                        message: "Por favor el nombre de la experencia laboral",
                      },
                    ]}
                  >
                    <Input addonBefore="4" size="large" placeholder="Nombre" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Puesto"
                    name="puesto4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa el puesto de la experencia laboral.",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Nombre del Puesto" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de inicio"
                    name="fechaInicio4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Inicio de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={4} xl={4}>
                  <Form.Item
                    className="username"
                    label="Fecha de final"
                    name="fechaFinal4"
                    rules={[
                      {
                        required: false,
                        message:
                          "Por favor ingresa la fecha de la experencia laboral.",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      placeholder="Fecha Final de Labores"
                      format="DD/MM/YYYY"
                      locale={locale}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item
                    className="username"
                    label="Motivo de Retiro"
                    name="motivoRetiro4"
                    rules={[
                      {
                        required: false,
                        message: "Por favor ingresa el motivo de retiro.",
                      },
                    ]}
                  >
                    <TextArea
                      size="large"
                      placeholder="Motivo de retiro"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <Meta
                    title="Adjuntar Archivos"
                    description="Adjunte curriculum y certificados importantes"
                  />
                </Col>
              </Row>

              <br />

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                  <Form.Item
                    name="adjuntos"
                    id="adjuntos"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, sube al menos un archivo",
                      },
                    ]}
                  >
                    <Upload
                      listType="picture"
                      customRequest={uploadFile}
                      onRemove={onRemoveUpoload}
                      multiple
                    >
                      <Button icon={<UploadOutlined />}>Subir Archivos</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 18]} justify="space-around" align="middle">
                <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                  <Form.Item>
                    <Button
                      loading={cargaButonForm}
                      size="Large"
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar Formulario
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      {
        ////Modal
        <Modal
          centered
          visible={modalSucess}
          onOk={() => setModalSucess(false)}
          onCancel={() => setModalSucess(false)}
          footer={
            <Button type="primary" onClick={() => setModalSucess(false)}>
              Salir
            </Button>
          }
        >
          <Sucess titulo="Solicitud de Empleo" />
        </Modal>
      }

      <br />
    </>
  );
};

export default FoamularioAplicante;
