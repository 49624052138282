////React
import { useState, useEffect } from "react";

///Axios
import Axios from "axios";

///RouterDom
import { Switch, Route } from "react-router-dom";

/////Helpers
import { deleteToken, getToken, setToken } from "./Helpers/auth-helpers";
import getUserHelpers from "./Helpers/getUser-helpers";

////Css
import "./theme/theme.less";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

/////Iconos Imagenes
import img404 from "./assets/images/404/404.png";

////Antdesign
import { Empty, Button } from "antd";

////Paginas
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import LoadingView from "./components/loading/Loading";
import FormularioManejaSolo from "./pages/FormularioManejaSolo";
import FormularioManejaPersonas from "./pages/FormularioManejaPersonas";
import FormularioManejaEquipos from "./pages/FormularioManejaEquipos";
import FormularioManejaAreas from "./pages/FormularioManejaAreas";

import ConfirmarFormularioManejaSolo from "./pages/ConfirmarFormularioManejaSolo";
import ConfirmarFormularioManejaPersonas from "./pages/ConfirmarFormularioManejaPersonas";
import ConfirmarFormularioManejaEquipos from "./pages/ConfirmarFormularioManejaEquipos";
import ConfirmarFormularioManejaAreas from "./pages/ConfirmarFormularioManejaAreas";

import Formulario from "./pages/Formulario";
import FormularioAplicante from "./pages/FormularioAplicante";
import Formulario2 from "./pages/Formulario2";
import FormularioPerfil from "./pages/FormularioPerfil";
import FormularioOferta from "./pages/FormularioOferta";
import FormularioOfertar from "./pages/FormularioOfertar";
import Formularioentre from "./pages/Formularioentre";
import Requerimiento from "./pages/Requerimiento";
import Solicitudes from "./pages/Solicitudes";
import Requerimientos from "./pages/solicitudes/Requerimientos";
import Perfiles from "./pages/solicitudes/Perfiles";
import DocumentoVista from "./pages/DocumentoVista";
import VerificarEntrevistas from "./pages/VerificarEntrevistas";
import Reporteria from "./pages/Reporteria";

function App() {
  ////Estados
  const [usuarioLogin, setUsuarioLogin] = useState(null);
  const [loading, setloading] = useState(true);

  //////Verificacion de Usuario
  const verUsuario = () => {
    const token = getToken();
    getUserHelpers(token)
      .then((datos) => {
        console.log("Datos de la persona logueada", datos);
        setloading(false);
        setUsuarioLogin(datos);
      })
      .catch(function (error) {
        logout();
        window.location = "/log-in";
      });
  };

  /////Efectos (  revision de usuario (token)  )
  useEffect(() => {
    if (!getToken()) {
      logout();
      setloading(false);
      deleteToken();
      console.log("No hay datos");
      return;
    } else {
      verUsuario();
    }
  }, []);

  ////Funcion de Login
  const login = async (identifier, password) => {
    const { data } = await Axios.post(
      "https://rh.api.prontobpo.cloud/api/auth/local",
      {
        identifier,
        password,
      }
    );
    setToken(data.jwt);
    console.log("Datos del logueo para redireccionar pagina", data);
    if (data.user.tipo_usuario === "Administrador") {
      //Ventana que envia luego de LOGGIN
      window.location = "/solicitudes/perfiles";
    } else if (data.user.tipo_usuario === "Requeridor") {
      //Ventana que envia luego de LOGGIN
      window.location = "/requerimiento";
    } else {
      //Ventana que envia luego de LOGGIN
      window.location = "/VerificarEntrevistas";
    }
    console.log(data);
  };

  ////Logout
  const logout = async () => {
    setUsuarioLogin(null);
    deleteToken();
  };

  /////Loading
  if (loading) {
    return <LoadingView></LoadingView>;
  }

  /////Vistas del sistema
  return (
    <div className="App">
      {!usuarioLogin ? (
        <Switch>
          <Route exact path="/formularioAplicante">
            <FormularioAplicante> </FormularioAplicante>
          </Route>

          <Route default path="/log-in">
            <SignIn login={login}></SignIn>
          </Route>

          <Route path="*">
            <SignIn login={login}></SignIn>
          </Route>
        </Switch>
      ) : (
        <Main
          usuarioMail={usuarioLogin.email}
          usuarioUser={usuarioLogin.username}
          usuarioTipo={usuarioLogin.tipo_usuario}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/informacion" component={Tables} />

            <Route exact path="/profile">
              <Profile usuarioInfoCompleta={usuarioLogin}></Profile>
            </Route>

            <Route exact path="/solicitudes/perfiles">
              <Perfiles
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
              ></Perfiles>
            </Route>

            <Route exact path="/pdf">
              <DocumentoVista></DocumentoVista>
            </Route>

            <Route exact path="/formulario2">
              <Formulario2 nombre={usuarioLogin.nombre}></Formulario2>
            </Route>

            <Route exact path="/VerificarEntrevistas">
              <VerificarEntrevistas
                usuarioInfoCompleta={usuarioLogin.username}
                usuarioMail={usuarioLogin.email}
              ></VerificarEntrevistas>
            </Route>

            <Route exact path="/Reporteria">
              <Reporteria
                usuarioInfoCompleta={usuarioLogin.username}
                usuarioMail={usuarioLogin.email}
              ></Reporteria>
            </Route>

            <Route exact path="/formularioManejaSolo">
              <FormularioManejaSolo
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></FormularioManejaSolo>
            </Route>

            <Route exact path="/formularioManejaPersonas">
              <FormularioManejaPersonas
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></FormularioManejaPersonas>
            </Route>

            <Route exact path="/formularioManejaEquipos">
              <FormularioManejaEquipos
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></FormularioManejaEquipos>
            </Route>

            <Route exact path="/formularioManejaAreas">
              <FormularioManejaAreas
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></FormularioManejaAreas>
            </Route>

            <Route exact path="/ConfirmarFormularioManejaSolo">
              <ConfirmarFormularioManejaSolo
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></ConfirmarFormularioManejaSolo>
            </Route>

            <Route exact path="/ConfirmarFormularioManejaPersonas">
              <ConfirmarFormularioManejaPersonas
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></ConfirmarFormularioManejaPersonas>
            </Route>

            <Route exact path="/ConfirmarFormularioManejaEquipos">
              <ConfirmarFormularioManejaEquipos
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></ConfirmarFormularioManejaEquipos>
            </Route>

            <Route exact path="/ConfirmarFormularioManejaAreas">
              <ConfirmarFormularioManejaAreas
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></ConfirmarFormularioManejaAreas>
            </Route>

            <Route exact path="/formularioPerfil">
              <FormularioPerfil
                usuario={[
                  usuarioLogin.nombre,
                  usuarioLogin.apellido,
                  usuarioLogin.email,
                ]}
                correoLogeado={usuarioLogin.email}
              ></FormularioPerfil>
            </Route>

            <Route exact path="/solicitudes/requerimientos">
              <Solicitudes usuarioInfoCompleta={usuarioLogin}></Solicitudes>
            </Route>

            <Route
              exact
              path="/solicitudes/requerimientos/:nombreRequerimiento"
            >
              <Requerimientos></Requerimientos>
            </Route>

            <Route exact path="/formulario">
              <Formulario usuario={usuarioLogin.username}></Formulario>
            </Route>

            <Route exact path="/formularioOferta">
              <FormularioOferta
                usuarioInfoCompleta={usuarioLogin.username}
              ></FormularioOferta>
            </Route>

            <Route exact path="/formularioOfertar">
              <FormularioOfertar
                usuario={[usuarioLogin.nombre, usuarioLogin.apellido]}
                correoLogeado={usuarioLogin.email}
              ></FormularioOfertar>
            </Route>

            <Route exact path="/formularioentre">
              <Formularioentre
                usuario={usuarioLogin.username}
              ></Formularioentre>
            </Route>

            <Route exact path="/requerimiento">
              <Requerimiento usuario={usuarioLogin.nombre}></Requerimiento>
            </Route>
            <Route path="*">
              <Empty
                image={img404}
                imageStyle={{
                  height: 400,
                }}
                description={
                  <span>
                    No se encontro la Pagina de <a href="#teba">Recruitify</a>
                  </span>
                }
              >
                <Button
                  type="primary"
                  onClick={() => (window.location = "/dashboard")}
                >
                  Regresar a Home
                </Button>
              </Empty>
            </Route>
          </Switch>
        </Main>
      )}
    </div>
  );
}

export default App;
