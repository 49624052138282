import { useState, useEffect } from "react";

///Axios
import Axios from "axios";

//Helpers
import { getToken } from "../Helpers/auth-helpers";

import {
  Card,
  Col,
  Row,
  Typography,
  Timeline,
  Radio,
} from "antd";
import {
  BulbOutlined,
  QuestionOutlined,
  CloseOutlined,
  CheckOutlined,
  FormOutlined,
  CarryOutOutlined,
  TeamOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

function Home() {
  const { Title, Text } = Typography;

  const [ultimosRegistros, setUltimosRegistros] = useState([]);
  const [ultimaActividad, setUltimaActividad] = useState([]);

  const [aplicado, setAplicado] = useState(0);
  const [entrevista, setEntrevista] = useState(0);
  const [declinado, setDeclinado] = useState(0);
  const [oferta, setOferta] = useState(0);
  const [contratado, setContratado] = useState(0);

  const [requerimientoEnProceso, setRequerimientoEnProceso] = useState(0);
  const [requerimientoCompleto, setRequerimientoCompleto] = useState(0);

  const cargaUltimaActividad = async (e) => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    let url;

    try {
      url = `https://rh.api.prontobpo.cloud/api/logs?pagination[page]=1&pagination[pageSize]=10&sort[0]=createdAt:desc`;

      const response = await Axios.get(url, { headers }); // Asumiendo que headers ya está definido
      console.log("datos a setear", response);
      setUltimaActividad(response.data.data);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  const cambioUltimosRegistros = async (e) => {
    console.log(`Valor seleccionado: ${e.target.value}`);

    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    let url;

    try {
      if (e.target.value === "Aplicado") {
        url =
          "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$in]=Aplicado&filters[estado][$in]=Confirmacion entrevista 1&sort[0]=createdAt:desc&populate[mother]=*";
      } else {
        url = `https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=10&filters[estado][$eq]=${e.target.value}&sort[0]=createdAt:desc&populate[mother]=*`;
      }

      const response = await Axios.get(url, { headers }); // Asumiendo que headers ya está definido
      console.log("datos a setear", response);
      setUltimosRegistros(response.data.data);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  const cargaDatos = async () => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
    };

    try {
      const urls = [
        "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$in]=Aplicado&filters[estado][$in]=Confirmacion entrevista 1&populate[mother]=*",
        "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=Entrevista",
        "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=Declinado",
        "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=Oferta",
        "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=Contratado",
        "https://rh.api.prontobpo.cloud/api/requerimientos?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=En Proceso",
        "https://rh.api.prontobpo.cloud/api/requerimientos?pagination[page]=1&pagination[pageSize]=100000&filters[estado][$eq]=Completo",
        "https://rh.api.prontobpo.cloud/api/perfiles?pagination[page]=1&pagination[pageSize]=10&filters[estado][$in]=Aplicado&filters[estado][$in]=Confirmacion entrevista 1&populate[mother]=*",
      ];

      const [
        aplicado,
        entrevista,
        declinado,
        oferta,
        contratado,
        enProceso,
        completo,
        ultimosRegistros,
      ] = await Promise.all(urls.map((url) => Axios.get(url, { headers })));

      setAplicado(aplicado.data.data.length);
      setEntrevista(entrevista.data.data.length);
      setDeclinado(declinado.data.data.length);
      setOferta(oferta.data.data.length);
      setContratado(contratado.data.data.length);

      setRequerimientoEnProceso(enProceso.data.data.length);
      setRequerimientoCompleto(completo.data.data.length);

      setUltimosRegistros(ultimosRegistros.data.data);
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  

  const count = [
    {
      today: "Aplicado",
      title: aplicado,
      icon: <QuestionOutlined />,
    },
    {
      today: "Entrevista",
      title: entrevista,
      icon: <FileSearchOutlined />,
    },
    {
      today: "Declinado",
      title: declinado,
      icon: <CloseOutlined />,
    },
    {
      today: "Oferta",
      title: oferta,
      icon: <FolderOpenOutlined />,
    },
    {
      today: "Contratado",
      title: contratado,
      icon: <CheckOutlined />,
    },
    {
      today: "Total de aplicantes",
      title: aplicado + entrevista + declinado + oferta + contratado,
      icon: <FormOutlined />,
    },
  ];

  const requerimientos = [
    {
      today: "En Proceso",
      title: requerimientoEnProceso,
      icon: <BulbOutlined />,
    },
    {
      today: "Completo",
      title: requerimientoCompleto,
      icon: <CarryOutOutlined />,
    },
    {
      today: "General",
      title: requerimientoEnProceso + requerimientoCompleto,
      icon: <TeamOutlined />,
    },
  ];

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses son 0-indexados
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  ////Efectos
  useEffect(() => {
    cargaDatos();
    cargaUltimaActividad();
  }, []);

  return (
    <>
      <div className="layout-content">
        <Row
          className="rowgap-vbox"
          gutter={[24, 0]}
          align="middle"
          justify="center"
        >
          <Title level={2}>Marcadores Aplicantes</Title>
        </Row>
        <Row
          className="rowgap-vbox"
          gutter={[24, 0]}
          align="middle"
          justify="center"
        >
          <br />
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>{c.title}</Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <br />
        <br />
        <Row
          className="rowgap-vbox"
          gutter={[24, 0]}
          align="middle"
          justify="center"
        >
          <Title level={2}>Marcadores Requerimientos</Title>
        </Row>
        <Row
          className="rowgap-vbox"
          gutter={[24, 0]}
          align="middle"
          justify="center"
        >
          <br />
          {requerimientos.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={3}>{c.title}</Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div>
                  <Title level={5}>Ulitmos 10 registros por estado</Title>
                </div>
                <div className="ant-filtertabs">
                  <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                    <Radio.Group onChange={cambioUltimosRegistros}>
                      <Radio.Button value="Aplicado">Aplicado</Radio.Button>
                      <Radio.Button value="Entrevista">Entrevista</Radio.Button>
                      <Radio.Button value="Declinado">Declinado</Radio.Button>
                      <Radio.Button value="Oferta">Oferta</Radio.Button>
                      <Radio.Button value="Contratado">Contratado</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Apellidos</th>
                      <th>Correo</th>
                      <th>Como Se Entero</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ultimosRegistros.map((d, index) => {
                      console.log("ultimos registros", d); // Imprime los registros en cada iteración
                      return (
                        <tr key={index}>
                          <td>{d.attributes.mother.data.attributes.nombre}</td>
                          <td>
                            {d.attributes.mother.data.attributes.apellido}
                          </td>
                          <td>{d.attributes.mother.data.attributes.correo}</td>
                          <td>
                            {d.attributes.mother.data.attributes.enteroNosotros}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="timeline-box">
                <Title level={5}>Actividad Reciente</Title>
                <br />
                <Timeline className="timelinelist">
                  {ultimaActividad.map((t, index) => {
                    console.log("Datos de logs", t);
                    return (
                      <Timeline.Item key={index}>
                        <Title level={5}>
                          {"Funcion: " + t.attributes.funcion}
                        </Title>
                        <Text>
                          {"Nombre: " + t.attributes.nombre}
                        </Text>
                        <Text>{formatDate(t.attributes.publishedAt)}</Text>
                      </Timeline.Item>
                    );
                  })}
                </Timeline>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
